import '../../../styles/gradient-spinner.css';

import React, { useEffect } from 'react';
import { Box, Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import { selectDeviceSetupState } from './deviceSetupSlice';

/* istanbul ignore next -- @preserve */
export const DeviceJoin = () => {
  const navigate = useNavigate();
  const { isLoaded, status, message } = useSelector(selectDeviceSetupState);
  const toast = useToast();
  const gradientColorModeClassName = useColorModeValue('light-mode-gradient', 'dark-mode-gradient');
  const bgColor = useColorModeValue('#F7F7F7', '#111111');
  useEffect(() => {
    if (isLoaded) {
      if (status === 'success') {
        navigate('../device_pre_setup');
      } else {
        if (message?.includes('Not support EVSE charger model')) {
          toast({ title: 'This device model is not supported!', status: 'error', isClosable: true });
          navigate('../device_charger_unavailable');
        } else {
          if (message.includes('already linked to another account')) {
            toast({
              title: 'This EV charger is already linked to another account!',
              status: 'error',
              isClosable: true,
            });
          }
          navigate('../device_generic_error');
        }
      }
    }
  }, [isLoaded, status, message]);

  return (
    <Flex bgColor={bgColor} direction={'column'} align="center" minH={COMPONENT_MIN_HEIGHT} pb={BOTTOM_NAV_HEIGHT}>
      <CommonTopNav title="Device Setup" backURL="../device_scan" />
      <Flex direction={'column'} px={8} pt={TOP_NAV_SPACING_AFFORDANCE}>
        <Text>
          We are connecting to your <strong>Schneider Home</strong>, this may take up to a few minutes.
        </Text>
        <Text>Please wait.</Text>
      </Flex>

      <Box mt={24} position="relative">
        <Box className={`gradient-spinner ${gradientColorModeClassName}`} />
      </Box>
    </Flex>
  );
};
