import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { selectBatteries, selectInverters, selectMeters } from '../../site/siteSlice';
import { PowerSourceConfiguration } from '../types';
import { useSource } from './use-source';

type Props = {
  powerSources: PowerSourceConfiguration;
  selectedPowerSources: PowerSourceConfiguration;
  onSetSelectedPowerSources: (property: keyof PowerSourceConfiguration, value: boolean) => void;
  selectedDateRangeType: DateRangeType;
};

export default function PowerSourceSelect({
  powerSources,
  selectedPowerSources,
  onSetSelectedPowerSources,
  selectedDateRangeType,
}: Props) {
  const source = useSource();
  const siteHasBattery = useSelector(selectBatteries).length > 0;
  const siteHasInverter = useSelector(selectInverters).length > 0;
  const siteHasMeter = useSelector(selectMeters).length > 0;
  const { t } = useTranslation();

  return (
    <Accordion defaultIndex={[0]} allowMultiple id="power-source-select" data-testid="power-source-select">
      <AccordionItem>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            {t('Activity.power sources')}
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Flex justify="space-between">
            {powerSources.solar && (
              <Center flexDirection="column">
                <Switch
                  onChange={(e) => onSetSelectedPowerSources('solar', e.target.checked)}
                  isChecked={selectedPowerSources.solar}
                  data-testid="solar-toggle-switch"
                  variant="solarGreen"
                  isDisabled={!!source || !siteHasInverter}
                  size="lg"
                />
                <Text mt={1} fontSize={'sm'}>
                  {t('Solar.solar').toUpperCase()}
                </Text>
              </Center>
            )}
            {/* Note: Battery is hidden when date range is not DAY */}
            {powerSources.battery && (
              <Center flexDirection="column">
                <Switch
                  onChange={(e) => onSetSelectedPowerSources('battery', e.target.checked)}
                  isChecked={selectedPowerSources.battery && selectedDateRangeType === DateRangeType.Day}
                  data-testid="battery-toggle-switch"
                  variant="batteryBlue"
                  isDisabled={!!source || !siteHasBattery}
                  size="lg"
                />
                <Text mt={1} fontSize={'sm'}>
                  {t('Battery.battery').toUpperCase()}
                </Text>
              </Center>
            )}
            {powerSources.grid && (
              <Center flexDirection="column">
                <Switch
                  onChange={(e) => onSetSelectedPowerSources('grid', e.target.checked)}
                  isChecked={selectedPowerSources.grid}
                  isDisabled={!!source || ((!siteHasInverter || !siteHasBattery) && !siteHasMeter)}
                  data-testid="grid-toggle-switch"
                  variant="gridRed"
                  size="lg"
                />
                <Text mt={1} fontSize={'sm'}>
                  {t('Grid.grid').toUpperCase()}
                </Text>
              </Center>
            )}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
