import React from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoFilter } from 'react-icons/io5';

export type SortByOptions = 'COST' | 'POWER' | 'ALPHABETICAL';

type Props = {
  sortBy: SortByOptions;
  onChangeSortBy: (sortBy: SortByOptions) => void;
};

export function SortByPopover({ sortBy, onChangeSortBy }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('#fff', 'customGrey.900');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const { t } = useTranslation();

  return (
    <Box zIndex={1}>
      <Popover placement="bottom-start" onClose={onClose} isOpen={isOpen} onOpen={onOpen}>
        <PopoverTrigger>
          <IconButton icon={<IoFilter size={23} />} aria-label={'Sort'} bg="none" data-testid="sortby-btn" />
        </PopoverTrigger>
        <PopoverContent borderRadius={8} bg={bgColor} data-testid="sortby-options" w="100%">
          <PopoverHeader>{t('Common.sort by')}:</PopoverHeader>
          <PopoverBody p={0}>
            <Button
              w="100%"
              p={3}
              borderRadius={0}
              bg="unset"
              onClick={() => {
                onChangeSortBy('COST');
                onClose();
              }}
              data-testid="sortby-cost-btn"
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text mr={2} fontWeight={500}>
                  {t('Common.cost')}
                </Text>
                {sortBy == 'COST' && <CheckIcon color={iconColor} />}
              </Flex>
            </Button>
            <Divider p={'unset'} />
            <Button
              w="100%"
              p={3}
              borderRadius={0}
              bg="unset"
              onClick={() => {
                onChangeSortBy('POWER');
                onClose();
              }}
              data-testid="sortby-power-btn"
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text mr={2} fontWeight={500}>
                  {t('Devices.power consumption')}
                </Text>
                {sortBy == 'POWER' && <CheckIcon color={iconColor} />}
              </Flex>
            </Button>
            <Divider />
            <Button
              w="100%"
              p={3}
              borderRadius={0}
              bg="unset"
              onClick={() => {
                onChangeSortBy('ALPHABETICAL');
                onClose();
              }}
              data-testid="sortby-alphabetical-btn"
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text mr={2} fontWeight={500}>
                  {t('Devices.alphabetical')}
                </Text>
                {sortBy == 'ALPHABETICAL' && <CheckIcon color={iconColor} />}
              </Flex>
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
