import React, { useEffect, useRef } from 'react';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';
import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';

import { useReduxDispatch } from '../../../app/store';
import { ReactComponent as EVLinkDeviceQRCode } from '../../../assets/images/evlink_device_qr_code.svg';
import ArcButton from '../../../common/components/ArcButton';
import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT, IS_NATIVE } from '../../../common/constants';
import { enrolEvCharger, resetEnrollState, selectDeviceSetupState } from './deviceSetupSlice';

/* istanbul ignore next -- @preserve */
export const DeviceScan = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { hasInitiatedEnroll } = useSelector(selectDeviceSetupState);
  const dispatch = useReduxDispatch();
  const isInitialLoad = useRef(true);
  const isRunningCypressTest = (window as any).Cypress;
  const isTestingBarcodeScanError = (window as any).isTestingBarcodeScanError;
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  useEffect(() => {
    if (isInitialLoad.current) {
      // calling prepare() to make startScan() work even faster.
      if (IS_NATIVE) BarcodeScanner.prepare();
      isInitialLoad.current = false;
    }

    if (hasInitiatedEnroll) {
      navigate('../device_join');
    }

    return () => {
      dispatch(resetEnrollState());
    };
  }, [hasInitiatedEnroll]);

  const showInvalidBarcodeToast = () => {
    toast({
      title: 'Invalid Barcode!',
      description: 'Please try again! If issue persists, please contact support.',
      status: 'error',
      isClosable: true,
    });
  };

  async function handleStartBarcodeScan() {
    // Handle barcode scan success and fail when running Cypress tests
    if (isRunningCypressTest) {
      if (isTestingBarcodeScanError) return showInvalidBarcodeToast();
      dispatch(
        enrolEvCharger({
          ref: 'RVH4B10I2PJ',
          sn: 'EVB1A33P4KI3N214912002400150027AZ',
          cpid: '0586996e-3d14-403d-9b71-31012010e7f21',
        })
      );
      return;
    }

    const hasPermission = await didUserGrantBarcodePermission();

    if (hasPermission) {
      await BarcodeScanner.hideBackground();
      document.body.classList.add('qrscanner');

      const result = await BarcodeScanner.startScan();
      document.body.classList.remove('qrscanner');

      if (result.hasContent) {
        // barcode content sample:
        // http://go2se.com/ref=EVH4A10N5PU/sn=SN2205278845/cpid=b5eddbaf-984f-418e-88eb-cf0b8ff3e775
        const barcodeContent = result.content;
        if (!barcodeContent) {
          showInvalidBarcodeToast();
          return;
        }

        const splitUrl = barcodeContent.split('.com/');
        const chargerProperties = splitUrl[1];

        if (!chargerProperties) {
          showInvalidBarcodeToast();
          return;
        }

        const splittedChargerProperties = chargerProperties.split('/');

        const barCodeValues = splittedChargerProperties.reduce(
          (acc, curr) => {
            const [key, value] = curr.split('=');
            if (['ref', 'sn', 'cpid'].includes(key)) return { ...acc, [key]: value };
            return acc;
          },
          { ref: '', sn: '', cpid: '' }
        );

        dispatch(enrolEvCharger(barCodeValues));
      } else {
        console.error('NO CONTENT!');
        showInvalidBarcodeToast();
      }
    }
  }

  return (
    <Flex
      bgColor={bgColor}
      direction={'column'}
      align="center"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title="Device Setup" backURL="../device_register" />

      <Text px={8} pt={TOP_NAV_SPACING_AFFORDANCE}>
        Next scan the QR code located on Schneider Home.
      </Text>

      <EVLinkDeviceQRCode style={{ width: 'fit-content', height: 'auto', maxHeight: '480px', marginLeft: '40px' }} />

      <ArcButton
        arcColor="#3DCD57"
        data-testid={'open-camera'}
        width="100%"
        maxWidth={'300px'}
        mb={16}
        onClick={handleStartBarcodeScan}
        disabled={!IS_NATIVE && !isRunningCypressTest}
      >
        {IS_NATIVE || isRunningCypressTest ? 'Open Camera' : 'Scanning not available on web'}
      </ArcButton>

      {/* Uncomment for testing purposes */}
      {/* {!isNativeDevice && (
        <Button
          position={'absolute'}
          bottom={4}
          zIndex={99999}
          fontSize="xs"
          onClick={() => {
            // Mitch device
            // ref: 'EVH4A10N5PU',
            // sn: 'EVB1A22P4KI3N214612002400152237AC',
            // cpid: '0586006e-3d14-406d-9b71-3602010e7f20'

            // Lab Charger
            // ref: EVH4A12N5JU
            // sn: SN2207121299
            // cpid: 0aa4dffd-156e-4479-bde2-2c99fdb247ee

            const barCodeValues = {
              ref: 'EVH4A10N5PU',
              sn: 'EVB1A22P4KI3N214612002400152237AC',
              cpid: '0586006e-3d14-406d-9b71-3602010e7f20',
            };
            dispatch(enrolEvCharger(barCodeValues));
          }}
        >
          Click to add sample ev charger (Testing purpose only)
        </Button>
      )} */}
    </Flex>
  );
};
