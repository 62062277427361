import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonProps, Flex } from '@chakra-ui/react';

type AccountDashboardButtonProps = React.PropsWithChildren<{
  onClick: () => void;
  includeChevron?: boolean;
  includeBottomDivider?: boolean;
  ['data-testid']?: string;
}> &
  ButtonProps;

export default function AccountMenuButton({
  onClick,
  children,
  includeChevron = true,
  includeBottomDivider = true,
  ...buttonProps
}: AccountDashboardButtonProps) {
  return (
    <Button
      borderBottomLeftRadius={'5px'}
      borderBottomRightRadius={'25px'}
      borderTopRightRadius={'5px'}
      borderTopLeftRadius={'5px'}
      variant="ghost"
      width="100%"
      py={8}
      position={'relative'}
      px={4}
      onClick={() => onClick()}
      {...buttonProps}
    >
      <Flex justify={'space-between'} align={'center'} cursor={'pointer'} width="100%">
        {children}
        {includeChevron && <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />}
      </Flex>
      {includeBottomDivider && (
        <Box
          left={4}
          bottom={0}
          right={0}
          position={'absolute'}
          w={'calc(100% - 1rem)'}
          h="1px"
          bg={'rgba(151, 151, 151, 0.3)'}
        />
      )}
    </Button>
  );
}
