import { useTranslation } from 'react-i18next';

import { DayOfWeek, DayType, TOURateType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { TimeOfUsePeriod } from './../tariff-types';

export const TOU_RATE_TYPE_TO_DEFAULT_HOUR_MINUTE: Record<
  DayType,
  Record<TOURateType, Omit<TimeOfUsePeriod, 'days' | 'publicHoliday'>>
> = {
  weekday: {
    PEAK: {
      fromHour: 17,
      fromMinute: 0,
      toHour: 20,
      toMinute: 0,
    },
    PARTIAL_PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    OFF_PEAK: {
      fromHour: 20,
      fromMinute: 0,
      toHour: 17,
      toMinute: 0,
    },
    SINGLE: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
  },
  weekend: {
    PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    PARTIAL_PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    OFF_PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    SINGLE: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
  },
  allWeek: {
    PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    PARTIAL_PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    OFF_PEAK: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
    SINGLE: {
      fromHour: 0,
      fromMinute: 0,
      toHour: 0,
      toMinute: 0,
    },
  },
};

export const DAY_TYPE_TO_DAYS: Record<DayType, DayOfWeek[]> = {
  weekday: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
  weekend: ['SAT', 'SUN'],
  allWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
};

export const TOU_TARIFF_FORM_KEYS = ['peak', 'partialPeak', 'offPeak'] as const;
export type TOUTariffFormKey = (typeof TOU_TARIFF_FORM_KEYS)[number];

export const useTranslatedTouRateTypeToConfig = (): Record<
  TOUTariffFormKey,
  { title: string; type: TOURateType; colorIndex: number }
> => {
  const { t } = useTranslation();
  return {
    peak: {
      title: t('Energy Rates.peak'),
      type: 'PEAK',
      colorIndex: 2,
    },
    offPeak: {
      title: t('Energy Rates.off peak'),
      type: 'OFF_PEAK',
      colorIndex: 0,
    },
    partialPeak: {
      title: t('Energy Rates.partial peak'),
      type: 'PARTIAL_PEAK',
      colorIndex: 1,
    },
  };
};

export const TOU_RATE_TYPE_TO_FORM_KEY: Record<TOURateType, TOUTariffFormKey> = {
  PEAK: 'peak',
  OFF_PEAK: 'offPeak',
  PARTIAL_PEAK: 'partialPeak',
  SINGLE: 'offPeak',
};
