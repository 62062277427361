import React, { useState } from 'react';
import { Box, Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import NotificationGroupingSelectMenu from './NotificationGroupingSelectMenu';
import { NotificationGrouping } from './notificationsApi';
import NotificationsListChronological from './NotificationsListChronological';
import NotificationsListPriorityStatus from './NotificationsListPriorityStatus';
import NotificationsListReadStatus from './NotificationsListReadStatus';

type NotificationsArchiveState = {
  groupBy: NotificationGrouping;
};

export function NotificationsArchive() {
  const { t } = useTranslation();
  const [state, setState] = useState<NotificationsArchiveState>({
    groupBy: getInitialGrouping(),
  });
  const { groupBy } = state;

  function getInitialGrouping() {
    const fromLocalStorage = localStorage.getItem('notificationsArchiveGroupBy');
    if (fromLocalStorage) {
      return fromLocalStorage as NotificationGrouping;
    }

    return 'CHRONOLOGICAL';
  }

  function handleUpdateGrouping(newGrouping: NotificationGrouping) {
    localStorage.setItem('notificationsArchiveGroupBy', newGrouping);

    setState({
      groupBy: newGrouping,
    });
  }

  const selectedGroupingToComponent: Record<NotificationGrouping, React.JSX.Element> = {
    CHRONOLOGICAL: <NotificationsListChronological groupBy={groupBy} isArchived={true} />,
    UNREAD: <NotificationsListReadStatus groupBy={groupBy} isArchived={true} />,
    PRIORITY: <NotificationsListPriorityStatus groupBy={groupBy} isArchived={true} />,
  };

  return (
    <SlidingAnimationPageBase backURL={`../list?direction=back`} title={t('Notifications.archive')}>
      <Box px={3}>
        <NotificationGroupingSelectMenu onSelectGrouping={handleUpdateGrouping} selectedGrouping={groupBy} />

        <Divider mt={2} mb={4} />

        {selectedGroupingToComponent[groupBy]}
      </Box>
    </SlidingAnimationPageBase>
  );
}
