import React from 'react';
import { Box, Center, Flex, Skeleton } from '@chakra-ui/react';

import HomePageWidgetTile, { HomePageWidgetTileProps } from '../HomePageWidgetTile';

type GenericHomePageWidgetTileProps = {
  isLoaded: boolean;
} & HomePageWidgetTileProps;

export default function GenericHomePageWidgetTile({
  item,
  index,
  isEditing,
  onRemoveGridItem,
  isLoaded,
}: GenericHomePageWidgetTileProps) {
  return (
    <HomePageWidgetTile item={item} index={index} isEditing={isEditing} onRemoveGridItem={onRemoveGridItem}>
      <Center px={1} pt={5} pb={5} overflow="auto">
        <item.Icon w={16} h={16} flexShrink={0.5} />

        <Skeleton isLoaded={isLoaded} overflow="auto">
          <Box fontSize={22} ml={2}>
            {item.status}
          </Box>
        </Skeleton>
      </Center>
      <Flex pl={2} pb={1}>
        {item.title}
      </Flex>
    </HomePageWidgetTile>
  );
}
