import React, { useMemo } from 'react';
import { Box, Center, SkeletonCircle, useColorModeValue } from '@chakra-ui/react';

import { createCirclePercentageFilledIcon } from '../../styles/create-circle-percentage-filled-icon';
import { PiggyBankIcon } from '../../styles/custom-icons';
import { useGetInvestmentsQuery } from './savingsApi';

export default function SavingsStatusIcon() {
  const { data: investmentsData, isLoading } = useGetInvestmentsQuery();
  const progressColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  const savingsPercentage = useMemo(() => {
    if (investmentsData?.total_savings && investmentsData?.total_investment) {
      return (investmentsData.total_savings / investmentsData.total_investment) * 100;
    }
    return 0;
  }, [investmentsData]);

  const CircleIcon = createCirclePercentageFilledIcon(savingsPercentage);

  return (
    <Center flexDirection="column">
      <SkeletonCircle w="250px" h="250px" isLoaded={!isLoading} mx="auto">
        <Box position="relative" w="100%" h="100%">
          <PiggyBankIcon w="250px" h="250px" color={progressColor} position="absolute" p={14} />
          <CircleIcon color={progressColor} h="250px" w="250px" position="absolute" />
        </Box>
      </SkeletonCircle>
    </Center>
  );
}
