import { RefObject, useEffect, useMemo } from 'react';
import anime from 'animejs';

import { useGetDevicesQuery } from '../devices/devicesApi';
import { useLiveData } from '../site/live-data/liveDataApi';
import { SOLAR_THRESHOLD_KW } from '../solar/solar-helpers';

export enum EnergyFlowPathIndex {
  SolarHouse,
  SolarBattery,
  SolarEV,
  SolarGrid,
  GridHouse,
  GridEV,
  GridBattery,
  BatteryGrid,
  BatteryHouse,
  BatteryEV,
  GeneratorHouse,
  GeneratorEV,
}

export enum EnergyFlowGroupIndex {
  DoorAndWindows,
  PowerPole,
  HousePowerBox,
  SolarPanel,
  Battery,
  EV,
  Generator,
}

export type AnimationType =
  | 'SOLAR_TO_HOUSE'
  | 'SOLAR_TO_EV'
  | 'SOLAR_TO_GRID'
  | 'SOLAR_TO_BATTERY'
  | 'BATTERY_TO_HOUSE'
  | 'BATTERY_TO_EV'
  | 'BATTERY_TO_GRID'
  | 'GRID_TO_BATTERY'
  | 'GRID_TO_HOUSE'
  | 'GRID_TO_EV';
// @TODO: Add generator when available
// | 'GENERATOR_TO_HOUSE'
// | 'GENERATOR_TO_EV';

const ANIMATION_DURATION_MS = 1500;

/**
 * Custom hook responsible for animating the live energy flow on mount.
 * This function uses HTML element references to animate the flow of energy from live data.
 *
 * @param pathRefs - Array of references for the `path` DOM nodes being animated.
 * @param groupRefs - Array of references for the `g` DOM nodes animated along each associated `path`.
 * @param circleRefs - Array of references for the `circle` DOM nodes animated along each associated `path`.
 * @param isParentLoaded - Whether the parent component has loaded the live data.
 * @param sunToSolarPanelLineRef - Reference for the sun to solar panel line animation to run. Optional.
 */
export function useEnergyAnimation(
  pathRefs: RefObject<SVGPathElement>[],
  groupRefs: RefObject<SVGGElement>[],
  circleRefs: RefObject<SVGCircleElement>[],
  isParentLoaded: boolean,
  sunToSolarPanelLineRef?: RefObject<SVGPathElement>
) {
  const { data: siteDevices } = useGetDevicesQuery();
  const { data: liveDataSummary } = useLiveData();
  const { solar, grid, battery, consumption, appliances: liveDevicesData } = liveDataSummary;

  // Get EV power data, if any
  const siteEVDevice = useMemo(
    () => siteDevices.find((device) => device.assignment.startsWith('load_ev')),
    [siteDevices]
  );
  const ev =
    siteEVDevice &&
    liveDevicesData.find(
      (device) => device?.appliance_id === siteEVDevice?.appliance_id || device.assignment === siteEVDevice?.assignment
    )?.power;

  // Get generator power data
  // @TODO: Generator is not yet supported, so this might change
  // const siteGeneratorDevice = useMemo(
  //   () => siteDevices.find((device) => device.assignment.startsWith('load_generator')),
  //   [siteDevices]
  // );
  // const generator =
  //   siteHasGenerator &&
  //   useMemo(() => {
  //     return liveDevicesData.find(
  //       (device) =>
  //         device?.appliance_id === siteGeneratorDevice?.appliance_id ||
  //         device.assignment === siteGeneratorDevice?.assignment
  //     )?.power;
  //   }, [liveDevicesData, siteGeneratorDevice]);

  useEffect(() => {
    if (isParentLoaded && !anime.running.length) {
      const animation = getAnimationToRun(solar, grid, consumption, battery, ev);

      if (animation) {
        const { color, path, steps, sunToSolarPanelLine } = getAnimationConfig(
          animation,
          pathRefs,
          groupRefs,
          sunToSolarPanelLineRef
        );

        // Animate the coloured path under the circles
        if (path.current) {
          const pathLength = path.current.getTotalLength();
          path.current.style.strokeDasharray = `${pathLength}`;
          path.current.style.strokeDashoffset = `${pathLength}`;
          anime({
            targets: path.current,
            strokeDashoffset: [pathLength, 0],
            stroke: color,
            duration: ANIMATION_DURATION_MS,
            easing: 'linear',
            loop: false,
            delay: 500,
          });
          // Animate the circles along the path
          const animePath = anime.path(path.current);
          for (const [i, circleRef] of circleRefs.entries()) {
            if (circleRef.current) {
              circleRef.current.style.stroke = color;
              if (!circleRef.current.hasAttribute('fill')) {
                circleRef.current.style.fill = color;
              }
              circleRef.current.style.display = 'block';
              anime({
                targets: circleRef.current,
                translateX: animePath('x'),
                translateY: animePath('y'),
                duration: ANIMATION_DURATION_MS,
                easing: 'linear',
                delay: 500 + i * 300,
                loop: false,
                changeBegin(anim) {
                  if (i === circleRefs.length - 1 && path.current) {
                    anime({
                      targets: path.current,
                      strokeDashoffset: [path.current.style.strokeDashoffset, -pathLength],
                      stroke: color,
                      duration: anim.duration - anim.currentTime,
                      easing: 'linear',
                      loop: false,
                    });
                  }
                },
                complete: () => {
                  if (circleRef.current) circleRef.current.style.display = 'none';
                },
              });
            }
          }
        }

        // This animation only runs if the ref is provided, ie when the sun is visible
        if (sunToSolarPanelLine?.current) {
          const path = sunToSolarPanelLine.current;
          const length = path.getTotalLength();
          path.style.strokeDasharray = `${length}`;
          path.style.strokeDashoffset = `${length}`;
          path.style.display = 'block';

          anime({
            targets: path,
            strokeDashoffset: 0,
            duration: 1100,
            easing: 'easeOutSine',
            loop: false,
            complete: () => {
              anime({
                targets: path,
                strokeDashoffset: length,
                duration: 1000,
                easing: 'easeOutSine',
                loop: false,
                complete: () => (path.style.display = 'none'),
              });
            },
          });
        }

        for (const [_, step] of steps.entries()) {
          const { ref, startTime, endTime, includeFill } = step;
          if (ref.current) {
            const paths = ref.current.querySelectorAll('path');
            anime({
              targets: paths,
              stroke: color,
              fill: includeFill && color,
              duration: endTime - startTime,
              delay: startTime,
              loop: false,
              changeBegin: () => {
                paths.forEach((path: SVGPathElement) => {
                  path.classList.add(`pulse`);
                });
              },
              complete: () => {
                // Reset the path colors to their original state after the animation step is complete
                paths.forEach((path: SVGPathElement) => {
                  path.classList.remove(`pulse`);
                  path.setAttribute('stroke', '#9FA0A4');
                  if (includeFill) {
                    path.hasAttribute('fill') && path.setAttribute('fill', '#9FA0A4');
                  }
                });
              },
            });
          }
        }
      }
    }
  }, [solar, grid, battery, ev, consumption, isParentLoaded, anime.running]);

  return;
}

type AnimationConfig = {
  color: string;
  path: RefObject<SVGPathElement>;
  steps: { ref: RefObject<SVGGElement>; startTime: number; endTime: number; includeFill?: boolean }[];
  sunToSolarPanelLine?: RefObject<SVGPathElement>;
};
function getAnimationConfig(
  animationType: AnimationType,
  pathRefs: RefObject<SVGPathElement>[],
  groupRefs: RefObject<SVGGElement>[],
  sunToSolarPanelLineRef?: RefObject<SVGPathElement>
) {
  const animationTypeMap: Record<AnimationType, AnimationConfig> = {
    SOLAR_TO_HOUSE: {
      color: '#27AE60',
      path: pathRefs[EnergyFlowPathIndex.SolarHouse],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.SolarPanel], startTime: 0, endTime: 2500 },
        { ref: groupRefs[EnergyFlowGroupIndex.DoorAndWindows], startTime: 2000, endTime: 3400 },
      ],
      sunToSolarPanelLine: sunToSolarPanelLineRef,
    },
    SOLAR_TO_BATTERY: {
      color: '#27AE60',
      path: pathRefs[EnergyFlowPathIndex.SolarBattery],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.SolarPanel], startTime: 0, endTime: 2300 },
        { ref: groupRefs[EnergyFlowGroupIndex.Battery], startTime: 2000, endTime: 3400, includeFill: true },
      ],
      sunToSolarPanelLine: sunToSolarPanelLineRef,
    },
    SOLAR_TO_EV: {
      color: '#27AE60',
      path: pathRefs[EnergyFlowPathIndex.SolarEV],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.SolarPanel], startTime: 0, endTime: 2200 },
        { ref: groupRefs[EnergyFlowGroupIndex.EV], startTime: 2000, endTime: 3400 },
      ],
      sunToSolarPanelLine: sunToSolarPanelLineRef,
    },
    SOLAR_TO_GRID: {
      color: '#27AE60',
      path: pathRefs[EnergyFlowPathIndex.SolarGrid],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.SolarPanel], startTime: 0, endTime: 2200 },
        { ref: groupRefs[EnergyFlowGroupIndex.HousePowerBox], startTime: 1500, endTime: 3000, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.PowerPole], startTime: 2000, endTime: 3400 },
      ],
      sunToSolarPanelLine: sunToSolarPanelLineRef,
    },
    GRID_TO_HOUSE: {
      color: '#DB5E52',
      path: pathRefs[EnergyFlowPathIndex.GridHouse],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.PowerPole], startTime: 0, endTime: 2000 },
        { ref: groupRefs[EnergyFlowGroupIndex.HousePowerBox], startTime: 900, endTime: 2500, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.DoorAndWindows], startTime: 2100, endTime: 3400 },
      ],
    },
    GRID_TO_EV: {
      color: '#DB5E52',
      path: pathRefs[EnergyFlowPathIndex.GridEV],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.PowerPole], startTime: 0, endTime: 1900 },
        { ref: groupRefs[EnergyFlowGroupIndex.HousePowerBox], startTime: 800, endTime: 2200, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.EV], startTime: 2100, endTime: 3400 },
      ],
    },
    GRID_TO_BATTERY: {
      color: '#DB5E52',
      path: pathRefs[EnergyFlowPathIndex.GridBattery],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.PowerPole], startTime: 0, endTime: 1900 },
        { ref: groupRefs[EnergyFlowGroupIndex.HousePowerBox], startTime: 1100, endTime: 2600, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.Battery], startTime: 2000, endTime: 3400, includeFill: true },
      ],
    },
    BATTERY_TO_HOUSE: {
      color: '#42B4E6',
      path: pathRefs[EnergyFlowPathIndex.BatteryHouse],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.Battery], startTime: 0, endTime: 2100, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.DoorAndWindows], startTime: 1900, endTime: 3400 },
      ],
    },
    BATTERY_TO_EV: {
      color: '#42B4E6',
      path: pathRefs[EnergyFlowPathIndex.BatteryEV],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.Battery], startTime: 0, endTime: 1900, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.EV], startTime: 1900, endTime: 3400 },
      ],
    },
    BATTERY_TO_GRID: {
      color: '#42B4E6',
      path: pathRefs[EnergyFlowPathIndex.BatteryGrid],
      steps: [
        { ref: groupRefs[EnergyFlowGroupIndex.Battery], startTime: 0, endTime: 1900, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.HousePowerBox], startTime: 1100, endTime: 2700, includeFill: true },
        { ref: groupRefs[EnergyFlowGroupIndex.PowerPole], startTime: 1900, endTime: 3400 },
      ],
    },
    // @TODO: Add generator when available
    // GENERATOR_TO_EV: {
    //   color: '#F2C94C',
    //   path: pathRefs[EnergyFlowPathIndex.GeneratorEV],
    //   steps: [
    //     {
    //       ref: groupRefs[EnergyFlowGroupIndex.Generator],
    //       startTime: 0,
    //       endTime: 800,
    //     },
    //     { ref: groupRefs[EnergyFlowGroupIndex.EV], startTime: 2800, endTime: 3400 },
    //   ],
    // },
    // GENERATOR_TO_HOUSE: {
    //   color: '#F2C94C',
    //   path: pathRefs[EnergyFlowPathIndex.GeneratorHouse],
    //   steps: [
    //     {
    //       ref: groupRefs[EnergyFlowGroupIndex.Generator],
    //       startTime: 0,
    //       endTime: 800,
    //     },
    //     { ref: groupRefs[EnergyFlowGroupIndex.DoorAndWindows], startTime: 2800, endTime: 3400 },
    //   ],
    // },
  };
  return animationTypeMap[animationType];
}

function getAnimationToRun(solar: number, grid: number, consumption: number, battery = 0, ev = 0) {
  // Build a list of animation possibilities
  const possibleAnimations: AnimationType[] = [];

  if (solar > SOLAR_THRESHOLD_KW) {
    if (consumption > 0) possibleAnimations.push('SOLAR_TO_HOUSE');
    if (battery > 0) possibleAnimations.push('SOLAR_TO_BATTERY');
    if (ev > 0) possibleAnimations.push('SOLAR_TO_EV');
    if (grid < 0) possibleAnimations.push('SOLAR_TO_GRID');
  }
  if (grid > 0) {
    if (consumption > 0) possibleAnimations.push('GRID_TO_HOUSE');
    if (battery > 0) possibleAnimations.push('GRID_TO_BATTERY');
    if (ev > 0) possibleAnimations.push('GRID_TO_EV');
  }
  if (battery < 0) {
    if (consumption > 0) possibleAnimations.push('BATTERY_TO_HOUSE');
    if (ev > 0) possibleAnimations.push('BATTERY_TO_EV');
    if (grid < 0) possibleAnimations.push('BATTERY_TO_GRID');
  }
  // @TODO: Add generator when available
  // if (generator && generator > 0) {
  //   availableAnimations.push('GENERATOR_TO_HOUSE');
  //   if (evCharger && evCharger > 0) availableAnimations.push('GENERATOR_TO_EV');
  // }

  if (possibleAnimations.length > 0) {
    // Select a random animation from the list
    const randomIndex = Math.floor(Math.random() * possibleAnimations.length);
    return possibleAnimations[randomIndex];
  } else return null;
}
