import React, { useEffect, useRef } from 'react';
import { Browser } from '@capacitor/browser';
import { Box } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { IS_IOS } from '../../common/constants';

/**
 * This component is responsible for handling an oauth response from the cIAM IDP. Implemented because logging in to
 * the IDP via the Cognito Hosted UI uses a very long encoded `state` query parameter when redirecting the user
 * to the IDP's authorize endpoint (where they log in).
 *
 * This causes a very specific bug on Safari, where a memory leak occurs and the browser shuts down. This is an unavoid-
 * able bug which occurs on the cIAM website, which they are currently unable to resolve, so we need to find a work-
 * around -- achieved by this implementation.
 *
 * The following steps occur at login time:
 * 1. The user is sent directly to the cIAM authorize endpoint (ignoring Cognito), where the `redirect_uri` is set to
 *    the app itself, instead of the Cognito hosted ui. The user logs in, or signs up, and is redirected back to this
 *    page in the app.
 * 2. This page will call the `Auth.feredatedSignIn` method, which is usually responsible for redirecting the user to
 *    the IDP login/signup page, however it automatically detects the current session (created above) from the IDP, and
 *    instead will detect that the user is logged in on the IDP side, then log the user in on the RP side (Cognito).
 * 3. Cognito's redirect_uri is set to the main route of the app, which uses the `code` returned in query parameters
 *    to log the user into Cognito.
 *
 * This long state query parameter cannot currently be reduced in size, or removed due to a constraint from Cognito.
 * We have discussed this at length with their Premium Support team, who have added our issue to a list of existing
 * similar issues related to this constraint, and informed us that a feature request currently exists in Cognito to
 * reduce the size of the `state` query parameter. Until then, we're stuck with this janky work-around.
 */

/* istanbul ignore next -- @preserve */
export function OAuthRedirect() {
  const isFirstRender = useRef(true);
  const [search] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    async function logInToCIAM() {
      // Not sure if this is a bug, but the browser does not close itself on iOS, so we have to do so manually.
      if (IS_IOS) await Browser.close();
      const code = search.get('code');

      if (code) {
        localStorage.setItem('oidcCode', code);
        // @TODO: use this code to get a token? is it still valid? or map to cIAM creds in Cognito
      }

      Auth.federatedSignIn({ customProvider: 'cIAM' });
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
      logInToCIAM();
    }
  }, []);

  // @TODO: use base page component
  return (
    <Box>
      <CenteredLoader text={`${t('Account.logging in')}...`} />
    </Box>
  );
}
