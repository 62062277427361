import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import ReactGA from 'react-ga4';
import { GaOptions } from 'react-ga4/types/ga4';
import { v4 as uuid } from 'uuid';

import { UserData } from 'clipsal-cortex-types/src/api/api-user';

import { RootState } from '../../app/store';
import { get } from '../../common/api/api-helpers';
import { APP_VERSION, IS_DEMO_LOGIN, IS_IOS } from '../../common/constants';
import { CIAM_IDMS_BASE_URL } from '../auth/auth-helpers';
import customFetch from './custom-ciam-interceptor';

export interface User {
  isLoggedIn: boolean;
  token: string;
  role: string;
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cognitoUser: CognitoUser | null;
  sendEmailAction: string;
  userID: number;
  tenantID: number;
  locale: string;
}

export const checkUserLoggedIn = createAsyncThunk('user/checkUserLoggedIn', async () => {
  let session: CognitoUserSession;
  let isLoggedIn = false;
  let idToken = '';
  let accessToken = '';
  let cognitoUser: CognitoUser | null = null;

  if (localStorage.getItem('isDemoLogin') === 'true') {
    return {
      ...initialState,
      isLoggedIn: true,
    };
  }

  try {
    session = await Auth.currentSession();
    cognitoUser = await Auth.currentUserPoolUser();
    isLoggedIn = true;
    idToken = session.getIdToken().getJwtToken();
    accessToken = session.getAccessToken().getJwtToken();
  } catch (error) {
    console.error(error);
  }

  // Return the successfully authorized user's token.
  return {
    ...initialState,
    cognitoUser,
    isLoggedIn,
    accessToken,
    token: idToken,
  };
});

/**
 * Logs the user into Cognito.
 *
 * Intended solely to fetch and save the user's API token to the User slice, so they can access the Clippy end-points.
 */
export const logIntoCognito = createAsyncThunk<User, { username: string; password: string }>(
  'user/logIntoCognito',
  async ({ username, password }) => {
    let token = '';
    let accessToken = '';
    let isLoggedIn = false;
    let cognitoUser: CognitoUser | null = null;

    try {
      cognitoUser = await Auth.signIn(username, password);
      token = cognitoUser?.getSignInUserSession()?.getIdToken()?.getJwtToken() ?? '';
      accessToken = cognitoUser?.getSignInUserSession()?.getAccessToken()?.getJwtToken() ?? '';
      isLoggedIn = true;
    } catch (e) {
      // Reject the promise where we can't login
      throw new Error((e as Error).message);
    }

    return {
      ...initialState,
      isLoggedIn,
      accessToken,
      cognitoUser,
      token,
    };
  }
);

export const updateUserDetails = createAsyncThunk<User, { firstName: string; lastName: string }, { state: RootState }>(
  'user/updateUserDetails',
  async (body, { getState }) => {
    // Update IDMS API user data
    const { data: ciamUserData } = await customFetch.patch(
      `${CIAM_IDMS_BASE_URL}/v1/customer-journey/identity/user/users`,
      { ...body, profileLastUpdateSource: 'SchneiderHome' }
    );

    return {
      ...getState().user,
      firstName: ciamUserData.firstName,
      lastName: ciamUserData.lastName,
    };
  }
);

export const fetchUserDetails = createAsyncThunk<
  User,
  { isProduction: boolean; isNotDevelopment: boolean; version: string },
  { state: RootState }
>('user/fetchUserDetails', async ({ isNotDevelopment }, { getState }) => {
  let userData: UserData | null = null;

  try {
    [userData] = await Promise.all([get<UserData>('/v1/user')]);
  } catch (error) {
    throw new Error((error as Error).message);
  }

  let email = userData?.email;
  let firstName = userData?.first_name;
  let lastName = userData?.last_name;

  if (!IS_DEMO_LOGIN) {
    // Only send events to sentry in staging and production environments
    if (isNotDevelopment && userData?.user_id) {
      Sentry.setUser({ id: userData.user_id.toString() });
    }

    try {
      // Fetch IDMS API user data
      const { data: userData } = await customFetch.get(`${CIAM_IDMS_BASE_URL}/v1/customer-journey/identity/user/users`);

      email = userData.email;
      firstName = userData.firstName;
      lastName = userData.lastName;
    } catch (e) {
      console.error('Failed to fetch user details from cIAM, falling back to RDS user details');
    }

    // Set up tracking for the user, only when logged in
    if (isNotDevelopment && !(window as any).Cypress && userData?.user_id) {
      const setupGA = () => {
        const trackingId = import.meta.env.VITE_GA_MEASUREMENT_ID as string;
        let clientId = localStorage.getItem('ga:clientId');

        if (!clientId) {
          // Generate random id for client identifier, put into local storage (should only run on first open).
          clientId = uuid();
          localStorage.setItem('ga:clientId', clientId);
        }

        const gaOptions: GaOptions = {
          userId: userData?.user_id.toString(),
          cookieDomain: 'none',
          clientId: clientId ?? undefined,
        };

        ReactGA.initialize(trackingId, { gaOptions });
        ReactGA.set({
          checkProtocolTask: null,
          user_properties: {
            app_version: APP_VERSION,
          },
        });
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      };

      if (IS_IOS) {
        const response = await AppTrackingTransparency.requestPermission();
        if (response.status === 'authorized') {
          setupGA();
        }
      } else {
        setupGA();
      }
    }
  }

  return {
    ...getState().user,
    email,
    firstName,
    lastName,
    phone: userData?.phone,
    sendBillsAction: userData?.send_bills_action,
    userID: userData?.user_id,
    role: userData?.role,
    tenantID: userData?.tenant_id,
  };
});

export const initialState: User = {
  isLoggedIn: false,
  cognitoUser: null,
  token: '',
  accessToken: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phone: '',
  sendEmailAction: '',
  userID: 0,
  tenantID: 0,
  locale: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    },
    logOut: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUserLoggedIn.fulfilled, (state, action) => {
        return {
          ...state,
          isLoggedIn: action.payload.isLoggedIn,
          token: action.payload.token,
          cognitoUser: action.payload.cognitoUser,
          accessToken: action.payload.accessToken,
        };
      })
      .addCase(logIntoCognito.fulfilled, (state, action) => {
        return {
          ...state,
          cognitoUser: action.payload.cognitoUser,
          isLoggedIn: action.payload.isLoggedIn,
          token: action.payload.token,
          accessToken: action.payload.accessToken,
        };
      })
      .addCase(logIntoCognito.rejected, () => {
        return initialState;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        return action.payload ? action.payload : state;
      })
      .addCase(fetchUserDetails.rejected, () => {
        return initialState;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        return action.payload ? action.payload : state;
      });
  },
});

export const { logIn, logOut } = userSlice.actions;

export const selectUser = (state: RootState): User => {
  return state.user;
};

export default userSlice.reducer;
