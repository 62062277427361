import React, { useRef, useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoFilter } from 'react-icons/io5';

import { NotificationGrouping } from './notificationsApi';

type Props = {
  onSelectGrouping: (grouping: NotificationGrouping) => void;
  selectedGrouping: NotificationGrouping;
};

export default function NotificationGroupingSelectMenu({ onSelectGrouping, selectedGrouping }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  useOutsideClick({
    ref: popoverRef,
    handler: () => setIsOpen(false),
  });

  function handleSelectGrouping(grouping: NotificationGrouping) {
    setIsOpen(false);
    onSelectGrouping(grouping);
  }

  return (
    <Flex align="center" justify="space-between" pb={1}>
      <Popover isOpen={isOpen} placement="bottom-start">
        <PopoverTrigger>
          <IconButton
            onClick={() => setIsOpen(true)}
            icon={<IoFilter size={23} />}
            aria-label={'Sort'}
            bg="none"
            data-testid="notification-groupby-menu-btn"
          />
        </PopoverTrigger>

        <PopoverContent
          ref={popoverRef}
          borderRadius={8}
          bg={'#fff'}
          _dark={{
            bg: '#434B4D',
          }}
          w="100%"
        >
          <PopoverHeader>{t('Common.sort by')}:</PopoverHeader>
          <PopoverBody data-testid="notification-groupby-menu" p={0}>
            <Button
              w="100%"
              p={3}
              borderRadius={0}
              bg="unset"
              onClick={() => handleSelectGrouping('UNREAD')}
              data-testid="groupby-unread-btn"
              data-selected={selectedGrouping == 'UNREAD' ? true : undefined}
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text mr={2} fontWeight={500}>
                  {t('Notifications.unread')}
                </Text>
                {selectedGrouping == 'UNREAD' && <CheckIcon color="#3DCD58" />}
              </Flex>
            </Button>
            <Divider p={'unset'} />
            <Button
              w="100%"
              p={3}
              borderRadius={0}
              bg="unset"
              onClick={() => handleSelectGrouping('PRIORITY')}
              data-testid="groupby-priority-btn"
              data-selected={selectedGrouping == 'PRIORITY' ? true : undefined}
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text mr={2} fontWeight={500}>
                  {t('Notifications.priority')}
                </Text>
                {selectedGrouping == 'PRIORITY' && <CheckIcon color="#3DCD58" />}
              </Flex>
            </Button>
            <Divider />
            <Button
              w="100%"
              p={3}
              borderRadius={0}
              bg="unset"
              onClick={() => handleSelectGrouping('CHRONOLOGICAL')}
              data-testid="groupby-chronological-btn"
              data-selected={selectedGrouping == 'CHRONOLOGICAL' ? true : undefined}
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text mr={2} fontWeight={500}>
                  {t('Notifications.chronological')}
                </Text>
                {selectedGrouping == 'CHRONOLOGICAL' && <CheckIcon color="#3DCD58" />}
              </Flex>
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
