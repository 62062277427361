import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useNavigate } from 'react-router-dom';

import { IS_IOS, IS_NATIVE } from '../constants';

export function useAuthRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    /* istanbul ignore if -- @preserve */
    if (IS_NATIVE) {
      // Used for OIDC auth
      CapacitorApp.addListener('appUrlOpen', async (data) => {
        const params = new URLSearchParams(data.url.split('?')?.[1]);
        const code = params.get('code');
        const state = params.get('state');

        // Hacky workaround for broken cIAM website
        if (data.url.includes('oauth_response')) {
          navigate('/oauth_response', { replace: true });
        } else if (code) {
          // The root domain of the webview differs according to the platform.
          // We reload the page with the passed query parameters to authorize with cIAM through federated identities.
          const rootDomain = IS_IOS ? 'capacitor://localhost' : 'http://localhost';
          location.href = `${rootDomain}/?code=${code}&state=${state ?? ''}`;

          // Not sure if this is a bug, but the browser does not close itself on iOS, so we have to do so manually.
          if (IS_IOS) await Browser.close();
        }
      });
    }
  }, []);
}
