import React, { useMemo } from 'react';
import { Center, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { DateRangeType } from '../../../../common/components/DateRangeTypePicker';
import { useGetUsageQuery } from '../../../activity/activityApi';
import { useGetCostsQuery } from '../../../site/costApi';
import { selectCurrentDayForSite } from '../../../site/siteSlice';

export function SummaryInfo() {
  const { i18n } = useTranslation();
  const textColor = useColorModeValue('customGrey.900', 'customGrey.400');
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: costsData, isLoading: isCostsLoading } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const { data: usageData, isLoading: isUsageLoading } = useGetUsageQuery(currentDay, DateRangeType.Day);
  const isLoaded = !isUsageLoading && !isCostsLoading;

  const { consumedToday, todaysCost } = useMemo(
    () => ({
      consumedToday: Intl.NumberFormat('en', {
        notation: 'compact',
        maximumFractionDigits: 2,
      }).format(usageData.energyUsageData[0]?.consumed ?? 0),
      todaysCost: formatDollarsCents(costsData.costData[0]?.total_cost || 0),
    }),
    [usageData.energyUsageData, costsData.costData]
  );

  return (
    <Center flexDirection="column" margin="auto" mb={2} textAlign={'center'} fontSize="sm" color={textColor}>
      <Skeleton isLoaded={isLoaded} minW={4} borderRadius={4}>
        <Text data-testid="summary-consumed-today">
          {consumedToday} kWh {i18n.t('Home Screen.consumed today').toUpperCase()}
        </Text>
        <Text>
          {i18n.t('Home Screen.todays cost').toUpperCase()}:{' '}
          <Text as="span" color="schneiderSkyBlue.200" data-testid="summary-todays-cost">
            {todaysCost}
          </Text>
        </Text>
      </Skeleton>
    </Center>
  );
}
