import React from 'react';
import { Center } from '@chakra-ui/react';

import VerticalSliderSelect from './VerticalSliderSelect';

type Props = {
  onChange: (value: { hours?: string; minutes?: string }) => void;
  gradientColor: string;
  borderColor: string;
  width?: string | number;
  sliderColumnWidth?: string | number;
  labels?: { hours: string; minutes: string };
} & State;

type State = {
  defaultHours: string;
  defaultMinutes: string;
};

const NEGATIVE_MINUTES = Array.from(Array(59))
  .map((_, i) => {
    const minute = i + 1;
    return minute < 10 ? `-0${minute}` : `-${minute}`;
  })
  .reverse();

const POSITIVE_MINUTES = Array.from(Array(59)).map((_, i) => {
  const minute = i + 1;
  return minute < 10 ? `+0${minute}` : `+${minute}`;
});

const MINUTE_OPTIONS = ['00', ...POSITIVE_MINUTES, ...NEGATIVE_MINUTES];
const HOUR_OPTIONS = ['0', '+1', '+2', '+3', '+4', '+5', '+6', '-6', '-5', '-4', '-3', '-2', '-1'];

export default function OffsetTimePicker({
  defaultHours,
  defaultMinutes,
  onChange,
  gradientColor,
  borderColor,
  width = '100%',
  sliderColumnWidth,
  labels = { hours: 'hours', minutes: 'mins' },
}: Props) {
  return (
    <Center
      pos="relative"
      w={width}
      height={'200px'}
      _after={{
        pos: 'absolute',
        left: 0,
        right: 0,
        content: "''",
        display: 'block',
        height: 'calc(50% - 32px / 2)',
        zIndex: 1,
        pointerEvents: 'none',
        bottom: '-0.5px',
        borderTop: `1px solid ${borderColor}`,
        background: `linear-gradient(to bottom, rgba(${gradientColor}, 0.65) 0%, rgba(${gradientColor}, 1) 100%)`,
      }}
      _before={{
        pos: 'absolute',
        left: 0,
        right: 0,
        content: "''",
        display: 'block',
        height: 'calc(50% - 32px / 2)',
        zIndex: 1,
        pointerEvents: 'none',
        top: '-0.5px',
        borderBottom: `1px solid ${borderColor}`,
        background: `linear-gradient(to top, rgba(${gradientColor}, 0.65) 0%, rgba(${gradientColor}, 1) 100%)`,
      }}
    >
      <VerticalSliderSelect
        width={sliderColumnWidth}
        defaultValue={defaultHours}
        items={HOUR_OPTIONS}
        onSelectItem={(hours) => {
          onChange({
            hours: hours as string,
          });
        }}
        data-testid="select-hours"
        fontSize={'24px'}
        label={labels.hours}
        loop
      />
      <VerticalSliderSelect
        width={sliderColumnWidth}
        defaultValue={defaultMinutes}
        items={MINUTE_OPTIONS}
        onSelectItem={(minutes) => {
          onChange({
            minutes: minutes as string,
          });
        }}
        data-testid="select-minutes"
        fontSize={'24px'}
        label={labels.minutes}
        loop
      />
    </Center>
  );
}
