import React, { cloneElement } from 'react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  ColorMode,
  Flex,
  Grid,
  IconButton,
  Image,
  Link,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { LogoutIcon } from 'clipsal-cortex-icons/src/custom-icons';
import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import useSelectedIndexFromLinks, { SiteNavLink } from 'clipsal-cortex-ui/src/components/bottom-nav/custom-nav-hooks';
import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';

import brandingLogoBlack from '../../assets/images/clipsal_logo_black.svg';
import brandingLogoWhite from '../../assets/images/clipsal_logo_white.svg';

interface Props {
  isSticky?: boolean;
  links: SiteNavLink[];
}

export default function SideNav({ links }: Props) {
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const backgroundColor = useColorModeValue('white', 'black');
  const logoImage = useColorModeValue(brandingLogoBlack, brandingLogoWhite);
  const colorModeIcon = useColorModeValue(<SunIcon />, <MoonIcon />);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const commonProps = {
    py: 4,
    justifyContent: 'start',
    variant: 'ghost',
    size: 'lg',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
  } as ButtonProps;

  return (
    <Flex
      overflow={'auto'}
      background={backgroundColor}
      minHeight="100vh"
      direction="column"
      justifyContent="space-between"
    >
      <Flex marginTop={`calc(0px + env(safe-area-inset-top))`} direction="column">
        <Grid justifyContent="center" alignItems="center" m={5}>
          <Image src={logoImage} width={'90%'} alt="Tenant branding logo" />
        </Grid>
        {links.map((link, i) => (
          <SideNavLink
            key={link.route}
            link={link}
            index={i}
            colorMode={colorMode}
            links={links}
            commonProps={commonProps}
          />
        ))}
      </Flex>

      <Flex direction={'column'}>
        <Box mb={2} alignSelf={'center'}>
          <IconButton
            onClick={toggleColorMode}
            aria-label="Change color scheme"
            icon={colorModeIcon}
            data-testid={`color-scheme-link-button`}
          />
        </Box>

        <Box mb={3} fontSize={'lg'} textAlign={'center'}>
          <Link onClick={() => navigate('/sites')} data-testid={`sites-link-button`}>
            View all sites
          </Link>
        </Box>

        <Button
          {...commonProps}
          my={2}
          ml={2}
          background={backgroundColor}
          onClick={onOpen}
          fontWeight={500}
          data-testid={`logout-link-button`}
        >
          <LogoutIcon mr={2} w={'28px'} h={'28px'} />
          Logout
        </Button>

        <AlertDialogModal
          {...{ isOpen, onClose }}
          header="Logout"
          subHeader="Are you sure you want to logout?"
          confirmButtonName="Logout"
          onConfirm={() => navigate('/logout')}
        />
      </Flex>
    </Flex>
  );
}

interface SideNavLinkProps {
  index: number;
  link: SiteNavLink;
  colorMode: ColorMode;
  links: SiteNavLink[];
  commonProps: ButtonProps;
}

function SideNavLink({
  link: { route, routeName, ariaLabel, icon, activeIcon },
  index,
  colorMode,
  links,
  commonProps,
}: SideNavLinkProps) {
  const navigate = useNavigate();
  const backgroundColor = useColorModeValue('white', 'black');
  const [selectedIndex, setSelectedIndex] = useSelectedIndexFromLinks(links);

  const formattedRouteName = routeName.replace('/', '');
  const Icon = selectedIndex === index ? activeIcon : icon;
  const fontWeight = selectedIndex === index ? 'bolder' : 500;
  const buttonStyle = colorMode === 'dark' ? 'customGrey.500' : 'customGrey.200';
  const background = selectedIndex === index ? buttonStyle : backgroundColor;

  const handleClick = () => {
    setSelectedIndex(index);
    navigate(route);
    window.scrollTo({ top: 0 });
  };

  return (
    <Button
      {...commonProps}
      my={1}
      ml={2}
      data-testid={`${formattedRouteName}-link-button`}
      background={background}
      onClick={handleClick}
      aria-label={ariaLabel}
      fontWeight={fontWeight}
    >
      {cloneElement(Icon, { w: '32px', h: '32px' })}
      <Text ml={2}>{capitalizeFirst(ariaLabel)}</Text>
    </Button>
  );
}
