import React, { useMemo, useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Center, Divider, IconButton, Text } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import CustomDatePicker from '../../../common/components/custom-date-picker/CustomDatePicker';
import { getLocaleForDateFns, SupportedLocales, useTranslatedMonths } from '../../../utils/common/common-utils';
import { useTariffFormContext } from '../useTariffFormContext';
import { CustomSelect } from './../../../common/components/CustomSelect';
import SEHomeCard from './../../../common/components/SEHomeCard';
import { adjustSeasonDate } from './../tariff-helpers';
import { useTranslatedSeasonOptions } from './tariff-season-helpers';

export type ActiveDatePickerState = {
  seasonIndex: null | number;
  type: 'start' | 'end';
};

type SeasonSelectFormProps = {
  index: number;
  resetError: () => void;
  onUpdateActiveDatePickerState: (newState: ActiveDatePickerState) => void;
} & { activeDatePickerState: ActiveDatePickerState };

const SeasonSelectForm = ({
  index,
  resetError,
  activeDatePickerState,
  onUpdateActiveDatePickerState,
}: SeasonSelectFormProps) => {
  const { setValue, control, getValues } = useTariffFormContext();
  const { i18n, t } = useTranslation();

  const [{ isDeleteModalOpen, seasonIndex, seasonName }, setState] = useState({
    isDeleteModalOpen: false,
    seasonIndex: 0,
    seasonName: '',
  });

  const { seasonIndex: activeSeasonIndex, type } = activeDatePickerState;
  const isSeasonStartDatePickerOpen = activeSeasonIndex === index && type === 'start';
  const isSeasonEndDatePickerOpen = activeSeasonIndex === index && type === 'end';
  const onCloseDatePicker = () => onUpdateActiveDatePickerState({ seasonIndex: null, type: 'start' });
  const SEASON_OPTIONS = useTranslatedSeasonOptions();
  const MONTHS = useTranslatedMonths();
  const seasons = useWatch({ control, name: 'tariff.seasons' });
  const { name, fromDate, toDate, fromMonth, toMonth } = seasons[index];
  const { seasonStartDate, seasonEndDate, selectedSeason, seasonOptions } = useMemo(() => {
    const seasonStartDate = adjustSeasonDate(fromMonth, fromDate);
    const seasonEndDate = adjustSeasonDate(toMonth, toDate);
    const seasonOptions = [...SEASON_OPTIONS];
    let selectedSeason = SEASON_OPTIONS.find((option) => option.value === name);

    // cannot always guarantee that the season name is in the options,
    // so we add it if it is not there
    if (!selectedSeason) {
      selectedSeason = { label: name, value: name };
      seasonOptions.push(selectedSeason);
    }
    return { seasonStartDate, seasonEndDate, selectedSeason, seasonOptions };
  }, [fromDate, toDate, fromMonth, toMonth, name]);

  return (
    <SEHomeCard
      borderTopLeftRadius={'5px'}
      borderBottomRightRadius={'39px'}
      mt={4}
      pr={0}
      pb={4}
      data-testid={`season-form-${index}`}
    >
      <Center w="100%" pr={4} justifyContent={'space-between'}>
        <Box w="100%" data-testid={`season-name-select-${index}`}>
          <CustomSelect
            value={{ ...selectedSeason }}
            onChange={(option) => {
              if (option) setValue(`tariff.seasons.${index}.name`, option.value, { shouldDirty: true });
            }}
            options={seasonOptions}
            placeholder={`${t('Energy Rates.select season')}...`}
          />
        </Box>
        {seasons.length > 1 && (
          <IconButton
            aria-label="delete-season"
            variant={'ghost'}
            minH={'fit-content'}
            minW={'fit-content'}
            h={7}
            w={7}
            borderRadius={50}
            ml={4}
            onClick={() => {
              setState((state) => ({
                ...state,
                isDeleteModalOpen: true,
                seasonIndex: index + 1, // season Index starts from 1 in api
                seasonName: selectedSeason?.label || '',
              }));
            }}
            data-testid={`delete-season-${index}`}
          >
            <DeleteIcon color="red.500" />
          </IconButton>
        )}
      </Center>

      <AlertDialogModal
        isOpen={isDeleteModalOpen}
        onClose={() => setState((state) => ({ ...state, isDeleteModalOpen: false }))}
        header={`${t('Common.delete')} ${seasonName}?`}
        subHeader={t('Energy Rates.are you sure delete season')}
        confirmButtonName={t('Common.delete')}
        onConfirm={async () => {
          const { seasons, rates } = getValues().tariff;

          // delete the season
          const newSeasons = seasons
            .filter((season) => season.seasonIndex !== seasonIndex)
            .map((season, index) => ({ ...season, seasonIndex: index + 1 }));
          setValue('tariff.seasons', newSeasons, { shouldDirty: true });

          // Also delete associated rates
          const newRates = rates
            .filter((_, index) => index + 1 !== seasonIndex)
            .map((seasonRate, index) => {
              seasonRate.import = seasonRate.import.map((rate) => ({ ...rate, seasonIndex: index + 1 }));
              seasonRate.export = seasonRate.export.map((rate) => ({ ...rate, seasonIndex: index + 1 }));
              return seasonRate;
            });
          setValue('tariff.rates', newRates, { shouldDirty: true });
        }}
        confirmButtonTextColor="red.500"
      />

      <Divider borderColor={'#9797974D'} my={4} />
      <Center
        justifyContent={'space-between'}
        onClick={() => {
          if (isSeasonStartDatePickerOpen) {
            onCloseDatePicker();
          } else {
            onUpdateActiveDatePickerState({ seasonIndex: index, type: 'start' });
          }
        }}
        pr={4}
      >
        <Text>{t('Energy Rates.season start')}</Text>
        <Center
          w={93}
          color="#fff"
          bg={'#6F6F76'}
          _light={{ color: '#000', bg: '#ddd' }}
          rounded={4}
          h={34}
          data-testid={`season-${index}-selected-start-date`}
        >{`${fromMonth + 1}/${fromDate}`}</Center>
      </Center>
      <Divider borderColor={'#9797974D'} mt={4} mb={2} />

      {isSeasonStartDatePickerOpen && (
        <Box className="tariff-date-picker" data-testid={`season-${index}-start-date-picker`}>
          <CustomDatePicker
            inputProps={{
              border: 'none',
              fontSize: '15px',
              textAlign: 'center',
              paddingRight: 0,
              width: '100vw',
              placeholder: t('Common.select a date'),
            }}
            inputGroupProps={{
              justifyContent: 'center',
            }}
            // We need to exclude Feb 29 (leap year day) since API doesnt support it
            excludeDates={[new Date(new Date().getFullYear(), 1, 29)]}
            showMovePeriodButtons
            showYearPicker={false}
            inline
            selected={seasonStartDate}
            onChange={(date) => {
              if (date) {
                setValue(`tariff.seasons.${index}.fromDate`, date.getDate(), { shouldDirty: true });
                setValue(`tariff.seasons.${index}.fromMonth`, date.getMonth(), { shouldDirty: true });
                resetError();
                onCloseDatePicker();
              }
            }}
            months={MONTHS}
          />
          <Divider borderColor={'#9797974D'} mb={4} />
        </Box>
      )}

      {/* End */}
      <Center
        justifyContent={'space-between'}
        onClick={() => {
          if (isSeasonEndDatePickerOpen) {
            onCloseDatePicker();
          } else {
            onUpdateActiveDatePickerState({ seasonIndex: index, type: 'end' });
          }
        }}
        pr={4}
        mb={2}
      >
        <Text>{t('Energy Rates.season end')}</Text>
        <Center
          w={93}
          color="#fff"
          bg={'#6F6F76'}
          _light={{ color: '#000', bg: '#ddd' }}
          rounded={4}
          h={34}
          data-testid={`season-${index}-selected-end-date`}
        >{`${toMonth + 1}/${toDate}`}</Center>
      </Center>

      {isSeasonEndDatePickerOpen && (
        <Box className="tariff-date-picker" data-testid={`season-${index}-end-date-picker`}>
          <Divider borderColor={'#9797974D'} mb={2} />
          <Box style={{ paddingRight: '16px' }}>
            <CustomDatePicker
              inputProps={{
                border: 'none',
                fontSize: '15px',
                textAlign: 'center',
                paddingRight: 0,
                width: '100vw',
                placeholder: t('Common.select a date'),
              }}
              inputGroupProps={{
                justifyContent: 'center',
              }}
              // We need to exclude Feb 29 (leap year day) since API doesnt support it
              excludeDates={[new Date(new Date().getFullYear(), 1, 29)]}
              showMovePeriodButtons
              showYearPicker={false}
              inline
              selected={seasonEndDate}
              onChange={(date) => {
                if (date) {
                  setValue(`tariff.seasons.${index}.toDate`, date.getDate(), { shouldDirty: true });
                  setValue(`tariff.seasons.${index}.toMonth`, date.getMonth(), { shouldDirty: true });
                  resetError();
                  onCloseDatePicker();
                }
              }}
              months={MONTHS}
              locale={getLocaleForDateFns(i18n.language as SupportedLocales)}
            />
          </Box>
        </Box>
      )}
    </SEHomeCard>
  );
};

export default SeasonSelectForm;
