import React, { useEffect } from 'react';
import { Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { matchPath, Outlet, useNavigate } from 'react-router-dom';

import { useReduxDispatch } from '../../../app/store';
import { selectEvChargers } from '../../site/siteSlice';
import { selectEvChargerData, setEvChargerId } from './evChargerSlice';

/* istanbul ignore next -- @preserve */
export const EVChargerHome = () => {
  const evChargers = useSelector(selectEvChargers);
  const { hasVisitedRegisterScreen } = useSelector(selectEvChargerData);
  const hasEvChargers = !!evChargers?.length;
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasEvChargers) {
      const evChargerId = evChargers[0].site_identifier;
      if (evChargerId) dispatch(setEvChargerId(evChargerId));
    }
  }, []);

  useEffect(() => {
    const isMatch = matchPath('/sites/:id/ev_charger/home', location.pathname);
    if (isMatch) {
      const path = !hasEvChargers && !hasVisitedRegisterScreen ? 'device_register' : 'ev_charger_status';
      navigate(path, { replace: true });
    }
  }, [hasEvChargers, hasVisitedRegisterScreen]);

  return (
    <Center flexDirection={'column'} height="100%">
      <Outlet />
    </Center>
  );
};
