import { useEffect, useRef } from 'react';
import { StatusBar } from '@capacitor/status-bar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { instantiateConsentManagement } from '../../features/account/settings/consent-management';
import { selectUser } from '../../features/user/userSlice';
import setupPushNotifications from '../../utils/native/push-notifications';
import { IS_DEMO_LOGIN, IS_IOS, IS_NATIVE } from '../constants';

export function usePushNotificationSetup() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const isPushNotificationSetupComplete = useRef(false);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    async function setupPNs() {
      await setupPushNotifications(navigate);
      isPushNotificationSetupComplete.current = true;
    }

    /* istanbul ignore next -- @preserve */
    async function setupConsent() {
      await instantiateConsentManagement();
    }

    /* istanbul ignore if -- @preserve */
    if (IS_NATIVE) {
      if (IS_IOS) StatusBar.show();
      // The user needs to be logged in before setting up PNs.
      if (user.isLoggedIn && !IS_DEMO_LOGIN) {
        if (!isPushNotificationSetupComplete.current) setupPNs();
        if (user.email) setupConsent();
      }
    }
  }, [user]);
}
