import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Retailer } from 'clipsal-cortex-types/src/api/api-retailer';
import { RetailRate, SiteTariff, SiteTariffToSave, Utility } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';
import { getStringifiedQueryParams } from 'clipsal-cortex-utils/src/formatting/query-params';

import { baseApi } from '../../app/services/baseApi';
import { selectSite } from '../site/siteSlice';
import { useTariffFormContext } from './useTariffFormContext';

export type RetailerQueryParams = {
  limit: number;
  offset: number;
  country_code?: string;
  search?: string;
};

export type TariffQueryParams = {
  limit: number;
  offset: number;
};

export type RetailRateQueryParams = {
  utility_id: number;
  state: string;
  page: number;
};

export const tariffApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTariffs: build.query<PaginatedResponseV2<SiteTariff>, { siteId: number; params: Partial<TariffQueryParams> }>({
      query: ({ siteId, params }) => {
        const stringifiedParams = getStringifiedQueryParams<string>(params);
        return `/v2/sites/${siteId}/tariffs${stringifiedParams ? '?' : ''}${stringifiedParams}`;
      },
      providesTags: (_, __, { siteId, params }) => [{ type: 'Tariffs', siteId, ...params }],
    }),
    getUtilities: build.query<Utility[], string>({
      query: (zipCode) => `/v1/wattbuy/utilities?zip_code=${zipCode}`,
      providesTags: (_, __, zipCode) => [{ type: 'Utilities', zipCode }],
    }),
    convertUtilityIdToRetailer: build.mutation<Retailer, { utilityId: number; zipCode: string }>({
      query: ({ utilityId, zipCode }) => ({
        url: `/v1/wattbuy/utilities/${utilityId}/convert`,
        method: 'POST',
        body: { zip_code: zipCode },
      }),
    }),
    getRetailRates: build.query<PaginatedResponseV2<RetailRate>, RetailRateQueryParams>({
      query: (params) => {
        const stringifiedParams = getStringifiedQueryParams<string>(params);
        return `/v1/wattbuy/retail_rates?${stringifiedParams}`;
      },
      providesTags: (_, __, params) => [{ type: 'Utilities', ...params }],
    }),
    convertRetailRateToTariff: build.mutation<
      SiteTariff['tariff'],
      { utilityId: number; rateId: string; state: string }
    >({
      query: ({ utilityId, rateId, state }) => ({
        url: `/v1/wattbuy/retail_rates/${rateId}/convert`,
        method: 'POST',
        body: { utility_id: utilityId, state: state },
      }),
    }),
    createTariff: build.mutation<SiteTariff, { siteId: number; body: SiteTariffToSave }>({
      query: ({ siteId, body }) => ({
        url: `/v2/sites/${siteId}/tariffs`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tariffs'],
    }),
    updateTariff: build.mutation<SiteTariff, SiteTariffToSave>({
      query: (body) => ({
        url: `/v2/site_tariffs/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tariffs'],
    }),
    deleteTariff: build.mutation<SiteTariff, number>({
      query: (tariffId) => ({
        url: `/v2/site_tariffs/${tariffId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Tariffs'],
    }),
  }),
});

export const {
  useGetTariffsQuery,
  useGetUtilitiesQuery,
  useConvertUtilityIdToRetailerMutation,
  useGetRetailRatesQuery,
  useConvertRetailRateToTariffMutation,
  useCreateTariffMutation,
  useUpdateTariffMutation,
  useDeleteTariffMutation,
} = tariffApi;

export const useUtilities = () => {
  const { control } = useTariffFormContext();
  const { zipCode } = useWatch({ control });
  const { data, error, isLoading, isError, isFetching } = useGetUtilitiesQuery(zipCode || '0', {
    // if zipCode is not provided or is less than 5 characters, skip the query
    skip: !zipCode || zipCode?.length < 5,
  });
  const hasUtilities = !isError && !!data?.length;

  return {
    utilities: hasUtilities ? data : [],
    error,
    isLoading,
    isFetching,
  };
};

export const useTariffs = (params?: Partial<TariffQueryParams>) => {
  const site = useSelector(selectSite);
  const { data, error, isLoading } = useGetTariffsQuery({ params: params ?? {}, siteId: site.site_id });

  return {
    tariffs: data?.results ?? [],
    count: data?.count ?? 0,
    error,
    isLoading,
  };
};

export const useRetailRates = (page = 1) => {
  const { control } = useTariffFormContext();
  const { id, state } = useWatch({ name: 'tariff.utility', control });
  const { data, error, isLoading, isFetching } = useGetRetailRatesQuery(
    {
      utility_id: id ?? 0,
      state: state ?? '',
      page,
    },
    { skip: !id }
  );

  return {
    retailRates: data?.results ?? [],
    count: data?.count ?? 0,
    error,
    isLoading,
    isFetching,
  };
};
