import React from 'react';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SEHomeCard from '../../common/components/SEHomeCard';
import { selectSite } from '../site/siteSlice';
import NotificationListItem from './NotificationListItem';
import { NotificationGrouping, useGetNotifications } from './notificationsApi';
import NotificationsListSkeletonLoader from './NotificationsListSkeletonLoader';

type Props = {
  groupBy: NotificationGrouping;
  isArchived?: boolean;
};

export default function NotificationsListReadStatus({ groupBy, isArchived = false }: Props) {
  const textColor = useColorModeValue('#111111', '#EEEEEE');
  const {
    data: { read, unread },
    isLoading,
    isError,
  } = useGetNotifications(groupBy, { limit: 10, offset: 0, archived: isArchived });
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { t } = useTranslation();
  const unreadNotifications = unread?.data?.results;
  const totaNumUnreadNotifications = unread?.data?.count;
  const readNotifications = read?.data?.results;
  const totalNumReadNotifications = read?.data?.count;
  const readNotificationsList = isLoading ? (
    <NotificationsListSkeletonLoader />
  ) : (
    readNotifications?.map((notification, notificationIndex) => (
      <NotificationListItem notification={notification} key={notificationIndex} isArchived={isArchived} />
    ))
  );
  const unreadNotificationsList = isLoading ? (
    <NotificationsListSkeletonLoader />
  ) : (
    unreadNotifications?.map((notification, notificationIndex) => (
      <NotificationListItem notification={notification} key={notificationIndex} isArchived={isArchived} />
    ))
  );

  if (isError) {
    return (
      <Alert status="error" my={4}>
        <AlertIcon w={8} h={8} />
        <AlertDescription py={4}>
          {t('Notifications.error fetching notifications')}.{' '}
          {`${t('Common.please try again')} ${t('Common.if this persists contact support')}`}.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Box>
      <Text data-testid="list-type-title-unread" color={textColor}>
        {t('Notifications.unread').toUpperCase()}
      </Text>
      <Box data-testid="notifications-list-unread" mb={3}>
        {!unreadNotifications?.length && !isLoading ? (
          <Center data-testid="no-unread-notifications" my={4}>
            <Text>{t('Notifications.no type notifications', { type: 'unread' })}.</Text>
          </Center>
        ) : (
          unreadNotificationsList
        )}

        {!!totaNumUnreadNotifications && totaNumUnreadNotifications > 10 && (
          <SEHomeCard
            mt={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/sites/${site.site_id}/notifications/list_by_type/unread?archived=${isArchived}?direction=forward`
              )
            }
            borderTopLeftRadius="5px"
            mb={2}
            as={'button'}
            w="100%"
            data-testid="view-all-unread-notifications-btn"
          >
            <Flex justify="space-between" align="center">
              <Text>{t('Common.view all')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </SEHomeCard>
        )}
      </Box>
      <Text data-testid="list-type-title-read" color={textColor}>
        {t('Notifications.read').toUpperCase()}
      </Text>
      <Box data-testid="notifications-list-read" mb={3}>
        {!readNotifications?.length && !isLoading ? (
          <Center data-testid="notifications-list-unread" my={4}>
            <Text>{t('Notifications.no type notifications', { type: 'read' })}.</Text>
          </Center>
        ) : (
          readNotificationsList
        )}

        {!!totalNumReadNotifications && totalNumReadNotifications > 10 && (
          <SEHomeCard
            mt={2}
            cursor="pointer"
            onClick={() =>
              navigate(
                `/sites/${site.site_id}/notifications/list_by_type/read?archived=${isArchived}?direction=forward`
              )
            }
            borderTopLeftRadius="5px"
            mb={2}
            as={'button'}
            w="100%"
            data-testid="view-all-read-notifications-btn"
          >
            <Flex justify="space-between" align="center">
              <Text>{t('Common.view all')}</Text>
              <ChevronRightIcon color={'customGrey.400'} w={'26px'} h={'26px'} />
            </Flex>
          </SEHomeCard>
        )}
      </Box>
    </Box>
  );
}
