import { useMemo } from 'react';
import { orderBy, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useLiveData } from '../../../site/live-data/liveDataApi';
import {
  CHART_DEFAULT_OPTIONS,
  CHART_SPACER,
  MAX_NUMBER_OF_TOP_CONSUMER_DEVICES,
  OTHER_DEVICES_COLOR,
  TOP_CONSUMERS_COLORS,
} from '../constants';

export function useDevicesChartData() {
  const { t } = useTranslation();
  const { data, isMeterError, isLoading, isFetching, error } = useLiveData();

  const seriesData = useMemo(() => {
    const { appliances, switches, consumption } = data;
    const devices = [...appliances, ...switches];

    if (isLoading) return [];

    const topConsumers = orderBy(devices, (device) => device.power, 'desc')
      .slice(0, MAX_NUMBER_OF_TOP_CONSUMER_DEVICES)
      .filter((device) => device.power > 0)
      .map((device) => ({ name: 'display_name' in device ? device.display_name : device.name, ...device }));

    const otherConsumption = consumption - (sumBy(topConsumers, (device) => device.power) ?? 0);

    return [
      CHART_SPACER,
      {
        name: t('Home Screen.other devices'),
        y: Math.min((otherConsumption / consumption) * 100, 100),
        color: OTHER_DEVICES_COLOR,
      },
      ...topConsumers.flatMap((device, i) => [
        CHART_SPACER,
        {
          name: device.name,
          y: Math.min((Math.abs(device.power) / consumption) * 100, 100),
          color: TOP_CONSUMERS_COLORS[i],
        },
      ]),
    ];
  }, [isLoading, data]);

  const options = useMemo(
    () => ({
      ...CHART_DEFAULT_OPTIONS,
      chart: {
        ...CHART_DEFAULT_OPTIONS.chart,
        height: 260,
        width: 260,
      },
      plotOptions: {
        pie: {
          ...CHART_DEFAULT_OPTIONS.plotOptions.pie,
          innerSize: 240,
        },
      },
      series: [
        {
          data: seriesData,
        },
      ],
    }),
    [seriesData]
  );

  return {
    isLoading,
    isFetching,
    isError: isMeterError,
    error,
    data,
    options,
  };
}
