import React from 'react';
import { Box, Center, Radio, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArcButton from '../../../../../common/components/ArcButton';
import SEHomeCard from '../../../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { DataDownloadIcon } from '../../../../../styles/custom-icons';

export function DeleteAccountArchive() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const greenIconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');

  return (
    <SlidingAnimationPageBase title={t('Account.delete account')} backURL={`../start?direction=back`}>
      <Box m={4}>
        <Text fontSize={15}>
          {t('Account.deletion is permanent')} {t('Account.before you delete')}
        </Text>
        <SEHomeCard my={4} borderTopLeftRadius={'5px'} p={0} width="100%">
          <Center flexDirection="column" m={5}>
            <DataDownloadIcon color={greenIconColor} w="75px" h="75px" my={3} data-testid="download-data-icon" />
            <Text fontSize={22} fontWeight={400} textAlign="center" data-testid="download-data-title">
              {t('Common.download')}
            </Text>
            <Text textAlign="center" fontSize={15}>
              {t('Account.you can request an archive')} {t('Account.this will include pdf json')}{' '}
              {t('Account.youll get notification')}
            </Text>

            <ArcButton
              arcColor={'#3DCD57'}
              w={250}
              mt={4}
              onClick={() => {
                toast({
                  title: `${t('Common.coming soon')}...`,
                  duration: 2000,
                  isClosable: true,
                });
              }}
              data-testid="request-data-archive-button"
            >
              {t('Account.request data archive')}
            </ArcButton>
            <Radio size="md" my={4} data-testid="dont-need-historical-data-input">
              <Text fontSize={15}>{t('Account.i dont need my data')}</Text>
            </Radio>
          </Center>
        </SEHomeCard>
        <Center>
          <ArcButton
            arcColor={'#3DCD57'}
            w={250}
            mt={1}
            onClick={() => navigate('../verify-details')}
            data-testid="continue-to-delete-button"
          >
            {t('Account.continue to delete')}
          </ArcButton>
        </Center>
      </Box>
    </SlidingAnimationPageBase>
  );
}
