import { useSelector } from 'react-redux';

import { SiteUser } from 'clipsal-cortex-types/src/api/api-site-user';
import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';

import { baseApi } from '../../app/services/baseApi';
import { selectSite } from './siteSlice';

export const siteUsersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSiteUsers: builder.query<PaginatedResponseV2<SiteUser>, { siteId: number }>({
      query: ({ siteId }) => `/v1/sites/${siteId}/users`,
      providesTags: ['SiteUsers'],
    }),
  }),
});

export const { useGetSiteUsersQuery: useGetOriginalSiteUsersQuery } = siteUsersApi;

/**
 * Provides some sensible default values for the site users query.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetSiteUsersQuery() {
  const { site_id: siteId } = useSelector(selectSite);
  const result = useGetOriginalSiteUsersQuery({
    siteId,
  });
  return {
    ...result,
    data: result?.data?.results ?? [],
  };
}
