import React from 'react';
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { ReactComponent as EVLinkCrossDevice } from '../../../assets/images/evlink_device_cross.svg';
import ArcButton from '../../../common/components/ArcButton';
import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';

/* istanbul ignore next -- @preserve */
export const DeviceChargerUnavailable = () => {
  const navigate = useNavigate();

  return (
    <Flex
      bgColor={useColorModeValue('#F7F7F7', '#111111')}
      direction={'column'}
      align="center"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title="Device Setup" backURL="../device_scan" />
      <Text px={8} pt={TOP_NAV_SPACING_AFFORDANCE}>
        Although the app was unable to connect to your EV charger, you can still charge your EV.
      </Text>
      <Flex direction={'column'} align="center">
        <Box>
          <EVLinkCrossDevice style={{ width: 'fit-content', height: 'auto', maxHeight: '300px', marginLeft: '50px' }} />
        </Box>
        <Text px={8} mt={8} fontSize="24px" fontWeight={'bold'} data-testid="device-unavailable-title">
          Unavailable
        </Text>
        <Text px={8} mt={2} data-testid="device-unavailable-support">
          Please contact customer support to ensure it has been set up correctly.
        </Text>
      </Flex>
      <Box mb={8}>
        <ArcButton
          data-testid={'customer-support-btn'}
          arcColor="#FF5B44"
          width="100%"
          maxWidth={'300px'}
          onClick={() => navigate(`../../../account/support`)}
          mb={4}
        >
          Contact Support
        </ArcButton>
        <Button
          colorScheme={'schneiderSkyBlue'}
          fontWeight="normal"
          variant="link"
          width="100%"
          maxWidth={'300px'}
          onClick={() => navigate(`../../wifi/home`)}
        >
          Configure WiFi Manually
        </Button>
      </Box>
    </Flex>
  );
};
