import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Wifi } from '@capacitor-community/wifi/src';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
  useInterval,
  useToast,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Device } from 'clipsal-cortex-types/src/api/api-site';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { useReduxDispatch } from '../../../../../app/store';
import semCommissioningSuccessful from '../../../../../assets/images/sem_commissioning_successful.svg';
import semCommissioningUnsuccessful from '../../../../../assets/images/sem_commissioning_unsuccessful.svg';
import { post } from '../../../../../common/api/api-helpers';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import TopNavProgressLoader from '../../../../../common/components/TopNavProgressLoader';
import { LockIcon } from '../../../../../styles/custom-icons';
import { timeout } from '../../../../../utils/common/common-utils';
import { addSiteDevice, selectSite } from '../../../../site/siteSlice';
import MeterWiFiPasswordDialog from './MeterWiFiPasswordDialog';
import { WifiNetwork } from './types';
import { makeRequestWithAuth } from './utils';
import { ConnectionTestOngoing, ConnectionTestResults, DiscoveredNetwork } from './wireless-access-api-types';

type State = {
  isLoaded: boolean;
  wifiNetworks: WifiNetwork[];
  selectedWifiNetworkIndex: number | null;
  selectedWifiNetworkPassword: string;
  connectionTestProgress: number; // Value between 1 and 100
  connectionTestResults: 'SUCCESS' | 'FAILURE' | 'IN_PROGRESS' | 'NOT_STARTED' | 'READY_TO_REGISTER';
};

const INITIAL_STATE: State = {
  isLoaded: false,
  wifiNetworks: [],
  selectedWifiNetworkIndex: null,
  selectedWifiNetworkPassword: '',
  connectionTestProgress: 0,
  connectionTestResults: 'NOT_STARTED',
};

/* istanbul ignore next -- @preserve */
export function MeterConnectionTests() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const [state, setState] = useState(INITIAL_STATE);
  const {
    isLoaded,
    wifiNetworks,
    selectedWifiNetworkIndex,
    selectedWifiNetworkPassword,
    connectionTestProgress,
    connectionTestResults,
  } = state;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const dispatch = useReduxDispatch();
  const connectedSSID = search.get('connectedSSID');
  const accountId = search.get('accountId');
  const isRegisteredDevice = search.get('shouldRegisterDevice') === 'false';
  const toast = useToast({ isClosable: true });
  const isFirstRender = useRef(true);
  const { t } = useTranslation();
  const connectionTestResultsRef = useRef(connectionTestResults);

  const fetchLocalWifiNetworks = useCallback(async () => {
    try {
      const networks = await makeRequestWithAuth<DiscoveredNetwork[]>(accountId, 'GET', '/api/wifi_scan');

      setState((p) => ({
        ...p,
        isLoaded: true,
        wifiNetworks: networks.map<WifiNetwork>(({ n, s, e }) => ({
          signalStrength: s,
          ssid: n,
          security: e,
        })),
        selectedWifiNetworkIndex: null,
      }));
    } catch (e) {
      const error = e as Error;
      Sentry.captureException(e);
      console.error(e);
      if (error?.name === 'AccountLockedSEM') {
        toast({
          title: t('Set Up Hardware.sem incorrectly configured'),
          description: `${t('Common.please contact support')} ${t('Set Up Hardware.sem likely connected wrong fleet')}`,
          status: 'error',
        });
      } else {
        toast({
          title: t('Set Up Hardware.error fetching wifi'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      fetchLocalWifiNetworks();
      isFirstRender.current = false;
    }
  }, [fetchLocalWifiNetworks]);

  const runConnectionTests = useCallback(async () => {
    try {
      setSearch({ if: 'wifi' });

      // Next, we begin the device's connection tests (this actual trigger endpoint does not return any data, instead
      // it begins an asynchronous process on the device that will eventually return the connection test results).
      // We poll for this result with the `get` call below.
      await makeRequestWithAuth(
        accountId,
        'POST',
        '/api/start_conn_test',
        { if: 'wifi' },
        {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      );

      setState((p) => ({ ...p, connectionTestResults: 'IN_PROGRESS' }));
    } catch (e) {
      const error = e as Error;
      Sentry.captureException(e);
      console.error(e);
      if (error?.name === 'AccountLockedSEM') {
        toast({
          title: t('Set Up Hardware.sem incorrectly configured'),
          description: `${t('Common.please contact support')} ${t('Set Up Hardware.sem likely connected wrong fleet')}`,
          status: 'error',
        });
      } else {
        toast({
          title: t('Set Up Hardware.error running commissioning tests'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
      }
    }
  }, [search]);

  useEffect(() => {
    connectionTestResultsRef.current = connectionTestResults;
  }, [connectionTestResults]);

  useInterval(
    async () => {
      if (connectionTestResultsRef.current !== 'IN_PROGRESS') return;

      // Poll for the connection test result, and once complete, examine the result to ensure the device is
      // connected to the internet through the gateway.
      // After this is complete and successful, we can finally register the device in the Clipsal and Sense clouds.
      try {
        const connectionTestResult = await makeRequestWithAuth<ConnectionTestOngoing | ConnectionTestResults>(
          accountId,
          'GET',
          '/api/conn_test_result'
        );

        if ('r' in connectionTestResult) {
          const didTestsPass = connectionTestResult.r.every(([_, status]) => status !== 'f');

          if (didTestsPass) {
            setState({
              ...state,
              connectionTestResults: 'SUCCESS',
            });
          } else {
            setState({
              ...state,
              connectionTestResults: 'FAILURE',
            });
          }
        } else {
          const { c, tt } = connectionTestResult;

          // Update with the current test progress
          setState({
            ...state,
            connectionTestProgress: Math.round((c / tt) * 100),
          });
        }
      } catch (e) {
        const error = e as Error;
        Sentry.captureException(e);
        console.error(e);
        if (error?.name === 'AccountLockedSEM') {
          toast({
            title: t('Set Up Hardware.sem incorrectly configured'),
            description: `${t('Common.please contact support')} 
            ${t('Set Up Hardware.sem likely connected wrong fleet')}`,
            status: 'error',
          });
          setState({
            ...state,
            connectionTestResults: 'FAILURE',
          });
        } else {
          toast({
            title: t('Set Up Hardware.error running commissioning tests'),
            description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
            status: 'error',
          });
          setState({
            ...state,
            connectionTestResults: 'FAILURE',
          });
        }
      }
    },
    connectionTestResults === 'IN_PROGRESS' ? 2_000 : null
  );

  useEffect(() => {
    async function reconnectToHomeWifi() {
      try {
        // Here we reconnect the user back from the SEM WAP back to their home Wi-Fi
        await Wifi.connect({
          ssid: wifiNetworks[selectedWifiNetworkIndex!].ssid,
          password: selectedWifiNetworkPassword,
          isHiddenSsid: false,
        });
        // Here we wait a bit after connecting to the Wi-Fi before moving on to ensure we are connected -
        // - before immediately making any network requests
        await timeout(8_000);
        setState((p) => ({ ...p, connectionTestResults: 'READY_TO_REGISTER' }));
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        setState((p) => ({ ...p, connectionTestResults: 'FAILURE' }));
        toast({
          title: t('Set Up Hardware.error connecting to wifi'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
      }
    }

    async function registerDevice() {
      if (!connectedSSID) {
        throw new Error('SSID not provided!');
      }
      const serialNum = connectedSSID.split(' ')[2];

      try {
        const commissionedDevice = await post<Device>(`/v1/sites/${site.site_id}/register_sense_device`, {
          monitor_serial: serialNum,
        });

        // Add directly to the store to avoid having to re-fetch the site
        dispatch(addSiteDevice(commissionedDevice));

        navigate(`../finish?${search.toString() || ''}`);
      } catch (e) {
        Sentry.captureException(e);
        // @TODO: handle specific errors -- test in office
        console.error(e);
        setState((p) => ({ ...p, connectionTestResults: 'FAILURE' }));
        toast({
          title: t('Set Up Hardware.error registering sem'),
          description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
          status: 'error',
        });
      }
    }

    if (connectionTestResults === 'SUCCESS') reconnectToHomeWifi();

    if (connectionTestResults === 'READY_TO_REGISTER') {
      if (isRegisteredDevice) {
        navigate(`../finish?${search.toString() || ''}`);
      } else {
        registerDevice();
      }
    }
  }, [connectionTestResults]);

  let content;

  if (connectionTestResults === 'IN_PROGRESS') {
    content = (
      <>
        <TopNavProgressLoader />
        <Heading mb={6} size="md">
          {t('Set Up Hardware.checking network connection')}...
        </Heading>
        <CircularProgress value={connectionTestProgress} thickness="6px" size="200px" color={'primaryBranding.500'}>
          <CircularProgressLabel>{connectionTestProgress}%</CircularProgressLabel>
        </CircularProgress>
      </>
    );
  }

  if (connectionTestResults === 'SUCCESS') {
    content = (
      <>
        <TopNavProgressLoader />
        <Image mb={3} w={'70%'} src={semCommissioningSuccessful} alt="SEM configuration complete" />
        <Heading mt={3} size="md">
          {t('Set Up Hardware.sem connected to internet')}
        </Heading>
        <Text mt={2}>{t('Set Up Hardware.give us a minute')}</Text>
      </>
    );
  }

  // This ensures when coming from hardware view page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set('direction', 'back');
    return params.toString();
  }, []);

  if (connectionTestResults === 'FAILURE') {
    content = (
      <>
        <Image mb={3} w={'70%'} src={semCommissioningUnsuccessful} alt="SEM internet connection failed" />
        <Heading size="md">{t('Set Up Hardware.error connecting to internet')}</Heading>
        <Text my={2}>{t('Set Up Hardware.please ensure the network has internet')}</Text>
        <Text>{t('Common.if this persists contact support')}</Text>
        <ArcButton
          onClick={() => navigate(`../connect_instructions?${urlSearchParamsToGoBack}`)}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.try again')}
        </ArcButton>
      </>
    );
  }

  if (connectionTestResults === 'NOT_STARTED') {
    content = (
      <>
        <Box
          p={4}
          overflow={'scroll'}
          maxHeight="30vh"
          w={'100%'}
          border={'2px solid'}
          borderColor={'#9FA0A4'}
          borderRadius={'20px'}
        >
          {isLoaded ? (
            wifiNetworks.map(({ signalStrength, ssid }, index) => {
              return (
                <Flex
                  onClick={() => setState((prev) => ({ ...prev, selectedWifiNetworkIndex: index }))}
                  borderTop={index !== 0 ? '1px solid' : undefined}
                  borderBottom={index !== wifiNetworks.length - 1 ? '1px solid' : undefined}
                  borderColor="#9FA0A4"
                  key={`${ssid}-${index}`}
                  py={2}
                  align={'center'}
                >
                  {selectedWifiNetworkIndex === index && <CheckIcon mr={2} color="#3DCD58" w={5} h={5} />}
                  <Flex w={'100%'} justify="space-between" align="center">
                    <Text>{ssid}</Text>

                    <Center>
                      {/* @TODO: show icon according to strength */}
                      <Text>S: {signalStrength}</Text>
                      <LockIcon ml={2} w={4} h={4} />
                    </Center>
                  </Flex>
                </Flex>
              );
            })
          ) : (
            <CenteredLoader text={`${t('Common.loading')}...`} />
          )}
        </Box>

        <Heading mt={6}>{t('Set Up Hardware.select your wifi')}</Heading>
        <Text mt={1}>{t('Set Up Hardware.choose the wifi for sem')}</Text>

        <ArcButton onClick={onOpen} mt={8} w={'80%'} arcColor="#3DCD57">
          {t('Common.continue')}
        </ArcButton>
      </>
    );
  }

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../connect_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={4} mt={6} textAlign="center">
        {content}
      </Center>

      <MeterWiFiPasswordDialog
        network={wifiNetworks[selectedWifiNetworkIndex!]}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={({ password }) => {
          setState((p) => ({
            ...p,
            selectedWifiNetworkPassword: password,
          }));
          runConnectionTests();
        }}
      />
    </SlidingAnimationPageBase>
  );
}
