import React, { useMemo } from 'react';
import { ChevronRightIcon, InfoIcon } from '@chakra-ui/icons';
import { Center, Divider, Flex, Heading, Image, SimpleGrid, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { formatSecondsToHHMM } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import batteryAnimationDark from '../../assets/images/battery_animation_750.gif';
import DataSummaryCard from '../../common/components/DataSummaryCard';
import SEHomeCard from '../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { createBatteryReserveIcon } from '../../styles/create-battery-reserve-icon';
import { createCirclePercentageFilledIcon } from '../../styles/create-circle-percentage-filled-icon';
import { BATTERY_STATUS_UI_CONFIG, useLiveBatteryData } from './battery-helpers';

export function BatteryHome() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const { data: batteryLiveData, isLoading, error } = useLiveBatteryData();
  const isLoaded = !isLoading;

  const { color, Icon: icon } = BATTERY_STATUS_UI_CONFIG[batteryLiveData.status];
  let Icon = icon;
  if (!Icon && batteryLiveData.status === 'DISCHARGING')
    Icon = createBatteryReserveIcon((batteryLiveData?.battery_soc_fraction ?? 0) * 100);
  const CircleIcon = createCirclePercentageFilledIcon((batteryLiveData?.battery_soc_fraction ?? 1) * 100);

  function getBatteryDurationRemaining() {
    if (batteryLiveData.status === 'EMPTY') return '0';
    if (batteryLiveData && !error) return formatSecondsToHHMM(Math.abs(batteryLiveData?.battery_duration_sec ?? 0));
    return '0';
  }

  function getBatteryDurationTitle() {
    if (batteryLiveData.status === 'EMPTY') return t('Battery.battery charge');
    if (batteryLiveData.status === 'CHARGING') return t('Battery.time until charged');
    return t('Battery.time remaining');
  }

  const backURL = useMemo(() => search.get('backURL') ?? `../../home?direction=back`, [search]);

  // @TODO: update bg back to #111111 when the gif background is fixed
  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Battery.battery')} _dark={{ bg: '#131313' }}>
      <Center flexDirection="column" my={8} mx={3}>
        {error && 'status' in error && error.status === 422 ? (
          <>
            <InfoIcon mb={2} color={'red.500'} w={50} h={50} />
            <Heading textAlign={'center'} data-testid="unsupported-site-error">
              {t('Battery.this home does not have a battery')}
            </Heading>
          </>
        ) : (
          <>
            <SkeletonCircle w={[275, 400]} h={[275, 400]} isLoaded={isLoaded}>
              <Center position="relative" px={3} flexDirection="column" textAlign="center">
                {batteryLiveData.status === 'CHARGING' && (
                  <Image
                    data-testid="battery-charging-animation"
                    position="absolute"
                    w={[250, 375]}
                    h={[250, 375]}
                    src={batteryAnimationDark}
                    _light={{ display: 'none' }} // @TODO: Get animation for light mode
                  />
                )}

                {!!Icon && (
                  <Icon w={[140, 200]} h={[140, 200]} color={color} pos="absolute" data-testid="status-icon" />
                )}
                <CircleIcon h="100%" w="100%" color={color} data-testid="circle-icon" zIndex={3} />
              </Center>
            </SkeletonCircle>

            <Skeleton isLoaded={isLoaded} mt={2} minHeight={58} minW={250}>
              <Text textAlign={'center'} fontSize={28} fontWeight={500} data-testid="status-title">
                {t(`Battery.states.${batteryLiveData.status}.title`)}
              </Text>
              <Text textAlign={'center'} fontSize={16} fontWeight={400} data-testid="status-text" lineHeight={1}>
                {t(`Battery.states.${batteryLiveData.status}.text`)}
              </Text>
            </Skeleton>

            <SimpleGrid gap={3} row={2} w="100%" alignItems="center" my={3}>
              <SimpleGrid gap={3} columns={2}>
                <DataSummaryCard
                  title={getBatteryDurationTitle()}
                  isLoaded={isLoaded}
                  value={getBatteryDurationRemaining()}
                  suffix={batteryLiveData.status === 'EMPTY' ? '%' : ''}
                  data-testid="battery-telemetry-card"
                  h="100%"
                />
                <DataSummaryCard
                  title={t('Common.current power')}
                  isLoaded={isLoaded}
                  value={Intl.NumberFormat('en', { notation: 'compact' }).format(
                    batteryLiveData.status == 'ERROR' || batteryLiveData.status == 'EMPTY'
                      ? 0
                      : Math.abs(batteryLiveData?.battery ?? 0)
                  )}
                  suffix="kW"
                  data-testid="current-power-card"
                  h="100%"
                />
              </SimpleGrid>
              <Skeleton isLoaded={true} borderRadius={5} borderBottomRightRadius={'25px'} m={0}>
                <SEHomeCard borderTopLeftRadius={5} borderBottomRightRadius={'25px'} textAlign={'center'}>
                  <Flex
                    w="100%"
                    py={2}
                    as="button"
                    onClick={() => {
                      navigate(`../set-reserve-capacity`);
                    }}
                    data-testid="battery-reserve-button"
                  >
                    <Text>{t('Battery.set battery reserve')}</Text>
                    <ChevronRightIcon ml="auto" w={6} h={6} />
                  </Flex>
                  <Divider borderColor={'rgba(151, 151, 151, 0.3)'} my={4} />
                  <Flex
                    w="100%"
                    py={2}
                    as="button"
                    onClick={() => {
                      navigate(`../../activity?source=battery`);
                    }}
                    data-testid="battery-activity-button"
                  >
                    <Text>{t('Battery.battery activity')}</Text>
                    <ChevronRightIcon ml="auto" w={6} h={6} />
                  </Flex>
                </SEHomeCard>
              </Skeleton>
            </SimpleGrid>
          </>
        )}
      </Center>
    </SlidingAnimationPageBase>
  );
}
