import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IS_NATIVE } from '../../../common/constants';
import { SmartDimmerSwitchIcon, SmartLightSwitchIcon, SmartOutletIcon } from '../../../styles/custom-icons';
import { useGetDeviceQuery } from '../devicesApi';
import { useGetLiveSwitchQuery, useGetSwitchQuery } from '../switches/switchApi';
import { LocalMatterDevice, useGetLocalMatterDevicesQuery } from './localMatterDevicesApi';

// This is the predefined endpoint ID for the on/off cluster.
export const MATTER_ON_OFF_ENDPOINT_ID = 1;
export const MATTER_OPEN_COMMISSIONING_WINDOW_DURATION_SECONDS = 250;

export type MatterDeviceType = 'AYLA_SWITCH' | 'AYLA_OUTLET' | 'AYLA_DIMMER';

/**
 * Returns the device type for the given appliance `display_name`. Since we don't really have a way to differentiate --
 * -- between Matter device types, we must rely on this field from the /appliances API.
 *
 * @param displayName - The `display_name` of the appliance
 * @returns A device type
 */
export function getMatterDeviceType(displayName?: string): MatterDeviceType {
  if (displayName == 'SE Switch Matter') {
    return 'AYLA_SWITCH';
  }
  if (displayName == 'SE Dimmer Matter') {
    return 'AYLA_DIMMER';
  }
  return 'AYLA_OUTLET';
}

type MatterDeviceUIConfig = Record<
  MatterDeviceType,
  {
    description: string;
    icon: ComponentWithAs<'svg', IconProps>;
  }
>;

export function useTranslatedUIConfig(): MatterDeviceUIConfig {
  const { t } = useTranslation();
  return {
    AYLA_SWITCH: {
      description: t('Devices.ayla switch description'),
      icon: SmartLightSwitchIcon,
    },
    AYLA_OUTLET: {
      description: t('Devices.ayla outlet description'),
      icon: SmartOutletIcon,
    },
    AYLA_DIMMER: {
      description: t('Devices.ayla dimmer description'),
      icon: SmartDimmerSwitchIcon,
    },
  };
}

/**
 * Combines switch, appliance and live switch data for Ayla (Matter) device data to consolidate the --
 * various sources of data
 *
 * @param switchId - The switch ID to fetch data for
 * @param shouldIncludeLocalMatterDeviceData - Whether to include fetching local Matter device data
 * @returns Consolidated Ayla switch data with loading states for the various sources
 */
export function useAylaSwitchData(switchId: number, shouldIncludeLocalMatterDeviceData = false) {
  const {
    data: liveSwitch,
    isLoading: isLiveDataLoading,
    isError: isLiveSwitchError,
  } = useGetLiveSwitchQuery(switchId);
  const {
    data: apiSwitch,
    isLoading: isApiSwitchLoading,
    isFetching: isApiSwitchFetching,
    isError: isApiSwitchError,
  } = useGetSwitchQuery(switchId);
  const { data: device, isLoading: isDeviceLoading } = useGetDeviceQuery({ switchId });
  const { data: localMatterDevice, isLoading: isLocalDataLoading } = useGetLocalMatterDevicesQuery(undefined, {
    skip: !shouldIncludeLocalMatterDeviceData || !IS_NATIVE,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data: data?.find((s: LocalMatterDevice) => s?.serialNumber === liveSwitch?.oem_switch_id),
    }),
  });

  return {
    data: {
      ...apiSwitch,
      ...liveSwitch,
      ...device,
      localMatterDevice,
    },
    isLoading: isLiveDataLoading || isApiSwitchLoading || isDeviceLoading || isLocalDataLoading,
    isLiveDataLoading,
    isApiDataLoading: isApiSwitchLoading || isDeviceLoading,
    isLocalDataLoading,
    isApiSwitchFetching,
    isLiveSwitchError,
    isApiSwitchError,
  };
}
