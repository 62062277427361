import React, { useMemo } from 'react';
import { Skeleton } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import DeviceListItem from '../../devices/DeviceListItem';
import { getIconForDevice } from '../../devices/devices-helper';
import { useGetDevicesQuery } from '../../devices/devicesApi';
import { CombinedSwitch, useGetLiveSenseSwitchesQuery } from '../../devices/switches/switchApi';
import { useGetSwitchGroupForType } from '../../devices/switches/switchGroupApi';
import { useGetCostsQuery } from '../../site/costApi';
import { selectCurrentDayForSite } from '../../site/siteSlice';
import { useGetEnergyModeUIConfig } from './energy-modes-helpers';
import RemoveDesignatedDeviceButton from './RemoveDesignatedCircuitButton';

type SwitchWithCostAndUserAssignment = CombinedSwitch & { costToday: number; userAssignment: string | null };

export function DesignatedCircuitsList() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const energyMode = useGetEnergyModeUIConfig();
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: switches, isLoading: isSwitchesLoading } = useGetLiveSenseSwitchesQuery();
  // We need to grab appliances because switches don't include the user_assignment field (for the icon)
  const { data: appliances, isLoading: isDeviceLoading } = useGetDevicesQuery();
  const { data: costsData, isLoading: isCostsLoading } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const { data: switchGroup, isLoading: isSwitchGroupsLoading } = useGetSwitchGroupForType(energyMode.groupType!);

  const isApiDataLoaded = !isSwitchesLoading && !isCostsLoading && !isDeviceLoading && !isSwitchGroupsLoading;

  const switchGroupDeviceIds = useMemo(() => {
    return switchGroup?.control_devices.map((switchGroupDevice) => switchGroupDevice.control_device.id);
  }, [switchGroup]);

  const switchesWithCostToday = useMemo<SwitchWithCostAndUserAssignment[]>(() => {
    return switches
      .filter((senseSwitch) => switchGroupDeviceIds?.includes(senseSwitch.id))
      .map((senseSwitch) => {
        const costForSwitch =
          costsData.devices.find((appliance) => appliance.appliance_id === senseSwitch.appliance_id)?.cost || 0;
        const userAssignmentForSwitch =
          appliances.find((appliance) => appliance.appliance_id === senseSwitch.appliance_id)?.user_assignment || null;

        return {
          ...senseSwitch,
          costToday: costForSwitch,
          userAssignment: userAssignmentForSwitch,
        };
      });
  }, [costsData, switches, appliances, switchGroupDeviceIds]);

  const handleViewSwitch = (senseSwitch: SwitchWithCostAndUserAssignment) => {
    navigate(`../../../switches/${senseSwitch.id}/view?backURL=${pathname}`);
  };

  return (
    <Skeleton
      isLoaded={isApiDataLoaded}
      borderRadius={5}
      borderBottomRightRadius="25px"
      height={isApiDataLoaded ? 'unset' : 75}
      data-testid="designated-circuits-list"
    >
      {switchesWithCostToday.map((senseSwitch) => {
        const Icon = getIconForDevice(senseSwitch.userAssignment);
        return (
          <DeviceListItem
            name={senseSwitch.site_switch_label}
            Icon={Icon}
            currentPower={senseSwitch.power}
            costToday={senseSwitch.costToday}
            onClick={() => handleViewSwitch(senseSwitch)}
            key={senseSwitch.id}
            data-testid={`designated-circuit-list-item-${senseSwitch.id}`}
          >
            <RemoveDesignatedDeviceButton switchId={senseSwitch.id} />
          </DeviceListItem>
        );
      })}
    </Skeleton>
  );
}
