import React, { useEffect } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import { Center, useColorModeValue } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { useReduxDispatch } from '../../../app/store';
import { IS_NATIVE } from '../../../common/constants';
import { resetWifiConfig } from './wifiConfigSlice';

/* istanbul ignore next -- @preserve */
export const EvWifiConfiguration = () => {
  const dispatch = useReduxDispatch();
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  useEffect(() => {
    if (IS_NATIVE) {
      // @TODO - add ssl pinning
      HTTP.setServerTrustMode('nocheck');

      // use json serializer as the server returns json response
      HTTP.setDataSerializer('json');
    }

    return () => {
      dispatch(resetWifiConfig());
    };
  }, []);

  return (
    <Center bgColor={bgColor} flexDirection={'column'}>
      <Outlet />
    </Center>
  );
};
