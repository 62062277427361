import React, { ChangeEvent } from 'react';
import { Box, Center, Divider, Flex, Heading, Skeleton, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirst } from 'clipsal-cortex-utils/src/formatting/formatting';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import SEHomeCard from '../../../common/components/SEHomeCard';
import { MANUFACTURER_ID_AYLA } from '../../devices/devices-helper';
import { getMatterDeviceType, useTranslatedUIConfig } from '../../devices/matter/matter-helpers';
import { useGetSwitchesByManufacturerQuery } from '../../devices/switches/switchApi';
import { MATTER_DEVICE_ACTIVITY_UI_CONFIG, MAX_ACTIVITY_MATTER_DEVICES } from '../constants';
import { ActivityDevice } from '../types';

type Props = {
  isLoaded: boolean;
  onToggleDevice: (deviceId: number, isToggled: boolean) => void;
  selectedDateRangeType: DateRangeType;
  selectedDeviceIds: number[];
  displayedDevices: ActivityDevice[];
};

export default function FilterByMatterDevice({
  onToggleDevice,
  selectedDateRangeType,
  selectedDeviceIds,
  isLoaded,
  displayedDevices,
}: Props) {
  const subHeadingColor = useColorModeValue('customGrey.500', 'customGrey.400');
  const { t } = useTranslation();
  const MATTER_DEVICES_UI_CONFIG = useTranslatedUIConfig();
  // We need to grab the switches so we know the display name of the device to display
  const { data: aylaSwitchesFromApi, isLoading: isSwitchesLoading } =
    useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA);

  function getPeriodCopy() {
    const dateRangeToPeriod = {
      [DateRangeType.Day]: t('Activity.today'),
      [DateRangeType.Week]: t('Activity.this week'),
      [DateRangeType.Month]: t('Activity.this month'),
      [DateRangeType.Year]: t('Activity.this year'),
    };

    return dateRangeToPeriod[selectedDateRangeType];
  }

  const devicesInPeriod = displayedDevices.length ? (
    displayedDevices.map((device, i) => {
      const deviceType = getMatterDeviceType(device.display_name);
      const Icon = MATTER_DEVICES_UI_CONFIG[deviceType].icon;
      const selectedDeviceIndex = selectedDeviceIds.indexOf(device.appliance_id);
      const isToggled = selectedDeviceIndex !== -1;
      const deviceName =
        aylaSwitchesFromApi?.find((s) => s.id === device.appliance_id)?.site_switch_label || device.display_name;

      return (
        <SEHomeCard p={4} borderTopLeftRadius="5px" mb={2} key={i} data-testid={`displayed-device-${i}-container`}>
          <Flex align="center" flexDir="row">
            <Icon w={10} h={10} mr={4} _light={{ color: '#3C4446' }} />

            <Box>
              <Text>{deviceName}</Text>
              <Text color={subHeadingColor} fontSize={14} fontWeight={400} lineHeight={1.1} mr={1}>
                {capitalizeFirst(getPeriodCopy())}: {device.energySummary.toFixed(1)}
                {' kWh'}
              </Text>
            </Box>

            <Switch
              display="flex"
              isChecked={isToggled}
              isDisabled={!isToggled && selectedDeviceIds.length >= MAX_ACTIVITY_MATTER_DEVICES}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onToggleDevice(device.appliance_id, e.target.checked)}
              ml="auto"
              size="lg"
              variant={isToggled ? MATTER_DEVICE_ACTIVITY_UI_CONFIG[selectedDeviceIndex].switchVariant : undefined}
              data-testid={`displayed-device-${i}`}
            />
          </Flex>
        </SEHomeCard>
      );
    })
  ) : (
    <Center>
      <Heading size="md">{t('Activity.no power consumers')}</Heading>
    </Center>
  );

  return (
    <Box mx={3} data-testid="activity-device-filter-panel">
      <Divider mb={2} />
      <Box as="span" flex="1" textAlign="left">
        <Text>
          {t('Activity.top power consumers')} {getPeriodCopy()}
        </Text>
      </Box>

      <Text fontSize={13} color={subHeadingColor} mb={1}>
        {t('Activity.select up to 5 devices')}
      </Text>
      {isLoaded && !isSwitchesLoading ? devicesInPeriod : <SkeletonLoader />}
    </Box>
  );
}

const EMPTY_ARRAY = Array.from(Array(6));

function SkeletonLoader() {
  return (
    <Box>
      {EMPTY_ARRAY.map((_, i) => (
        <Skeleton key={i} w={'100%'} h={'75px'} isLoaded={false} />
      ))}
    </Box>
  );
}
