import { areIntervalsOverlapping, differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type Season = {
  seasonIndex: number;
  name: string;
  fromMonth: number;
  toMonth: number;
  fromDate: number;
  toDate: number;
};

export type TariffSeasonData = { seasons: Season[] };

/**
 * Breaks down season to range such that it can be compared to other ranges.
 *
 * @param seasons season to get range from
 * @returns array of ranges for the season
 */
const getSeasonRanges = (seasons: Season[]) => {
  type DateMonthTuple = [number, number];
  type RangeTuple = [DateMonthTuple, DateMonthTuple];
  const ranges: RangeTuple[] = [];

  // get ranges for all seasons
  seasons.forEach(({ fromDate, toDate, fromMonth, toMonth }) => {
    // if season spans across two years, split it into two ranges
    if (fromMonth > toMonth || (toMonth === fromMonth && fromDate > toDate)) {
      ranges.push(
        [
          [fromMonth, fromDate],
          [11, 31],
        ],
        [
          [0, 1],
          [toMonth, toDate],
        ]
      );
    } else {
      ranges.push([
        [fromMonth, fromDate],
        [toMonth, toDate],
      ]);
    }
  });

  // sort ranges in ascending order
  return ranges.sort(([[fromMonthA, fromDateA]], [[fromMonthB, fromDateB]]) => {
    if (fromMonthA > fromMonthB) return 1;
    if (fromMonthA < fromMonthB) return -1;
    if (fromDateA > fromDateB) return 1;
    if (fromDateA < fromDateB) return -1;
    return 0;
  });
};

/**
 * Validates whether seasons overlap each other. If any season overlaps another, it's invalid.
 *
 * @param seasons The array of seasons to validate
 * @returns {boolean}
 */
export function checkIfSeasonOverlap(seasons: Season[]) {
  const ranges = getSeasonRanges(seasons);

  // compare each range to all other ranges
  const hasOverlappingRanges = ranges.some((rangeA, indexA) => {
    const [[startMonthA, startDateA], [endMonthA, endDateA]] = rangeA;

    const today = new Date();
    const startFullDateA = new Date(today.getFullYear(), startMonthA, startDateA);
    const endFullDateA = new Date(today.getFullYear(), endMonthA, endDateA);

    // compare this range to all exisitng ranges
    return ranges.some((rangeB, indexB) => {
      // do not compare to self
      if (indexA === indexB) return false;

      const [[startMonthB, startDateB], [endMonthB, endDateB]] = rangeB;
      const startFullDateB = new Date(today.getFullYear(), startMonthB, startDateB);
      const endFullDateB = new Date(today.getFullYear(), endMonthB, endDateB);

      // checks if two ranges overlap
      return areIntervalsOverlapping(
        { start: startFullDateA, end: endFullDateA },
        { start: startFullDateB, end: endFullDateB },
        { inclusive: true }
      );
    });
  });

  return hasOverlappingRanges;
}

/**
 * Validates whether seasons cover whole year. If all seasons cover the whole year, it's valid.
 *
 * @param seasons The array of seasons to validate
 * @returns {boolean}
 */
export function checkIfSeasonsCoverFullYear(seasons: Season[]) {
  const ranges = getSeasonRanges(seasons);

  let totalDays = 0;

  // compare each range to all other ranges
  ranges.forEach((rangeA) => {
    const [[startMonthA, startDateA], [endMonthA, endDateA]] = rangeA;

    // using 2022 to avoid leap years and feb 29. Year is not important here
    const startFullDateA = new Date(2022, startMonthA, startDateA);
    const endFullDateA = new Date(2022, endMonthA, endDateA);
    const totalNumberOfDays = differenceInDays(endFullDateA, startFullDateA);
    totalDays += totalNumberOfDays + 1; // adding 1 to include end date
  });

  return totalDays === 365;
}

export const useTranslatedEmptySeasonsTemplate = (): Season[] => {
  const { t } = useTranslation();
  return [
    {
      seasonIndex: 1,
      name: t('Energy Rates.summer'),
      fromMonth: 4,
      toMonth: 9,
      fromDate: 1,
      toDate: 31,
    },
    {
      seasonIndex: 2,
      name: t('Energy Rates.winter'),
      fromMonth: 10,
      toMonth: 3,
      fromDate: 1,
      toDate: 30,
    },
  ];
};

export const useTranslatedSeasonOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t('Energy Rates.summer'), value: 'Summer' },
    { label: t('Energy Rates.winter'), value: 'Winter' },
    { label: t('Energy Rates.custom'), value: 'Custom' },
  ];
};

export const useTranslatedSeasonSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    seasonIndex: yup.number().required().label(t('Energy Rates.season index')),
    name: yup.string().max(100).required().label(t('Energy Rates.season name')),
    fromMonth: yup.number().required().label(t('Energy Rates.from month')),
    fromDate: yup.number().required().label(t('Energy Rates.from date')),
    toMonth: yup.number().required().label(t('Energy Rates.to month')),
    toDate: yup.number().required().label(t('Energy Rates.to date')),
  });
};

export const useTranslatedTariffSeasonSchema = () => {
  const { t } = useTranslation();
  const seasonSchema = useTranslatedSeasonSchema();
  return yup
    .array()
    .of(seasonSchema)
    .default([
      {
        seasonIndex: 1,
        name: 'Summer',
        fromMonth: 4,
        toMonth: 9,
        fromDate: 1,
        toDate: 31,
      },
      {
        seasonIndex: 2,
        name: 'Winter',
        fromMonth: 10,
        toMonth: 3,
        fromDate: 1,
        toDate: 30,
      },
    ])
    .min(1, t('Energy Rates.tariff must have a season'))
    .test(
      'does-not-overlap',
      t('Energy Rates.seasons cannot overlap'),
      (seasons: Season[]) => !checkIfSeasonOverlap(seasons)
    )
    .test('covers-whole-year', t('Energy Rates.seasons should cover year'), (seasons: Season[]) =>
      checkIfSeasonsCoverFullYear(seasons)
    );
};
