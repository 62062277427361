import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AlertDialogModal from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { useReduxDispatch } from '../../app/store';
import SEHomeCard from '../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../common/constants';
import { SEHomeNavHomeIcon } from '../../styles/custom-icons';
import { debounceEvent } from '../../utils/common/component-helpers';
import AccountMenuButton from '../account/AccountMenuButton';
import { resetEvChargerData } from '../ev_charger/dashboard/evChargerSlice';
import { clearCurrentSite } from '../site/siteSlice';
import { getSites, selectSites, setNotLoaded, setSearchTerm } from '../sites/sitesSlice';

export function ListSites() {
  const dispatch = useReduxDispatch();
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const { sites, isLoaded, pageSize, totalSites, searchTerm } = useSelector(selectSites);
  const totalPageCount = Math.ceil(totalSites / pageSize);
  const navigate = useNavigate();
  const backgroundColor = useColorModeValue('customGrey.100', 'customGrey.900');
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const { isOpen: isLogoutOpen, onOpen: onLogoutOpen, onClose: onLogoutClose } = useDisclosure();

  // Clear Redux store on first load.
  useEffect(() => {
    dispatch(clearCurrentSite());
    dispatch(resetEvChargerData());
    dispatch(setSearchTerm(null));
  }, [dispatch]);

  const handleFetchData = useCallback(
    async (pageIndex: number, pageSize: number) => {
      dispatch(setNotLoaded());
      const offset = pageIndex * pageSize;
      await dispatch(getSites({ pageSize, offset }));
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    debounceEvent(async () => {
      await dispatch(getSites({ pageSize, offset: 0 }));
    }),
    [sites]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(e.currentTarget.value));
    handleSearch();
  };

  // Listener for page index changes
  useEffect(() => {
    handleFetchData(currentPageIndex, 10);
  }, [handleFetchData, currentPageIndex]);

  const backURL = useMemo(() => {
    // A backURL is provided when viewing the site list from within a site
    return search.get('backURL') ?? undefined;
  }, [search]);

  const onClickBack = useMemo(() => {
    if (backURL) return undefined;
    // If no backURL is provided, open the logout modal instead (ie. we are in the root site list)
    return onLogoutOpen;
  }, [backURL]);

  const urlSearchParamsToGoForward = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set('direction', 'forward');
    return params.toString();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Manage Homes.manage homes')}
      includeTopNav
      px={4}
      backURL={backURL}
      onClickBack={onClickBack}
      customTopNavButton={
        <Button
          data-testid="create-new-home"
          onClick={() => navigate(`../create?${urlSearchParamsToGoForward}`)}
          variant={'ghost'}
          colorScheme={'schneiderSkyBlue'}
          mr={2}
          size={'sm'}
          isDisabled={IS_DEMO_LOGIN}
          fontWeight={500}
        >
          {t('Common.create')}
        </Button>
      }
      mb={16}
    >
      <Center mb={3}>
        <Input
          data-testid="homes-list-search"
          borderRadius={0}
          type="text"
          data-private
          background={backgroundColor}
          value={searchTerm ?? ''}
          name={t('Common.search')}
          placeholder={t('Manage Homes.search your homes')}
          onChange={handleInputChange}
        />
      </Center>

      <Text>{t('Manage Homes.homes').toUpperCase()}</Text>
      {isLoaded ? (
        !!sites.length ? (
          <SEHomeCard data-testid="homes-list" mb={5} borderTopLeftRadius={'5px'} p={0}>
            {sites.map((site, index) => {
              return (
                <AccountMenuButton
                  className="manage-homes-site"
                  key={site.site_id}
                  includeBottomDivider={index !== sites.length - 1}
                  onClick={() => {
                    dispatch(clearCurrentSite());
                    navigate(`/sites/${site.site_id}/home`, { replace: true });
                  }}
                  maxHeight="100px"
                >
                  <Flex align="center" width="90%">
                    <SEHomeNavHomeIcon mr={2} w={6} h={6} />
                    <VStack align={'flex-start'} rowGap={0} overflowX="auto">
                      <Box fontWeight={500} fontSize={18}>
                        {site.site_name}
                      </Box>
                      <Box fontWeight={400} fontSize={12} textTransform={'uppercase'}>
                        {t('Common.site id')}: {site.site_id}
                      </Box>
                    </VStack>
                  </Flex>
                </AccountMenuButton>
              );
            })}
          </SEHomeCard>
        ) : (
          <Box data-testid="empty-homes-message">{t('Manage Homes.no homes found matching')}</Box>
        )
      ) : (
        <CenteredLoader text={`${t('Common.loading')}...`} />
      )}

      {totalPageCount > 1 && (
        <Flex data-testid="homes-list-pagination" mt={3} align="center" justify={'center'}>
          <IconButton
            mx={2}
            size="sm"
            data-testid="go-to-previous-page"
            aria-label="Go to previous page"
            icon={<ChevronLeftIcon />}
            onClick={() => {
              setCurrentPageIndex(currentPageIndex - 1);
            }}
            isDisabled={currentPageIndex === 0 || !isLoaded}
          />

          <Box>
            {t('Common.page')} {currentPageIndex + 1}
          </Box>

          <IconButton
            mx={2}
            size="sm"
            data-testid="go-to-next-page"
            aria-label="Go to next page"
            icon={<ChevronRightIcon />}
            onClick={() => {
              setCurrentPageIndex(currentPageIndex + 1);
            }}
            isDisabled={currentPageIndex === totalPageCount - 1 || !isLoaded}
          />
        </Flex>
      )}
      <AlertDialogModal
        isOpen={isLogoutOpen}
        onClose={onLogoutClose}
        header={t('Account.logout')}
        subHeader={t('Account.are you sure logout')}
        confirmButtonName={t('Account.logout')}
        onConfirm={() => navigate('/logout')}
        cancelButtonName={t('Common.cancel')}
      />
    </SlidingAnimationPageBase>
  );
}
