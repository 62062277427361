import React, { useState } from 'react';
import { useEventListener } from '@chakra-ui/react';

import { AngleDescription, angleToPosition, angleToValue, positionToAngle, valueToAngle } from './circularGeometry';
import { arcPathWithRoundedEnds } from './svgPaths';

export type CircularSliderProps = {
  value: number;
  onChange: (value: number) => void;
  size?: number;
  trackWidth?: number;
  minValue?: number;
  maxValue?: number;
  startAngle?: number; // 0 - 360 degrees
  endAngle?: number; // 0 - 360 degrees
  angleType?: AngleDescription;
  knobSize?: number;
  isDisabled?: boolean;
  trackColor?: string;
  progressColor?: string;
  knobColor?: string;
  arcBackgroundColor?: string;
  preventIndefiniteSliding?: boolean;
  trackStyle?: React.CSSProperties;
  progressStyle?: React.CSSProperties;
  knobStyle?: React.CSSProperties;
  knobProps?: React.SVGProps<SVGCircleElement>;
  isArcRounded?: boolean;
};

const DRAG_THRESHOLD_RATIO = 0.2;

const CircularSlider = ({
  size = 320,
  trackWidth = 16,
  minValue = 0,
  maxValue = 100,
  knobSize = 30,
  startAngle = 0,
  endAngle = 360,
  angleType = {
    direction: 'cw',
    axis: '+y',
  },
  trackColor = '#aaa',
  progressColor = '#42B4E6',
  knobColor = '#ffffff',
  value,
  onChange,
  isDisabled,
  preventIndefiniteSliding,
  trackStyle,
  progressStyle,
  knobStyle,
  knobProps,
  isArcRounded = true,
}: CircularSliderProps) => {
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const isDragging = React.useRef(false);
  const [knobCursor, setKnobCursor] = useState('grab');

  const onMouseDown = (event: React.MouseEvent<SVGSVGElement>) => {
    if (isDisabled) return;
    event.preventDefault(); // disables text selection when dragging the knob
    isDragging.current = true;
    setKnobCursor('grabbing');
    document.body.style.cursor = 'grabbing';
    if (svgRef.current) {
      svgRef.current.addEventListener('mousemove', handleMousePosition);
      svgRef.current.addEventListener('mouseleave', removeMouseListeners);
      svgRef.current.addEventListener('mouseup', removeMouseListeners);
    }
    handleMousePosition(event);
  };

  const onMouseUp = () => {
    setKnobCursor('grab');
    if (!isDragging.current) return;
    isDragging.current = false;
    document.body.style.cursor = 'inherit';
  };

  const removeMouseListeners = () => {
    if (svgRef.current) {
      svgRef.current.removeEventListener('mousemove', handleMousePosition);
      svgRef.current.removeEventListener('mouseleave', removeMouseListeners);
      svgRef.current.removeEventListener('mouseup', removeMouseListeners);
    }
  };

  const handleMousePosition = (event: React.MouseEvent<SVGSVGElement> | MouseEvent) => {
    const x = event.clientX;
    const y = event.clientY;
    processSelection(x, y);
  };

  const onTouch = (event: React.TouchEvent<SVGSVGElement>) => {
    /* This is a very simplistic touch handler. Some optimzations might be:
      - Right now, the bounding box for a touch is the entire element. Having the bounding box
        for touched be circular at a fixed distance around the slider would be more intuitive.
      - Similarly, don't set `touchAction: 'none'` in CSS. Instead, call `ev.preventDefault()`
        only when the touch is within X distance from the slider
  */

    // This simple touch handler can't handle multiple touches. Therefore, bail if there are either:
    // - more than 1 touches currently active
    // - a touchEnd event, but there is still another touch active
    if (isDisabled || event.touches.length > 1 || (event.type === 'touchend' && event.touches.length > 0)) {
      return;
    }

    // Process the new position
    const touch = event.changedTouches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    processSelection(x, y);
  };

  const processSelection = (x: number, y: number) => {
    if (!svgRef.current) return;

    // Find the coordinates with respect to the SVG
    const svgPoint = svgRef.current.createSVGPoint();
    svgPoint.x = x;
    svgPoint.y = y;
    const coordsInSvg = svgPoint.matrixTransform(svgRef.current.getScreenCTM()?.inverse());

    const angle = positionToAngle(coordsInSvg, size, angleType);
    const newValue = Math.round(
      angleToValue({
        angle,
        minValue,
        maxValue,
        startAngle,
        endAngle,
      })
    );

    // Don't update the value if the slider is disabled
    if (isDisabled) return;

    // This is a threshold to avoid the knob from sliding indefinitely when the user is dragging it
    const dragThreshold = DRAG_THRESHOLD_RATIO * maxValue;

    // the handle is probably crossing over from 0% -> 100%, don't move it
    const isMovingTowardsMaximum = newValue > value + dragThreshold;

    // the handle is probably crossing over from 100% -> 0%, don't move it
    const isMovingTowardsMinimum = newValue < value - dragThreshold;

    if (preventIndefiniteSliding && isMovingTowardsMaximum) {
      onChange(minValue);
    } else if (preventIndefiniteSliding && isMovingTowardsMinimum) {
      onChange(maxValue);
    } else {
      onChange(newValue);
    }
  };

  // Adding Global Event Listeners to ensure that the knob can be dragged even if the mouse leaves the SVG element
  useEventListener('mousemove', (event) => {
    if (!isDragging.current) return;
    handleMousePosition(event);
  });
  useEventListener('mouseup', onMouseUp);

  const shadowWidth = 20;
  const trackInnerRadius = size / 2 - trackWidth - shadowWidth;
  const valueAngle = valueToAngle({
    value,
    minValue,
    maxValue,
    startAngle,
    endAngle,
  });

  const knobPosition = angleToPosition({ degree: valueAngle, ...angleType }, trackInnerRadius + trackWidth / 2, size);

  return (
    <svg
      width={size}
      height={size}
      ref={svgRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={
        /* TODO: be smarter about this -- for example, we could run this through our
        calculation and determine how close we are to the arc, and use that to decide
        if we propagate the click. */
        (event) => event.stopPropagation()
      }
      onTouchStart={onTouch}
      onTouchEnd={onTouch}
      onTouchMove={onTouch}
      onTouchCancel={onTouch}
      style={{ touchAction: 'none' }}
    >
      {/* Arc Background  */}
      <path
        d={arcPathWithRoundedEnds({
          startAngle: valueAngle,
          endAngle,
          angleType,
          innerRadius: trackInnerRadius,
          thickness: trackWidth,
          svgSize: size,
          direction: angleType.direction,
        })}
        fill={trackColor}
        style={trackStyle}
      />
      {/* Arc (render after background so it overlays it) */}
      <path
        d={arcPathWithRoundedEnds({
          startAngle,
          endAngle: valueAngle,
          angleType,
          innerRadius: trackInnerRadius,
          thickness: trackWidth,
          svgSize: size,
          direction: angleType.direction,
          isArcRounded,
        })}
        fill={progressColor}
        style={progressStyle}
      />

      <filter id="handleShadow" x="-50%" y="-50%" width="16" height="16">
        <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
        <feColorMatrix
          result="matrixOut"
          in="offOut"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="5" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <circle
        style={{ cursor: isDisabled ? 'not-allowed' : knobCursor, ...knobStyle }}
        r={knobSize / 2}
        cx={knobPosition.x}
        cy={knobPosition.y}
        fill={knobColor}
        {...knobProps}
      />
    </svg>
  );
};

export default CircularSlider;
