import React, { useMemo } from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import semQrCodeImg from '../../../../../assets/images/sem_qr_code_scan.svg';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';

export function MeterConnectInstructions() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();

  // This ensures when coming from hardware view page, we can go back to the same page when going back
  const urlSearchParamsToGoBack = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set('direction', 'back');
    return params.toString();
  }, []);

  return (
    <SlidingAnimationPageBase
      title={t('Common.device setup')}
      backURL={`../power_up_instructions?${urlSearchParamsToGoBack}`}
    >
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        <Image w={'70%'} my={4} src={semQrCodeImg} alt="Schneider Energy Monitor sketch" />

        <Heading mt={2}>{t('Set Up Hardware.activate sem title')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.on your device there is a qr', {
            device: t('Set Up Hardware.energy monitor'),
          })}{' '}
          {t('Set Up Hardware.use your camera to scan', {
            device: t('Set Up Hardware.energy monitor'),
          })}
        </Text>
        <Text mt={1}>{t('Set Up Hardware.alternatively you can manually enter serial')}</Text>

        <ArcButton
          data-testid={'meter-scan-qr-code'}
          onClick={() => {
            navigate(`../scan?${search.toString() || ''}`);
          }}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.scan qr code')}
        </ArcButton>

        <ArcButton
          data-testid={'meter-enter-serial-number'}
          onClick={() => {
            navigate(`../manual_serial_entry?${search.toString() || ''}`);
          }}
          mt={3}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Set Up Hardware.enter serial number')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
