import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ChargerStatus,
  EvChargerData,
  EvChargerDetails,
  EvChargerStatusData,
  GenericStatusResponse,
} from 'clipsal-cortex-types/src/api/api-evcharger';

import { RootState } from '../../../app/store';
import { del, get } from '../../../common/api/api-helpers';
import { removeEvCharger } from '../../site/siteSlice';
import availableStatus from './simulated-statuses/charger_status_available.json';
import chargingStatus from './simulated-statuses/charger_status_charging.json';
import finishingStatus from './simulated-statuses/charger_status_completed.json';
import faultedStatus from './simulated-statuses/charger_status_faulted.json';
import preparingStatus from './simulated-statuses/charger_status_preparing.json';
import suspendedEVStatus from './simulated-statuses/charger_status_suspended_ev.json';
import unavailableStatus from './simulated-statuses/charger_status_unavailable.json';
import wifiFaultedStatus from './simulated-statuses/charger_status_wifi_faulted.json';

export type EvChargerState = {
  isLoaded: boolean;
  isDeactivating: boolean;
  hasVisitedRegisterScreen: boolean;
  hasFetchedEvChargerDetails: boolean;
  chargerId: string;
  chargeSimulationType: ChargerStatus | 'None';
} & EvChargerStatusData &
  Pick<EvChargerDetails, 'description' | 'firmware' | 'model' | 'output' | 'sn'>;

export type EvChargerReducers = {
  addEnrolledEvChargerDetails: (state: EvChargerState, action: PayloadAction<Partial<EvChargerData>>) => void;
  setHasVisitedRegisterScreen: (state: EvChargerState, action: PayloadAction<boolean>) => void;
};

export const fetchEvChargerDetails = createAsyncThunk<EvChargerDetails, string, { state: RootState }>(
  'evCharger/fetchEvChargerDetails',
  async (evChargerId, { getState }) => {
    const state = getState();
    const siteId = state.site.site_id;
    return await get<EvChargerDetails>(`/v1/sites/${siteId}/ev_chargers/${evChargerId}`);
  }
);

export const fetchEvChargerStatus = createAsyncThunk<EvChargerStatusData, string, { state: RootState }>(
  'evCharger/fetchEvChargerStatus',
  async (evChargerId, { getState }) => {
    const state = getState();
    const siteId = state.site.site_id;

    if (state.evCharger.chargeSimulationType !== 'None') {
      const statusToSimulatedResponse: Record<string, any> = {
        Available: availableStatus,
        Charging: chargingStatus,
        Finishing: finishingStatus,
        Faulted: faultedStatus,
        Preparing: preparingStatus,
        SuspendedEVSE: suspendedEVStatus,
        Unavailable_Disconnect: unavailableStatus,
        Wifi_Faulted: wifiFaultedStatus,
      };

      return statusToSimulatedResponse[state.evCharger.chargeSimulationType];
    }

    return await get<EvChargerStatusData>(`/v1/sites/${siteId}/ev_chargers/${evChargerId}/status`);
  }
);

export const deactivateEvCharger = createAsyncThunk<GenericStatusResponse, string, { state: RootState }>(
  'evCharger/deactivateEvCharger',
  async (evChargerId, { getState, dispatch }) => {
    const state = getState();
    const siteId = state.site.site_id;
    const response = await del<GenericStatusResponse>(`/v1/sites/${siteId}/ev_chargers/${evChargerId}`, {});
    dispatch(removeEvCharger(evChargerId));
    return response;
  }
);

const initialState: EvChargerState = {
  isLoaded: false,
  hasVisitedRegisterScreen: false,
  hasFetchedEvChargerDetails: false,
  isDeactivating: false,
  chargerId: '',
  error: 'NoError',
  error_message: '',
  error_title: '',
  status: 'Unavailable_Disconnect',
  latest_charge_session_start: '',
  latest_charge_session_end: '',
  energy_consumed: 0,
  power: 0,
  timestamp: '',
  description: '',
  firmware: '',
  model: '',
  output: '',
  sn: '',
  chargeSimulationType: 'None',
  clipsal_endpoints: [
    'retrieve',
    'start_charge',
    'pause_charge',
    'resume_charge',
    'stop_charge',
    'get_status',
    'get_charge_current_request',
    'set_charge_current',
    'clear_charge_current',
    'history',
  ],
  charge_current: null,
  charge_current_request: null,
  charge_current_request_max: null,
  battery_level: null,
  is_scheduled_charge_active: null,
  scheduled_charge_start_time: null,
  scheduled_charge_end_time: null,
  estimated_full_charge_time: null,
  timeout_seconds: null,
  control_mode: 'Automatic',
};

export const evChargerSlice = createSlice({
  name: 'evCharger',
  initialState,
  reducers: {
    resetEvChargerData: () => {
      return { ...initialState };
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoaded: action.payload };
    },
    setEvChargerId: (state, action: PayloadAction<string>) => {
      return { ...state, chargerId: action.payload };
    },
    addEnrolledEvChargerDetails: (state, action: PayloadAction<Partial<EvChargerData>>) => {
      return {
        ...state,
        ...action.payload,
        model: action.payload.model ?? state.model,
        sn: action.payload.sn ?? state.sn,
        isLoaded: false,
      };
    },
    setHasVisitedRegisterScreen: (state, action) => {
      return { ...state, hasVisitedRegisterScreen: action.payload };
    },
    setChargeSimulationType: (state, action: PayloadAction<string>) => {
      return { ...state, chargeSimulationType: action.payload as ChargerStatus | 'None' };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvChargerDetails.fulfilled, (state, { payload }) => {
        // We do not require last_status as of now
        const { last_status, ...chargerDetails } = payload;
        return { ...state, ...chargerDetails, hasFetchedEvChargerDetails: true };
      })
      .addCase(fetchEvChargerDetails.rejected, (state) => {
        return { ...state, hasFetchedEvChargerDetails: true };
      })
      .addCase(fetchEvChargerStatus.fulfilled, (state, action) => {
        return { ...state, ...action.payload, isLoaded: true };
      })
      .addCase(fetchEvChargerStatus.rejected, (state) => {
        return { ...state, isLoaded: true };
      })
      .addCase(deactivateEvCharger.pending, (state) => {
        return { ...state, isDeactivating: true };
      })
      .addCase(deactivateEvCharger.fulfilled, () => {
        return { ...initialState, isDeactivating: false, isLoaded: true };
      })
      .addCase(deactivateEvCharger.rejected, (state) => {
        return { ...state, isDeactivating: false, isLoaded: true };
      });
  },
});

export const {
  resetEvChargerData,
  setIsLoaded,
  setEvChargerId,
  addEnrolledEvChargerDetails,
  setHasVisitedRegisterScreen,
  setChargeSimulationType,
} = evChargerSlice.actions;

export const selectEvChargerData = (state: RootState) => {
  return state.evCharger;
};

export const selectChargeSimulationType = (state: RootState) => {
  return state.evCharger.chargeSimulationType;
};

export default evChargerSlice.reducer;
