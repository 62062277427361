import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { formatNiceDateFromString } from 'clipsal-cortex-utils/src/formatting/formatting';

import ArcButton from '../../common/components/ArcButton';
import CustomDatePicker from '../../common/components/custom-date-picker/CustomDatePicker';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { getLocaleForDateFns, SupportedLocales, useTranslatedMonths } from '../../utils/common/common-utils';
import { selectCurrentDayForSite, selectSite } from '../site/siteSlice';
import { useCreateInvestmentMutation, useGetInvestmentsQuery, useUpdateInvestmentMutation } from './savingsApi';
import SavingsStatusIcon from './SavingsStatusIcon';
import { InvestmentState } from './types';

type EditInvestmentsFormData = {
  investments: InvestmentState[];
};

export function EditInvestmentsForm() {
  const navigate = useNavigate();
  const toast = useToast();
  const { site_id: siteId } = useSelector(selectSite);
  const todayInTimezone = useSelector(selectCurrentDayForSite);
  const { data: investmentsData, isLoading } = useGetInvestmentsQuery();
  const [updateInvestment, { isLoading: isUpdateLoading }] = useUpdateInvestmentMutation();
  const [createInvestment, { isLoading: isCreateLoading }] = useCreateInvestmentMutation();
  const isApiDataLoaded = !isLoading && !isUpdateLoading && !isCreateLoading;
  const doneButtonRef = useRef<HTMLDivElement>(null);
  const { i18n, t } = useTranslation();
  const MONTHS = useTranslatedMonths();

  const schema = yup.object({
    investments: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        date: yup.string().required(),
        description: yup.string().max(100).label(t('Savings.item name')).required(),
        amount: yup
          .number()
          .transform((value) => (isNaN(value) ? 0 : value)) // Prevents 'NaN' error messages for empty number fields
          .positive()
          .min(1)
          .max(1000000)
          .test(
            'noEOrSign', // type of the validator (should be unique)
            t('Savings.cost cant include e or sign'), // error message
            (value) => typeof value === 'number' && !/[eE+-]/.test(value.toString())
          )
          .label(t('Savings.item cost'))
          .required(),
      })
    ),
  });

  async function handleEditInvestments(values: EditInvestmentsFormData) {
    const createAndUpdatePromises = values.investments.reduce<Promise<InvestmentState>[]>((promises, investment) => {
      if (investment.id) {
        promises.push(
          updateInvestment({
            siteId,
            investmentId: investment.id,
            body: {
              amount: Math.abs(investment.amount),
              date: investment.date,
              description: investment.description,
            },
          }).unwrap()
        );
      } else {
        promises.push(
          createInvestment({
            siteId,
            body: {
              amount: Math.abs(investment.amount),
              date: investment.date,
              description: investment.description,
            },
          }).unwrap()
        );
      }
      return promises;
    }, []);

    try {
      await Promise.all(createAndUpdatePromises);
      navigate(`../home`);
    } catch (error) {
      toast({
        title: t('Savings.error updating investments'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }

  const {
    register,
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<EditInvestmentsFormData>({
    resolver: yupResolver(schema),
  });

  const {
    fields: formInvestments,
    append,
    replace,
    update,
  } = useFieldArray({
    control,
    name: 'investments', // unique name for your Field Array
    keyName: 'fieldId',
  });

  useEffect(() => {
    if (investmentsData?.investments) {
      replace(investmentsData.investments);
    }
  }, [investmentsData]);

  return (
    <SlidingAnimationPageBase backURL={`../home?direction=back`} title={t('Savings.savings')}>
      <Center flexDirection="column">
        <SavingsStatusIcon />
      </Center>
      <Text textAlign={'center'} fontSize={22} fontWeight={500} my={3} mb={5}>
        {t('Savings.edit investment cost')}
      </Text>
      <Skeleton mt={1} mx={3} mb={20} isLoaded={!isLoading} borderRadius={5} minH="50px">
        <Box data-testid="edit-investment-form" onSubmit={handleFormSubmit(handleEditInvestments)} as={'form'}>
          {formInvestments?.length ? (
            <>
              {formInvestments.map((investment, i) => {
                return (
                  <Box mx={4} mb={2} key={investment.id} data-testid={`investment-${i}-box`}>
                    <Text fontSize={14} fontWeight={600} mb={2}>
                      {t('Savings.installation date')}
                    </Text>
                    <FormControl isInvalid={errors.investments && !!errors.investments[i]?.date?.message}>
                      <CustomDatePicker
                        inputProps={{
                          'data-testid': `investment-${i}-installation-date-picker`,
                          borderRadius: 0,
                          _dark: { background: '#27282A' },
                          height: '44px',
                          placeholder: t('Common.select a date'),
                        }}
                        dateFormatFn={(date) => formatNiceDateFromString(date ?? null)}
                        selected={new Date(investment.date)}
                        onChange={(date) =>
                          update(i, {
                            ...investment,
                            date: format(date!, 'yyyy-MM-dd'),
                          })
                        }
                        months={MONTHS}
                        locale={getLocaleForDateFns(i18n.language as SupportedLocales)}
                      />
                    </FormControl>
                    <Text fontSize={14} fontWeight={600} my={2}>
                      {t('Savings.item name')}
                    </Text>
                    <FormControl isInvalid={errors.investments && !!errors.investments[i]?.description?.message}>
                      <Input
                        key={investment.id}
                        {...register(`investments.${i}.description`)}
                        type="text"
                        size="md"
                        mb={1}
                        data-testid={`investment-${i}-description-input`}
                        borderRadius={0}
                        _dark={{ background: 'customGrey.900' }}
                      />
                      <FormErrorMessage>
                        {errors?.investments ? errors?.investments[i]?.description?.message : ''}
                      </FormErrorMessage>
                    </FormControl>
                    <Text fontSize={14} fontWeight={600} my={2}>
                      {t('Savings.item cost')}
                    </Text>
                    <FormControl isInvalid={errors.investments && !!errors.investments[i]?.amount?.message}>
                      <InputGroup mb={4}>
                        <InputLeftElement pointerEvents="none">
                          <span>$</span>
                        </InputLeftElement>
                        <Input
                          key={investment.id}
                          {...register(`investments.${i}.amount`)}
                          type="number"
                          size="md"
                          data-testid={`investment-${i}-amount-input`}
                          borderRadius={0}
                          _dark={{ background: 'customGrey.900' }}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors?.investments ? errors?.investments[i]?.amount?.message : ''}
                      </FormErrorMessage>
                    </FormControl>
                    {i !== formInvestments.length - 1 && <Divider my={5} />}
                  </Box>
                );
              })}
              <Button
                hidden={formInvestments?.length !== investmentsData?.investments.length}
                data-testid="add-item-button"
                onClick={() => {
                  append({
                    id: null,
                    date: format(todayInTimezone, 'yyyy-MM-dd'),
                    description: '',
                    amount: 0,
                  });
                  doneButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
                variant={'ghost'}
                size={'sm'}
                fontWeight={400}
                colorScheme={'schneiderSkyBlue'}
              >
                {t('Savings.add item')}
              </Button>
              <Center>
                <ArcButton
                  arcColor="#3DCD57"
                  data-testid={'done-btn'}
                  width="100%"
                  maxWidth={'300px'}
                  my={6}
                  type="submit"
                  isLoading={!isApiDataLoaded}
                >
                  {t('Common.done')}
                </ArcButton>
                <Box ref={doneButtonRef} />
              </Center>
            </>
          ) : (
            <>
              <Text mx={4}>{t('Savings.you have no investments')}.</Text>
              <Button
                data-testid="add-item-button"
                onClick={() => {
                  append({
                    id: null,
                    date: format(todayInTimezone, 'yyyy-MM-dd'),
                    description: '',
                    amount: 0,
                  });
                }}
                variant={'ghost'}
                fontWeight={400}
                colorScheme={'schneiderSkyBlue'}
              >
                {t('Savings.add item')}
              </Button>
            </>
          )}
        </Box>
      </Skeleton>
    </SlidingAnimationPageBase>
  );
}
