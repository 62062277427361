import { useEffect } from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';

import { IS_ANDROID, IS_NATIVE } from '../constants';

export function useStatusBarStyleConfiguration() {
  const backgroundColor = useColorModeValue('#ffffff', '#111111');
  const statusBarColor = useColorModeValue('#f1f1f1', '#111111');
  const { colorMode } = useColorMode();

  useEffect(() => {
    // Set status bar style according to the current color mode setup in the app.
    async function setStyle() {
      /* istanbul ignore if -- @preserve */
      if (IS_NATIVE) {
        await StatusBar.setStyle({ style: colorMode === 'light' ? Style.Light : Style.Dark });
        if (IS_ANDROID) {
          await StatusBar.setBackgroundColor({ color: statusBarColor });
          await NavigationBar.setColor({ color: backgroundColor });
        }
      }
    }

    setStyle();
  }, [colorMode]);
}
