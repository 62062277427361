import React from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { UserLocale } from 'clipsal-cortex-types/src/api/api-user';

import { useUpdateLocaleMutation } from '../../features/account/accountApi';
import { SUPPORTED_LOCALES } from '../../i18n';
import { CustomSelect, SelectOption } from './CustomSelect';

/**
 * Maps IETF BCP 47 language tags to our API locale names.
 *
 * @param locale - An IETF BCP 47 language tag (e.g. 'en-US', 'fr-CA', 'es-US').
 *
 * @returns The locale name in our API for the language tag
 */
function mapLocaleToApiValue(locale: string): UserLocale {
  switch (locale) {
    case 'en-US':
      return 'EN_US';
    case 'fr-CA':
      return 'FR_CA';
    case 'es-US':
      return 'ES_US';
    default:
      return 'EN_US'; // Default to English (US)
  }
}

export default function LanguageSelector({ ...props }: BoxProps) {
  const { i18n, t } = useTranslation();
  const backgroundColor = useColorModeValue('#FFFFFF', '#27282A');
  const [updateLocale] = useUpdateLocaleMutation();

  async function handleChangeLanguage(language: SelectOption | null) {
    try {
      if (language?.value) {
        i18n.changeLanguage(language.value);
        const userLocale = mapLocaleToApiValue(language.value);
        await updateLocale({ locale: userLocale }).unwrap();
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Box data-testid="language-selector" {...props}>
      <CustomSelect
        value={{
          value: i18n.language,
          label: SUPPORTED_LOCALES[i18n.language],
        }}
        onChange={handleChangeLanguage}
        options={Object.entries(SUPPORTED_LOCALES).map(([code, name]) => ({ value: code, label: name }))}
        placeholder={`${t('Common.select language')}...`}
        backgroundColor={backgroundColor}
        isSearchable={false}
      />
    </Box>
  );
}
