import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useLiveData } from '../../../site/live-data/liveDataApi';
import { selectSite } from '../../../site/siteSlice';
import { CHART_DEFAULT_OPTIONS, CHART_SPACER, SOURCES_COLOURS } from '../constants';

export function useSourcesChartData() {
  const { is_consumption_site } = useSelector(selectSite);
  const { data, isMeterError, isLoading, isFetching, error } = useLiveData();

  const seriesData = useMemo(() => {
    const { solar, grid } = data;
    const solarProduction = Math.max(solar, 0);
    const gridImport = Math.max(grid, 0);
    const totalSources = solarProduction + gridImport;

    if (isLoading || totalSources === 0) return [];

    return [
      ...(!is_consumption_site && solarProduction
        ? [
            CHART_SPACER,
            {
              name: 'Solar',
              y: Math.min((Math.abs(solarProduction) / totalSources) * 100, 100),
              color: SOURCES_COLOURS.solar,
            },
          ]
        : []),
      ...(gridImport > 0
        ? [
            CHART_SPACER,
            {
              name: 'Grid',
              y: Math.min((gridImport / totalSources) * 100, 100),
              color: SOURCES_COLOURS.grid,
            },
          ]
        : []),
    ];
  }, [isLoading, data]);

  const options = useMemo(
    () => ({
      ...CHART_DEFAULT_OPTIONS,
      plotOptions: {
        pie: {
          ...CHART_DEFAULT_OPTIONS.plotOptions.pie,
          innerSize: 280,
        },
      },
      series: [
        {
          data: seriesData,
        },
      ],
    }),
    [seriesData]
  );

  return {
    isLoading,
    isFetching,
    isError: isMeterError,
    error,
    data,
    options,
  };
}
