import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectUser } from '../../../features/user/userSlice';

export const RouteAuthCheck = ({ children }: PropsWithChildren) => {
  const { isLoggedIn } = useSelector(selectUser);

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ originalPath: `${window.location.pathname}${window.location.search}` }} replace />
  );
};
