import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Text,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { Duration, intervalToDuration } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChargerStatus } from 'clipsal-cortex-types/src/api/api-evcharger';
import useInterval from 'clipsal-cortex-utils/src/hooks/use-interval';

import { useReduxDispatch } from '../../../app/store';
import pendingGifImgDark from '../../../assets/images/charger_preparing_animation_dark_mode.gif';
import pendingGifImgLight from '../../../assets/images/charger_preparing_animation_light_mode.gif';
import chargingGifImgDark from '../../../assets/images/dark_animation.gif';
import { ReactComponent as MobileQRScan } from '../../../assets/images/evlink_mobile_scan.svg';
import chargingGifImgLight from '../../../assets/images/light_animation.gif';
import { post } from '../../../common/api/api-helpers';
import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { selectEvChargers, selectSite } from '../../site/siteSlice';
import { ARC_BUTTON_UI_CONFIG, CHARGER_STATUS_UI_CONFIG } from './ev-charger-helpers';
import { fetchEvChargerStatus, selectEvChargerData, setIsLoaded } from './evChargerSlice';

const DEFAULT_INTERVAL_MS = 5000;

// These statuses will not have description below charging status
const NO_DESCRIPTION_STATUSES: ChargerStatus[] = ['Preparing'];

const formatTotalTime = (totalTime: Duration) => {
  const days = totalTime.days || '';
  const hours = totalTime.hours || '';
  const minutes = totalTime.minutes || '';

  let duration = '';

  if (minutes) duration = `${minutes}m`;
  if (hours) duration = `${hours}h ${duration}`;
  if (days) duration = `${days}d ${duration}`;

  return duration;
};

/* istanbul ignore next -- @preserve */
export const EvChargerStatus = () => {
  const site = useSelector(selectSite);
  const evChargers = useSelector(selectEvChargers);
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const hasEvChargers = !!evChargers.length;
  const {
    isLoaded,
    chargerId,
    status,
    power,
    energy_consumed: energyConsumed,
    latest_charge_session_start: latestChargeSessionStart,
    error_title: errorTitle,
    error_message: errorMessage,
    error,
  } = useSelector(selectEvChargerData);
  const { colorMode } = useColorMode();
  const imgSrc = CHARGER_STATUS_UI_CONFIG[status as ChargerStatus]?.imgSrc;
  const toast = useToast();
  const [isStoppingCharging, setIsStoppingCharging] = useState(false);
  const isCharging = status === 'Charging';
  const isFaulty = status === 'Faulted';
  const isChargingComplete = status === 'Finishing';
  // If no start time, set it to now.
  const startDate = latestChargeSessionStart ? new Date(latestChargeSessionStart) : new Date();
  const totalTime = formatTotalTime(intervalToDuration({ start: startDate, end: new Date() }));
  const chargerStateTitle = isFaulty ? errorTitle : status;
  const chargerStateDescription = isFaulty ? errorMessage : status;
  const canPollStatus = hasEvChargers && isLoaded;
  const isWifiError = error === 'WeakSignal';
  const shouldRenderArcBtn = isCharging || isFaulty;
  const [isSmallMobile] = useMediaQuery('(max-height: 700px)', { ssr: false });
  const arcButtonState =
    ARC_BUTTON_UI_CONFIG[isWifiError ? 'wifiError' : (status as Extract<ChargerStatus, 'Charging' | 'Faulted'>)];

  const bgColor = useColorModeValue('#F7F7F7', '#111111');
  const chargingAnimationImg = useColorModeValue(chargingGifImgLight, chargingGifImgDark);
  const pendingAnimationImg = useColorModeValue(pendingGifImgLight, pendingGifImgDark);
  const metadataBgColor = useColorModeValue('#E7E6E6', 'customGrey.800');
  const buttonTextColor = useColorModeValue('black', 'white');
  const dividerColor = useColorModeValue('#D9D9D9', '#494B50');

  // polling will start after first status update
  useInterval(
    async () => {
      await fetchEvChargerStatusData();
    },
    canPollStatus ? DEFAULT_INTERVAL_MS : null
  );

  const fetchEvChargerStatusData = async () => {
    const evChargerId = evChargers[0].site_identifier;
    if (evChargerId) {
      try {
        await dispatch(fetchEvChargerStatus(evChargerId));
      } catch (error) {
        console.error(error);
        toast({ title: 'Something went wrong fetching charger status', status: 'error', duration: 5000 });
      }
    }
  };

  const handleStopCharging = async () => {
    try {
      setIsStoppingCharging(true);
      await post(`/v1/sites/${site.site_id}/ev_chargers/${chargerId}/stop_charge`, {});
      await fetchEvChargerStatusData();
    } catch (error) {
      toast({ title: 'Something went wrong while stopping charging', status: 'error', duration: 5000 });
      console.error(error);
    }
    setIsStoppingCharging(false);
  };

  const handleClick = () => {
    if (isCharging) return handleStopCharging();
    if (isWifiError) return navigate('../../wifi/home');
    if (isFaulty) return navigate(`../../../account/support`);
  };

  useEffect(() => {
    // If site has ev chargers, fetch status data else show empty state
    if (!isLoaded) {
      if (hasEvChargers) {
        fetchEvChargerStatusData();
      } else {
        dispatch(setIsLoaded(true));
      }
    }
  }, []);

  const getArcButton = () => {
    const arcButtonProps = {
      onClick: handleClick,
      isLoading: isStoppingCharging,
      arcColor: arcButtonState?.arcColor?.[colorMode],
      minWidth: '280px',
      maxWidth: '300px',
      my: isSmallMobile ? 8 : 12,
      ['data-testid']: `${arcButtonState?.text}-button`.toLowerCase().replace(' ', '-'),
    };

    return <ArcButton {...arcButtonProps}>{arcButtonState?.text}</ArcButton>;
  };

  if (!hasEvChargers) return <EmptyEvChargerStatus />;

  return (
    <SlidingAnimationPageBase title="EV Charger Status" backURL={`../../../home`}>
      <Flex bgColor={bgColor} w="100%" h="100%" justify="space-between" direction={'column'} pt={8} overflow={'hidden'}>
        <Flex flexGrow={1} justify={'space-around'} direction="column" align={'center'} w="100vw">
          <Box>
            <SkeletonCircle
              mx="auto"
              w={['300px', '300px', '400px', '500px']}
              h={['300px', '300px', '400px', '500px']}
              isLoaded={isLoaded}
            >
              <Box w="100%" h="100%" position={'relative'}>
                {/* Gif image rendered behind the circle */}
                {status === 'Charging' && (
                  <Image
                    w={['300px', '300px', '400px', '500px']}
                    h={['300px', '300px', '400px', '500px']}
                    position="absolute"
                    src={chargingAnimationImg}
                  />
                )}
                {status === 'Preparing' && (
                  <Center w="100%" h="100%" position="absolute">
                    <Image w="50%" h="50%" src={pendingAnimationImg} />
                  </Center>
                )}
                <Image
                  w={['300px', '300px', '400px', '500px']}
                  h={['300px', '300px', '400px', '500px']}
                  pos="absolute"
                  src={imgSrc?.[colorMode]}
                />
              </Box>
            </SkeletonCircle>

            <SkeletonText mx={'auto'} mt={4} isLoaded={isLoaded}>
              <Text fontWeight={'semibold'} fontSize={'24px'} textAlign={'center'} data-testid="charging-status-title">
                {chargerStateTitle || 'Unavailable'}
              </Text>
              {!NO_DESCRIPTION_STATUSES.includes(status) && (
                <>
                  {isChargingComplete && (
                    <>
                      <Text align={'center'}>{`Charge Time: ${totalTime}`}</Text>
                      <Text pb={2} align={'center'}>{`Total Energy: ${energyConsumed.toFixed(2)} kWh`}</Text>
                    </>
                  )}
                  {isCharging && (
                    <>
                      <Text
                        align={'center'}
                        data-testid="charging-status-description"
                      >{`Session Time ${totalTime} since: ${formatInTimeZone(
                        startDate,
                        site.timezone,
                        'h:mm:ss aaa'
                      )}`}</Text>
                      <Text align={'center'}>{`Current Power ${power.toFixed(2)} kW (${energyConsumed.toFixed(
                        2
                      )} kWh total)`}</Text>
                    </>
                  )}
                  {!isCharging && (
                    <Text
                      mx={4}
                      mt={2}
                      whiteSpace={'pre-line'}
                      align={chargerStateDescription?.includes('\n') ? 'left' : 'center'}
                      data-testid="charging-status-description"
                    >
                      {chargerStateDescription ||
                        'Something went wrong. If this problem persists, contact customer care.'}
                    </Text>
                  )}
                </>
              )}
            </SkeletonText>
          </Box>

          <SkeletonText px={4} mt={2} noOfLines={2} isLoaded={isLoaded}>
            {shouldRenderArcBtn && getArcButton()}
          </SkeletonText>
        </Flex>

        <Flex w="100%" direction={'column'} bgColor={metadataBgColor}>
          <Skeleton isLoaded={isLoaded}>
            <Button
              bg={metadataBgColor}
              color={buttonTextColor}
              borderRadius={0}
              border={'none'}
              size={'xl'}
              fontWeight={'400'}
              w="100%"
              py={5}
              onClick={() => navigate('../device_charge_history')}
              data-testid="device-charge-history-button"
            >
              <Flex align={'center'} justify="space-between" w="100%">
                <Text ml={6} fontSize={'16px'}>
                  Charge History
                </Text>
                <ChevronRightIcon color={'#A6A6A6'} mr={4} w={6} h={6} />
              </Flex>
            </Button>
          </Skeleton>

          <Divider ml={6} borderColor={dividerColor} />

          <Skeleton isLoaded={isLoaded}>
            <Button
              bg={metadataBgColor}
              color={buttonTextColor}
              borderRadius={0}
              border="none"
              size={'xl'}
              fontWeight={'400'}
              w="100%"
              py={5}
              onClick={() => navigate('../device_details')}
              data-testid="device-details-button"
            >
              <Flex align={'center'} justify="space-between" w="100%">
                <Text ml={6} fontSize={'16px'}>
                  Device Details
                </Text>
                <ChevronRightIcon color={'#A6A6A6'} mr={4} w={6} h={6} />
              </Flex>
            </Button>
          </Skeleton>
        </Flex>
      </Flex>
    </SlidingAnimationPageBase>
  );
};

// Shown when there is no EV Charger device on the site
function EmptyEvChargerStatus() {
  const navigate = useNavigate();

  return (
    <SlidingAnimationPageBase title="No Device" backURL={`../../../home`}>
      <Center flexDirection={'column'} data-testid="empty-ev-charger-status" pb={16}>
        <Flex direction={'column'} align="center" mt="auto">
          <Box>
            <MobileQRScan style={{ width: 'fit-content', height: 'auto', maxHeight: '300px', marginLeft: '30px' }} />
          </Box>
          <Text data-testid="no-device-text" px={8} mt={8} fontSize="24px" fontWeight={'bold'}>
            You have no devices setup
          </Text>
          <Text px={8} mt={2} textAlign="center">
            If you have a new device, scan the QR code to add a device now.
          </Text>
        </Flex>
        <ArcButton
          mt="auto"
          data-testid="device-setup-button"
          arcColor="#3DCD57"
          minWidth={'280px'}
          maxWidth={'300px'}
          onClick={() => navigate(`../device_register`)}
        >
          Add a Device
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
