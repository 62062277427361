import { format } from 'date-fns';
import { fromZonedTime, getTimezoneOffset } from 'date-fns-tz';

import { convertOffsetToISO8601, getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { getTimeFormat } from '../account/settings/settings-helpers';

/**
 * Given a date string, formatted as `yyyy-MM-dd`, and a timezone, creates and returns a new `Date` object with the
 * internal UTC tracked time set to the equivalent time in the given timezone.
 *
 * Useful because we need to use local timezones when managing dates in the date picker, but we need to add offsets
 * when working with dates in the chart.
 *
 * @param dateString
 * @param timezone
 */
export function convertToTimezoneAwareDate(dateString: string, timezone: string): Date {
  const offset = getTimezoneOffset(timezone, new Date(dateString)) / 60_000;
  const offsetStr = convertOffsetToISO8601(offset);

  return new Date(dateString + `T00:00:00${offsetStr}`);
}

export function getUIConfigForRangeType(dateRangeType: DateRangeType) {
  const timeFormat = getTimeFormat();

  const RANGE_TYPE_UI_CONFIG = {
    [DateRangeType.Day]: {
      xAxisTickFormat: timeFormat === '24HR' ? 'HH:mm' : 'h aaa',
      tooltipDateFormat: timeFormat === '24HR' ? '%H:%M' : '%l:%M %P',
      xAxisTickInterval: 6 * 60 * 60 * 1000,
    },
    [DateRangeType.Week]: {
      xAxisTickFormat: 'EEEEE',
      tooltipDateFormat: '%e %b %Y',
      xAxisTickInterval: 24 * 60 * 60 * 1000,
    },
    [DateRangeType.Month]: {
      xAxisTickFormat: 'd',
      tooltipDateFormat: '%e %b %Y',
      xAxisTickInterval: 24 * 60 * 60 * 1000,
    },
    [DateRangeType.Year]: {
      xAxisTickFormat: 'LLLLL',
      tooltipDateFormat: '%B',
      xAxisTickInterval: 24 * 60 * 60 * 1000 * 30,
    },
  };

  return RANGE_TYPE_UI_CONFIG[dateRangeType];
}

export function getInitialSelectedDates(currentDay: Date) {
  return {
    [DateRangeType.Day]: new Date(currentDay),
    [DateRangeType.Week]: new Date(currentDay),
    [DateRangeType.Month]: new Date(currentDay),
    [DateRangeType.Year]: new Date(currentDay),
  };
}

export function getMinDateTime(startDate: Date, selectedDateRangeType: DateRangeType, timezone: string) {
  const zonedStartDate = fromZonedTime(startDate, timezone);
  if (selectedDateRangeType === DateRangeType.Day) {
    return zonedStartDate.getTime();
  } else if (selectedDateRangeType === DateRangeType.Week) {
    const firstDayOfWeek = getFirstDayOfWeek(startDate); // Monday
    const firstDayOfWeekDateString = format(firstDayOfWeek, 'yyyy-MM-dd');
    return convertToTimezoneAwareDate(firstDayOfWeekDateString, timezone).getTime();
  } else if (selectedDateRangeType === DateRangeType.Month) {
    const firstDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const firstDayOfMonthString = format(firstDayOfMonth, 'yyyy-MM-dd');
    return convertToTimezoneAwareDate(firstDayOfMonthString, timezone).getTime();
  } else {
    const firstDayOfYear = new Date(`${startDate.getFullYear()}-01-01`).getTime();
    const firstDayOfYearString = format(firstDayOfYear, 'yyyy-MM-dd');
    return convertToTimezoneAwareDate(firstDayOfYearString, timezone).getTime();
  }
}

export function getMaxDateTime(startDate: Date, selectedDateRangeType: DateRangeType, timezone: string) {
  const zonedStartDate = fromZonedTime(startDate, timezone);
  if (selectedDateRangeType === DateRangeType.Day) {
    zonedStartDate.setDate(zonedStartDate.getDate() + 1);
    return zonedStartDate.getTime();
  } else if (selectedDateRangeType === DateRangeType.Week) {
    const firstDayOfWeek = getFirstDayOfWeek(startDate); // Monday
    const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
    const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6)); // Friday
    const lastDayOfWeekDateString = format(lastDayOfWeek, 'yyyy-MM-dd');
    return convertToTimezoneAwareDate(lastDayOfWeekDateString, timezone).getTime();
  } else if (selectedDateRangeType === DateRangeType.Month) {
    const firstDayOfNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
    const lastDayOfMonth = new Date(firstDayOfNextMonth);
    lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
    const lastDayOfMonthString = format(lastDayOfMonth, 'yyyy-MM-dd');
    return convertToTimezoneAwareDate(lastDayOfMonthString, timezone).getTime();
  } else {
    const firstDayOfLastMonthOfYear = new Date(`${startDate.getFullYear()}-12-01`);
    const firstDayOfLastMonthOfYearString = format(firstDayOfLastMonthOfYear, 'yyyy-MM-dd');
    return convertToTimezoneAwareDate(firstDayOfLastMonthOfYearString, timezone).getTime();
  }
}
