import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import { Wifi } from '@capacitor-community/wifi/src';
import { Network } from '@capacitor/network';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Device } from 'clipsal-cortex-types/src/api/api-site';

import { useReduxDispatch } from '../../../../../app/store';
import { post } from '../../../../../common/api/api-helpers';
import ArcButton from '../../../../../common/components/ArcButton';
import { PasswordInput } from '../../../../../common/components/PasswordInput';
import SelectWifiDialog from '../../../../../common/components/SelectWifiDialog';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import TopNavProgressLoader from '../../../../../common/components/TopNavProgressLoader';
import { IS_ANDROID, IS_PRODUCTION } from '../../../../../common/constants';
import { timeout } from '../../../../../utils/common/common-utils';
import { fetchSite, selectSite } from '../../../../site/siteSlice';
import { get as nativeGet, post as nativePost } from '../api-helpers';
import { AP_WIFI_STATUS_TO_ERROR_TYPE, BASE_IPV4_ADDRESS, ErrorType } from './constants';
import { useSaturnRegistrationErrorMessage } from './useSaturnRegistrationErrorMessage';
import { NetworkInterfaces, WiFiConnectionStatus } from './wireless-access-api-types';

type Status =
  | ErrorType
  | 'CHECKING_FOR_ETHERNET_CONNECTION'
  | 'ETHERNET_CONNECTION_DISCOVERED'
  | 'WIFI_CREDENTIAL_INPUT_REQUIRED';

type State = {
  ssid: string;
  password: string;
  isSubmitting: boolean;
  status: Status;
};

const INITIAL_STATE: State = {
  status: 'CHECKING_FOR_ETHERNET_CONNECTION',
  ssid: '',
  password: '',
  isSubmitting: false,
};

const RETRY_DELAY_MS = 5_000;
const MAX_STATUS_GET_ATTEMPTS = 12;

/* istanbul ignore next -- @preserve */
export function InverterWifiCredentials() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);
  const { status, ssid, password, isSubmitting } = state;
  const backgroundColor = useColorModeValue('customGrey.100', 'customGrey.800');
  const dispatch = useReduxDispatch();
  const toast = useToast({ isClosable: true });
  const macAddressRef = useRef<string | null>(null);
  const { onOpen: onOpenWifiDialog, onClose: onCloseWifiDialog, isOpen: isWifiDialogOpen } = useDisclosure();
  const { getRegistrationErrorMessage } = useSaturnRegistrationErrorMessage();

  // This ensures when coming from hardware view page, we can go back to the same page when going back
  const urlSearchParams = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set('direction', 'back');
    return params.toString();
  }, []);

  const isRegisteredDevice = useMemo(() => {
    return search.get('shouldRegisterDevice') === 'false';
  }, [search]);

  useEffect(() => {
    HTTP.setDataSerializer('json');
    HTTP.setServerTrustMode('nocheck');

    // If the inverter is already connected to the router via an ethernet cable (informed by the status endpoint of the
    // SCC AP), skip Wi-Fi configuration and register the device on the internet.
    async function checkForEthernetConnection() {
      const { isEthernetConnected, macAddress } = await getConnectionStatus();

      if (isEthernetConnected) {
        setState((p) => ({
          ...p,
          status: 'ETHERNET_CONNECTION_DISCOVERED',
        }));

        macAddressRef.current = macAddress;

        // We need to disconnect from the AP on Android devices as there are issues contacting the Internet through
        // cellular connections otherwise
        if (IS_ANDROID) await Wifi.disconnect();

        const { connected: isConnectedToInternet } = await Network.getStatus();
        if (!isConnectedToInternet) {
          // Users need to manually input their network credentials again to continue if they don't have a cellular
          // connection.
          toast({
            title: t('Set Up Hardware.home wifi credentials required'),
            description: t('Set Up Hardware.no cellular network connection'),
            status: 'info',
          });
          onOpenWifiDialog();
          return;
        }

        // Skip rest of the steps to register the device if it's already been registered
        if (!isRegisteredDevice) {
          const didRegister = await handleRegisterDevice(macAddress);

          if (!didRegister) {
            setState((p) => ({ ...p, status: 'GENERIC_ERROR' }));
          }
        } else {
          navigate(`../finish?${search.toString() || ''}`);
        }
      } else {
        setState((p) => ({
          ...p,
          status: 'WIFI_CREDENTIAL_INPUT_REQUIRED',
        }));
      }
    }

    checkForEthernetConnection();
  }, []);

  async function handleReconnectToHomeNetwork() {
    // Re-connect the user back to their home Wi-Fi network to give them Internet connectivity
    try {
      await Wifi.connect({ ssid, password, isHiddenSsid: false });
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast({
        title: t('Set Up Hardware.error connecting back to wifi'),
        description: t('Common.please try again'),
        status: 'error',
      });
      setState((p) => ({ ...p, isSubmitting: false, status: 'WIFI_RECONNECT_FAILED' }));
      return false;
    }
  }

  async function handleRegisterDevice(macAddress: string): Promise<boolean> {
    try {
      const response = await post<Device[]>(`/v1/sites/${site.site_id}/register_saturn_device`, {
        mac_address: macAddress,
      });

      if (!response.length) {
        toast({
          title: t('Set Up Hardware.error registering device'),
          description: t('Set Up Hardware.we did not discover devices'),
          status: 'error',
        });
        setState((p) => ({ ...p, isSubmitting: false }));

        return false;
      }

      // Just re-fetch the site to retrieve the new devices, rather than requiring complex diffing logic from the
      // devices in the response.
      await dispatch(fetchSite({ siteID: site.site_id, isProduction: IS_PRODUCTION }));

      navigate(`../finish`);

      return true;
    } catch (e) {
      const error = e as AxiosError;
      console.error(e);
      Sentry.captureException(e);

      if (!error.response) {
        // If no response is received, it timed out.
        toast({
          title: t('Common.request timed out'),
          description: t('Common.check internet connection'),
          status: 'error',
        });
      } else if (error.response.status === 404) {
        // Trigger a new flow on 404 response (the device is not yet in Saturn cloud).
        // See the `PendingInverterInternetConnection` component docblock comment for details.
        localStorage.setItem(`inverterConnectionPendingMACAddress_${site.site_id}`, macAddress);
        navigate(`../pending_inverter_internet_connection`);
        return true;
      } else {
        const messaging = getRegistrationErrorMessage(error);
        toast({
          title: messaging.title,
          description: messaging.description,
          status: 'error',
        });
      }

      setState((p) => ({ ...p, isSubmitting: false }));

      return false;
    }
  }

  async function getConnectionStatus(
    attempt = 1
  ): Promise<{ macAddress: string; status: WiFiConnectionStatus; isEthernetConnected: boolean }> {
    let macAddress = '';
    let status: WiFiConnectionStatus = 'NOT_CONNECTED';
    let isEthernetConnected = false;

    try {
      // We have to extract the MAC address before we re-connect to the home Wi-Fi network
      // Also check if Wi-Fi has been configured successfully
      const interfaces = await nativeGet<NetworkInterfaces>(`${BASE_IPV4_ADDRESS}/v1/homeapp/status`, {
        'Content-type': 'application/json',
      });

      macAddress = interfaces.eth1.mac_address;
      status = interfaces.wifi.connection_status;
      isEthernetConnected = interfaces.eth1.enabled;

      // Assign to a ref to ensure it can be re-used elsewhere
      macAddressRef.current = macAddress;
    } catch (e) {
      console.error(e);
      // Recursively retry up to 12 times with 5s delay between each attempt
      if (attempt <= MAX_STATUS_GET_ATTEMPTS) {
        await timeout(RETRY_DELAY_MS);
        return await getConnectionStatus(attempt + 1);
      } else {
        Sentry.captureException(e);
        toast({
          title: t('Set Up Hardware.could not get inverter status'),
          description: t('Set Up Hardware.please check connection to inverter wifi'),
          status: 'error',
        });

        navigate(`../power_up_instructions`);
      }
    }

    return { macAddress, status, isEthernetConnected };
  }

  async function handleConnectToNetwork() {
    setState((p) => ({ ...p, isSubmitting: true }));

    try {
      const body = {
        ssid,
        wifi_password: password,
      };

      try {
        // Connect the SCC to the Wi-Fi network
        await nativePost(`${BASE_IPV4_ADDRESS}/v1/homeapp/wifi/configuration`, body, {
          'Content-type': 'application/json',
          'Content-Length': JSON.stringify(body).length.toString(),
        });
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);

        toast({
          title: t('Set Up Hardware.could not save wifi configuration on inverter'),
          description: t('Set Up Hardware.please check connection to inverter wifi'),
          status: 'error',
        });

        setState((p) => ({ ...p, status: 'GENERIC_ERROR', isSubmitting: false }));
        return;
      }

      // @FIXME: Without an arbitrary wait time, the SCC gives a 500 error after adding a wifi connection
      // I assume this is a bug in the firmware.
      await timeout(5_000);

      // Query the status endpoint to discern whether the credentials worked
      const { status, macAddress } = await getConnectionStatus();
      if (status !== 'CONNECTED') {
        setState((p) => ({ ...p, isSubmitting: false, status: AP_WIFI_STATUS_TO_ERROR_TYPE[status] }));
        return false;
      }

      // We need to disconnect from the AP on Android devices as there are issues contacting the Internet through
      // cellular connections otherwise
      if (IS_ANDROID) await Wifi.disconnect();

      // Reconnect the user back to their home Wi-Fi network to give them Internet connectivity, if they are not
      // already connected via cellular (e.g. an iPad)
      const { connected: isConnectedToInternet } = await Network.getStatus();
      if (!isConnectedToInternet) {
        await handleReconnectToHomeNetwork();
        // Again, a timeout is required to re-establish an Internet connection on the home Wi-Fi network
        await timeout(5_000);
      }

      // Skip rest of the steps to register the device if it's already been registered
      if (!isRegisteredDevice) {
        const didRegister = await handleRegisterDevice(macAddress);
        if (!didRegister) {
          setState((p) => ({ ...p, status: 'GENERIC_ERROR', isSubmitting: false }));
        }
      } else {
        navigate(`../finish?${search.toString() || ''}`);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);

      toast({
        title: t('Set Up Hardware.could not get inverter status'),
        description: t('Set Up Hardware.please check connection to inverter wifi'),
        status: 'error',
      });

      setState((p) => ({ ...p, status: 'GENERIC_ERROR', isSubmitting: false }));
    }
  }

  async function handleRetryRegistration() {
    setState((p) => ({ ...p, isSubmitting: true }));

    // We need to disconnect from the AP on Android devices as there are issues contacting the Internet through
    // cellular connections otherwise
    if (IS_ANDROID) await Wifi.disconnect();

    // Reconnect the user back to their home Wi-Fi network to give them Internet connectivity, if they are not
    // already connected via cellular (e.g. an iPad)
    const { connected: isConnectedToInternet } = await Network.getStatus();
    if (!isConnectedToInternet) await handleReconnectToHomeNetwork();

    const macAddress = macAddressRef.current;
    if (!macAddress) {
      toast({
        title: t('Set Up Hardware.no mac address found'),
        description: t('Set Up Hardware.please go back and scan qr code again'),
        status: 'error',
      });
      setState((p) => ({ ...p, status: 'GENERIC_ERROR', isSubmitting: false }));
      return;
    }

    // Skip rest of the steps to register the device if it's already been registered
    if (!isRegisteredDevice) {
      const didRegister = await handleRegisterDevice(macAddress);
      if (!didRegister) {
        setState((p) => ({ ...p, status: 'GENERIC_ERROR', isSubmitting: false }));
      }
    } else {
      navigate(`../finish?${search.toString() || ''}`);
    }
  }

  // Handles the case where the user must manually re-enter their home Wi-Fi credentials, in the event that an ethernet
  // connection is detected on the SCC, but the user's device does not have a cellular connection. See the effect
  // in this component for more information.
  async function handleCompleteManualWifiReconnection() {
    onCloseWifiDialog();

    // If somehow, the MAC address isn't stored at this point, show an error which forces the user to try again
    // from the previous step.
    if (!macAddressRef.current) {
      setState((p) => ({ ...p, status: 'GENERIC_ERROR' }));
      return;
    }

    // Skip rest of the steps to register the device if it's already been registered
    if (!isRegisteredDevice) {
      // Wait to ensure device is connected to the network properly
      await timeout(5_000);
      const didRegister = await handleRegisterDevice(macAddressRef.current);

      if (!didRegister) {
        setState((p) => ({ ...p, status: 'GENERIC_ERROR' }));
      }
    } else {
      navigate(`../finish?${search.toString() || ''}`);
    }
  }

  // Default UI value for "WIFI_CREDENTIAL_INPUT_REQUIRED"
  let contents = (
    <>
      <Heading mt={2}>{t('Set Up Hardware.enter home wifi credentials')}</Heading>
      <FormControl isInvalid={status === 'INVALID_SSID'} mt={3}>
        <Input
          value={ssid}
          onChange={(e) => setState((p) => ({ ...p, ssid: e.target.value, status: 'WIFI_CREDENTIAL_INPUT_REQUIRED' }))}
          borderRadius={0}
          type="text"
          data-private
          background={backgroundColor}
          borderColor={'#9FA0A4'}
          placeholder={t('Common.wifi ssid')}
        />
        <FormErrorMessage>{t('Common.invalid wifi ssid')}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={status === 'INVALID_PASSWORD'} mt={3}>
        <PasswordInput
          onChange={(password) => setState((p) => ({ ...p, password, status: 'WIFI_CREDENTIAL_INPUT_REQUIRED' }))}
          value={password}
          backgroundColor={backgroundColor}
          borderColor={'#9FA0A4'}
          placeholder={t('Common.wifi pw')}
        />
        <FormErrorMessage>{t('Common.invalid wifi pw')}</FormErrorMessage>
      </FormControl>

      <ArcButton
        onClick={handleConnectToNetwork}
        mt={8}
        w={'80%'}
        isDisabled={!ssid}
        arcColor="#3DCD57"
        isLoading={isSubmitting}
      >
        {t('Common.continue')}
      </ArcButton>
    </>
  );

  if (status === 'AP_CONNECTION_FAILED') {
    contents = (
      <>
        <Heading>{t('Set Up Hardware.connection error to inverter ap')}</Heading>
        <Text>{t('Set Up Hardware.please return and reconnect inverter')}</Text>
      </>
    );
  }

  if (status === 'GENERIC_ERROR') {
    contents = (
      <>
        <Heading>{t('Set Up Hardware.unknown error setting up inverter')}</Heading>
        <Text>{t('Set Up Hardware.please return and reconnect inverter')}</Text>
      </>
    );
  }

  if (status === 'NO_ROUTER_INTERNET_CONNECTION') {
    contents = (
      <>
        <Heading>{t('Set Up Hardware.your inverter couldnt connect to internet')}</Heading>
        <Text>{t('Set Up Hardware.please ensure the network has internet')}</Text>
      </>
    );
  }

  if (status === 'WIFI_RECONNECT_FAILED') {
    contents = (
      <>
        <Heading>{t('Set Up Hardware.error connecting back to wifi')}</Heading>
        <Text my={2}>{t('Set Up Hardware.make sure youre in range of wifi network')}</Text>
        <ArcButton onClick={handleRetryRegistration} mt={8} w={'80%'} arcColor="#3DCD57" isLoading={isSubmitting}>
          {t('Common.try again')}
        </ArcButton>
        <Button
          mt={2}
          w="80%"
          variant="ghost"
          fontSize={18}
          color="schneiderSkyBlue.600"
          onClick={async () => {
            await NativeSettings.open({
              optionAndroid: AndroidSettings.Wifi,
              optionIOS: IOSSettings.WiFi,
            });
          }}
        >
          {t('Set Up Hardware.open wifi settings')}
        </Button>
      </>
    );
  }

  if (status === 'CHECKING_FOR_ETHERNET_CONNECTION') {
    contents = (
      <>
        <TopNavProgressLoader />
        <Heading>{t('Set Up Hardware.checking for connection type')}</Heading>
      </>
    );
  }

  if (status === 'ETHERNET_CONNECTION_DISCOVERED') {
    contents = (
      <>
        <TopNavProgressLoader />
        <Heading>{t('Set Up Hardware.discovered ethernet connection')}</Heading>
        <Text>{t('Set Up Hardware.registering device on internet')}</Text>
      </>
    );
  }

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={`../scan?${urlSearchParams}`}>
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        {contents}
      </Center>

      <SelectWifiDialog
        shouldConnectToNetwork
        isOpen={isWifiDialogOpen}
        onClose={onCloseWifiDialog}
        onConfirm={handleCompleteManualWifiReconnection}
      />
    </SlidingAnimationPageBase>
  );
}
