import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

interface ErrorResponseData {
  code: string;
  detail: string;
}

/* istanbul ignore next -- @preserve */
export const useSaturnRegistrationErrorMessage = () => {
  const { t } = useTranslation();

  const defaultErrorMessage = useMemo(
    () => ({
      title: t('Set Up Hardware.error registering device'),
      description: t('Set Up Hardware.make sure you are connected'),
    }),
    []
  );

  const errorResponseCodeToMessaging = useMemo(
    () =>
      ({
        400: {
          title: t('Set Up Hardware.error getting installed devices'),
          description: t('Set Up Hardware.issue with hardware config'),
        },
        409: {
          title: t('Set Up Hardware.meter already in use'),
          description: t('Set Up Hardware.already associated to another home'),
        },
        422: {
          title: t('Set Up Hardware.inconsistent data'),
          description: t('Set Up Hardware.issue with hardware config'),
        },
      } as Record<number, { title: string; description: string }>),
    []
  );

  const status409ResponseCodeToMessaging = useMemo(
    () =>
      ({
        SENSE_DEVICE_ALREADY_IN_USE: {
          title: t('Set Up Hardware.meter already in use'),
          description: t('Set Up Hardware.already associated to another home'),
        },
        SATURN_DEVICE_ALREADY_IN_USE: {
          title: t('Set Up Hardware.inverter already registered'),
          description: t('Set Up Hardware.already registered to another home'),
        },
      } as Record<string, { title: string; description: string }>),
    []
  );

  const getRegistrationErrorMessage = useCallback(
    (error: AxiosError) => {
      // If there is no response, we don't have any information to display
      if (!error.response) return defaultErrorMessage;

      const statusCode = error.response.status;
      const data = error.response.data as ErrorResponseData;

      // For specific 409 errors, we want to show a different message based on the detail
      if (statusCode === 409 && data.code && data.code in status409ResponseCodeToMessaging) {
        return status409ResponseCodeToMessaging[data.code];
      }

      return errorResponseCodeToMessaging[statusCode] ?? defaultErrorMessage;
    },
    [errorResponseCodeToMessaging, status409ResponseCodeToMessaging, defaultErrorMessage]
  );

  return { getRegistrationErrorMessage, status409ResponseCodeToMessaging, errorResponseCodeToMessaging };
};
