import React, { PropsWithChildren } from 'react';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';

type Props = PropsWithChildren<{
  title: string;
  dataTestId?: string;
}>;

export function SenseAccordionItem({ title, children, dataTestId }: Props) {
  return (
    <AccordionItem borderBottom="none">
      <AccordionButton pl={0} pr={1} data-testid={dataTestId}>
        <Box as="span" flex="1" textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel px={0}>{children}</AccordionPanel>
    </AccordionItem>
  );
}
