import { JailbreakRoot } from '@basecom-gmbh/capacitor-jailbreak-root-detection';
import { RootJailBreakDetector } from 'capacitor-root-jailbreak-detector';

import { IS_ANDROID, IS_NATIVE } from '../../common/constants';

/**
 * Checks if the device is rooted (Android) or jailbroken (iOS)
 *
 */
/* istanbul ignore next -- @preserve */
export const isDeviceRootedOrJailbroken = async (): Promise<boolean> => {
  if (IS_NATIVE) {
    // @basecom-gmbh/capacitor-jailbreak-root-detection provides us with basic Android root detection, and --
    // standard iOS jailbreak detection.
    const { result: isRootedDevice } = await JailbreakRoot.isJailbrokenOrRooted();
    if (isRootedDevice) return true;

    // capacitor-root-jailbreak-detector provides us with advanced Android root detection via a wrapper around the --
    // popular RootBeer library for Android (https://github.com/scottyab/rootbeer).
    // Unfortunately it doesn't provide us with iOS jailbreak detection... @TODO: Consolidate both into one plugin
    if (IS_ANDROID) {
      const { isRooted: doesSuperuserApkExist } = await RootJailBreakDetector.doesSuperuserApkExistRooted();
      const { isRooted: doesSuperuserPathExist } = await RootJailBreakDetector.isExistSUPathRooted();
      const { isRooted: isDirectoryRooted } = await RootJailBreakDetector.isCheckDirPermissionsRooted();
      const { isRooted: canExecuteSudo } = await RootJailBreakDetector.isCheckExecutingCommandsRooted();
      const { isRooted: isRootedPackagesInstalled } = await RootJailBreakDetector.isCheckInstalledPackagesRooted();
      if (
        doesSuperuserApkExist ||
        doesSuperuserPathExist ||
        isDirectoryRooted ||
        canExecuteSudo ||
        isRootedPackagesInstalled
      ) {
        return true;
      }
    }
  }
  return false;
};
