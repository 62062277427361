import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { PasswordInput } from '../../../../common/components/PasswordInput';
import SEHomeCard from '../../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { selectSite } from '../../../site/siteSlice';
import { addWifiDetails } from './wifi-settings-helpers';

const FORM_ID = 'add-wifi-details-form';

export function AddWifiDetails() {
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Account.edit wifi settings')}
      backURL={`../view?direction=back`}
      customTopNavButton={
        <Button
          data-testid={'save-button'}
          variant={'ghost'}
          form={FORM_ID}
          size={'sm'}
          colorScheme={'schneiderSkyBlue'}
          type={'submit'}
          fontWeight={500}
        >
          {t('Common.save')}
        </Button>
      }
    >
      <Box m={4}>
        <Text>{t('Account.add network').toUpperCase()}</Text>
        <Box mb={16}>
          <AddWifiDetailsForm />
        </Box>
      </Box>
    </SlidingAnimationPageBase>
  );
}

type AddWifiDetailsFormData = {
  ssid: string;
  password: string;
  isDefault: boolean;
};

function AddWifiDetailsForm() {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation();
  const bgColor = useColorModeValue('#FFFFFF', 'customGrey.800');
  const textColor = useColorModeValue('customGrey.500', 'customGrey.400');

  const schema = yup.object({
    ssid: yup.string().required().label(t('Common.wifi ssid')),
    password: yup.string().required().label(t('Common.wifi pw')),
    isDefault: yup.boolean(),
  });

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control,
  } = useForm<AddWifiDetailsFormData>({
    resolver: yupResolver(schema),
    defaultValues: { password: '', isDefault: false },
  });

  async function handleSubmit({ ssid, password, isDefault }: AddWifiDetailsFormData) {
    try {
      await addWifiDetails(siteId, ssid, password, isDefault);
      toast({
        status: 'success',
        title: t('Account.success saving wifi details'),
      });
      navigate(`../view?direction=back`);
    } catch {
      toast({
        status: 'error',
        title: t('Account.error saving wifi details'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
      });
    }
  }

  return (
    <SEHomeCard borderTopLeftRadius={'5px'} mt={1}>
      <Box as={'form'} id={FORM_ID} onSubmit={handleFormSubmit(handleSubmit)} data-testid="add-wifi-details-form">
        <Box pt={3} pb={5}>
          <FormControl isInvalid={!!errors?.ssid}>
            <Input
              borderRadius={0}
              backgroundColor={bgColor}
              size="md"
              data-testid="edit-ssid-input"
              placeholder={`${t('Account.enter ssid')}...`}
              {...register(`ssid`)}
            />
            <FormErrorMessage data-testid="ssid-error">{errors?.ssid?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Divider borderColor="rgba(151, 151, 151, 0.3)" />
        <Box py={5}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value, ref } }) => (
              <FormControl isInvalid={!!errors?.password}>
                <PasswordInput
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  placeholder={`${t('Account.enter password')}...`}
                />
                <FormErrorMessage data-testid="password-error">{errors?.password?.message}</FormErrorMessage>
              </FormControl>
            )}
          />

          <Checkbox
            {...register('isDefault')}
            mt={3}
            colorScheme="primaryBranding"
            iconColor="#fff"
            data-testid="is-default-checkbox"
            borderColor={textColor}
            color={textColor}
          >
            {t('Account.set default network')}
          </Checkbox>
        </Box>
      </Box>
    </SEHomeCard>
  );
}
