import React from 'react';
import {
  Center,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useReduxDispatch } from '../../../app/store';
import phoneScanImg from '../../../assets/images/evlink_mobile_scan.svg';
import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { IS_IOS } from '../../../common/constants';
import { selectSite, setCurrentSite } from '../siteSlice';

export type HardwareFormData = {
  installedHardware: 'SEM' | 'INVERTER' | 'SEM_INVERTER' | 'WIRING_DEVICES';
};

export function SetupHardware() {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { t } = useTranslation();
  const schema = yup.object().shape({
    installedHardware: yup.string().nullable().required(t('Common.please select an option')),
  });
  const {
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<HardwareFormData>({
    resolver: yupResolver(schema),
  });

  function handleBeginHardwareConfiguration({ installedHardware }: HardwareFormData) {
    localStorage.setItem(`hasConfiguredHardware_${site.site_id}`, 'true');
    dispatch(setCurrentSite({ ...site, isOnboarding: true }));

    const redirectBackURL = `/sites/${site.site_id}/first_time_hardware_configuration`;
    const installedHardwareOptionToRoute = {
      SEM: `../account/hardware/configure/meter/start?backURL=${redirectBackURL}`,
      INVERTER: `../account/hardware/configure/inverter/start?backURL=${redirectBackURL}`,
      // If both exist, only set up the inverter Wi-Fi, because we assume the SEM is connected to the Inverter already
      // via a physical EIP connector.
      SEM_INVERTER: `../account/hardware/configure/inverter/start?backURL=${redirectBackURL}`,
      WIRING_DEVICES: `../matter-devices/add/${IS_IOS ? 'start-ios' : 'start'}?backURL=${redirectBackURL}`,
    };
    navigate(installedHardwareOptionToRoute[installedHardware]);
  }

  return (
    <SlidingAnimationPageBase includeTopNav title={t('Set Up Hardware.set up')} backURL="/sites/list">
      <Center
        onSubmit={handleFormSubmit(handleBeginHardwareConfiguration)}
        as={'form'}
        flexDirection="column"
        m={5}
        textAlign="center"
      >
        <Image mb={4} w={'40%'} src={phoneScanImg} alt="Phone scan" />

        <Heading size="lg" mt={2}>
          {t('Set Up Hardware.lets connect')}
        </Heading>
        <Text>{t('Set Up Hardware.select your devices')}</Text>

        <FormControl mt={3} isInvalid={!!errors.installedHardware}>
          <Controller
            name="installedHardware"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack spacing={3} textAlign="left">
                  <Radio data-testid="sem-option" value="SEM">
                    {t('Set Up Hardware.schneider energy monitor')}
                  </Radio>
                  <Radio data-testid="inverter-option" value="INVERTER">
                    {t('Set Up Hardware.schneider inverter')}
                  </Radio>
                  <Radio data-testid="sem-inverter-option" value="SEM_INVERTER">
                    {t('Set Up Hardware.sem + inverter')}
                  </Radio>
                  <Radio data-testid="wiring-devices-option" value="WIRING_DEVICES">
                    {t('Set Up Hardware.schneider wiring devices')}
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
          <FormErrorMessage data-testid="hardware-form-input-error">
            {errors?.installedHardware?.message}
          </FormErrorMessage>
        </FormControl>

        <ArcButton data-testid="submit-installed-hardware-form" type="submit" mt={8} w={'80%'} arcColor="#3DCD57">
          {t('Common.next')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
