import React, { useMemo } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import solarAnimationImg from '../../assets/images/solar_animation_750.gif';
import ArcButton from '../../common/components/ArcButton';
import DataSummaryCard from '../../common/components/DataSummaryCard';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import SEHomeCard from '../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { ErrorStatusIcon, SolarStatusIcon } from '../../styles/custom-icons';
import { useGetUsageQuery } from '../activity/activityApi';
import { useLiveData } from '../site/live-data/liveDataApi';
import { selectCurrentDayForSite } from '../site/siteSlice';
import { SOLAR_THRESHOLD_KW, SolarStatus } from './solar-helpers';

export function Solar() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const currentDay = useSelector(selectCurrentDayForSite);
  const { t } = useTranslation();
  const { data: usageData, isLoading: isUsageLoading } = useGetUsageQuery(currentDay, DateRangeType.Day);
  const { powerUsageData } = usageData;
  const { data: liveDataSummary, isInverterDataLoading: isLoading, isInverterError } = useLiveData();
  const { solar, hybrid_inverter: hybridInverter } = liveDataSummary;
  const solarData = hybridInverter || solar;

  const solarUsageSum = useMemo(() => {
    if (!powerUsageData.length) return 0;
    const date1 = new Date(powerUsageData[0]?.reading_datetime);
    const date2 = new Date(powerUsageData[1]?.reading_datetime);
    const intervalMins = Math.abs(date1.getTime() - date2.getTime()) / 1000 / 60;
    const intervalsPerHour = 60 / intervalMins;

    return powerUsageData.reduce((acc, interval) => acc + (interval?.solar ?? 0), 0) / intervalsPerHour;
  }, [powerUsageData]);

  const isLoaded = !isLoading && !isUsageLoading;
  const StatusIcon = isInverterError ? ErrorStatusIcon : SolarStatusIcon;

  const status: SolarStatus = useMemo(() => {
    if (isInverterError) return 'ERROR';
    if (solarData > SOLAR_THRESHOLD_KW) return 'PRODUCTION';
    return 'IDLE';
  }, [solar, isInverterError]);

  const solarStatusColors = {
    IDLE: '#9FA0A4',
    PRODUCTION: useColorModeValue('primaryBranding.600', 'primaryBranding.200'),
    ERROR: '#FF5B44',
  };
  const color = solarStatusColors[status];

  const backURL = useMemo(() => search.get('backURL') ?? `../../home?direction=back`, [search]);

  // @TODO: update bg back to #111111 when the gif background is fixed
  return (
    <SlidingAnimationPageBase backURL={backURL} title={t('Solar.solar')} _dark={{ bg: '#131313' }}>
      <Center flexDirection="column" my={8} mx={3}>
        <SkeletonCircle mx="auto" w={[275, 400]} h={[275, 400]} isLoaded={isLoaded}>
          <Box w="100%" h="100%" position={'relative'}>
            {/* Gif image rendered behind the circle */}
            {status == 'PRODUCTION' && (
              <Image
                w={[275, 400]}
                h={[275, 400]}
                position="absolute"
                src={solarAnimationImg}
                _light={{ display: 'none' }} // @TODO: Get animation for light mode
              />
            )}
            <StatusIcon w={[275, 400]} h={[275, 400]} pos="absolute" color={color} data-testid="icon" />
          </Box>
        </SkeletonCircle>

        <Skeleton isLoaded={isLoaded} mt={2} minHeight={58} minW={250}>
          <Text textAlign={'center'} fontSize={28} fontWeight={500} data-testid="status-title">
            {t(`Solar.states.${status}.text`)}
          </Text>
          {isInverterError && (
            <Text textAlign={'center'} fontSize={16} data-testid="status-subtitle" lineHeight={1}>
              {t('Solar.contact customer care')}.
            </Text>
          )}
        </Skeleton>

        <SimpleGrid gap={3} row={2} w="100%" alignItems="center">
          <SimpleGrid gap={3} pt={3} columns={2} data-testid="solar-details">
            <DataSummaryCard
              title={t('Solar.generated today')}
              isLoaded={isLoaded}
              value={Intl.NumberFormat('en', { maximumFractionDigits: 1 }).format(solarUsageSum)}
              suffix={'kWh'}
              data-testid="today-generated"
              h="100%"
            />
            <DataSummaryCard
              title={t('Common.current power')}
              isLoaded={isLoaded}
              value={Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(solarData)}
              suffix="kW"
              data-testid="current-power"
              h="100%"
            />
          </SimpleGrid>
          <SEHomeCard
            borderTopLeftRadius={5}
            borderBottomRightRadius={'25px'}
            textAlign={'center'}
            as="button"
            w="100%"
            onClick={() => {
              navigate(`../../activity?source=solar`);
            }}
            data-testid="solar-activity-button"
          >
            <Flex w="100%" py={2}>
              <Text>{t('Solar.solar activity')}</Text>
              <ChevronRightIcon ml="auto" w={6} h={6} />
            </Flex>
          </SEHomeCard>
        </SimpleGrid>

        {isInverterError && (
          <ArcButton
            arcColor={color}
            w={250}
            mt={6}
            onClick={() => navigate(`../../account/support`)}
            data-testid="contact-support-button"
          >
            {t('Common.contact support')}
          </ArcButton>
        )}
      </Center>
    </SlidingAnimationPageBase>
  );
}
