import { useMemo } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Options, SeriesAreaOptions } from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CostsData, SavingsAPIData } from 'clipsal-cortex-types/src/api';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { getChartTimezoneOffset } from '../../../utils/common/common-utils';
import { selectSite } from '../../site/siteSlice';
import { CHART_DEFAULT_OPTIONS, SERIES_DEFAULT_OPTIONS } from '../constants';
import { convertToTimezoneAwareDate, getMaxDateTime, getMinDateTime, getUIConfigForRangeType } from '../utils';

interface Props {
  isLoaded: boolean;
  selectedDateRangeType: Exclude<DateRangeType, DateRangeType.Day>;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  costData?: CostsData[];
  savingsData?: SavingsAPIData[];
  selectedDeviceAssignment?: string;
  isSelectedDeviceDisplayedInChart?: boolean;
}

export function useCostSavingChartData({
  isLoaded,
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  costData,
  savingsData,
  selectedDeviceAssignment,
  isSelectedDeviceDisplayedInChart,
}: Props) {
  const lineChartColor = useColorModeValue('#5f5f5f', '#b5b5b5');
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { timezone } = useSelector(selectSite);
  const { t } = useTranslation();

  const isCost = !!costData;

  const areaCostsData = useMemo(
    () =>
      costData?.map((interval) => ({
        x: convertToTimezoneAwareDate(interval.date, timezone).getTime(),
        y: interval.components?.length ? interval.total_cost : null,
      })) ?? [],
    [costData, timezone]
  );

  const areaCostForApplianceData = useMemo(
    () =>
      costData?.map((interval) => {
        const appliance = interval.assignments?.find((a) => a.assignment === selectedDeviceAssignment);
        return {
          x: convertToTimezoneAwareDate(interval.date, timezone).getTime(),
          y: appliance?.amount || null,
        };
      }) ?? [],
    [costData, selectedDeviceAssignment, timezone]
  );

  const areaSavingsData = useMemo(() => {
    return (
      savingsData?.map((interval) => {
        return {
          x: convertToTimezoneAwareDate(interval.date, timezone).getTime(),
          y: interval.total_saved,
        };
      }) ?? []
    );
  }, [savingsData, timezone]);

  const series = useMemo((): SeriesAreaOptions[] => {
    if (!isLoaded) return [];

    const isCostForAppliance =
      costData?.[0]?.hasOwnProperty('assignments') &&
      costData[0]?.assignments?.length &&
      isSelectedDeviceDisplayedInChart;

    const areas: SeriesAreaOptions[] = [];
    areas.push({
      ...SERIES_DEFAULT_OPTIONS,
      color: lineChartColor,
      fillColor: {
        ...SERIES_DEFAULT_OPTIONS.fillColor,
        stops: [
          [0, 'rgba(181, 181, 181, 0.3)'],
          [1, 'rgba(181, 181, 181, 0)'],
        ],
      },
      data: [{ x: 0, y: 0 }, ...(isCost ? areaCostsData : areaSavingsData)],
      connectNulls: false,
    });

    if (isCostForAppliance) {
      areas.push({
        ...SERIES_DEFAULT_OPTIONS,
        color: '#9B51E0',
        fillColor: {
          ...SERIES_DEFAULT_OPTIONS.fillColor,
          stops: [
            [0, 'rgba(155, 81, 224, 0.3)'],
            [1, 'rgba(155, 81, 224, 0)'],
          ],
        },
        data: [{ x: 0, y: 0 }, ...areaCostForApplianceData],
      });
    }
    return areas;
  }, [
    isCost,
    isLoaded,
    areaCostsData,
    areaCostForApplianceData,
    isSelectedDeviceDisplayedInChart,
    lineChartColor,
    costData,
  ]);

  return useMemo<Options>(() => {
    const startDate = rangeTypeToSelectedPeriod[selectedDateRangeType];
    const { xAxisTickFormat, tooltipDateFormat, xAxisTickInterval } = getUIConfigForRangeType(selectedDateRangeType);

    return {
      ...CHART_DEFAULT_OPTIONS,
      chart: {
        ...CHART_DEFAULT_OPTIONS.chart,
        height: '320px',
      },
      series,
      tooltip: {
        ...CHART_DEFAULT_OPTIONS.tooltip,
        valuePrefix: '$',
        valueDecimals: 2,
        xDateFormat: tooltipDateFormat,
      },
      xAxis: {
        ...CHART_DEFAULT_OPTIONS.xAxis,
        min: getMinDateTime(startDate, selectedDateRangeType, timezone),
        max: getMaxDateTime(startDate, selectedDateRangeType, timezone),
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        tickInterval: xAxisTickInterval,
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            return format(new Date(data.value ?? 0), xAxisTickFormat);
          },
        },
      },
      yAxis: {
        ...CHART_DEFAULT_OPTIONS.yAxis,
        accessibility: { enabled: true, description: isCost ? t('Activity.cost in $') : t('Activity.savings in $') },
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            data.value = Number(data.value);
            if (data.value >= 0) return `$${data.value.toFixed(2)}`;
            else {
              return `-$${(data.value * -1).toFixed(2)}`;
            }
          },
        },
      },
      time: {
        getTimezoneOffset: (timestamp: number) => getChartTimezoneOffset(timestamp, timezone),
      },
    };
  }, [series, t, markerColor, selectedDateRangeType, rangeTypeToSelectedPeriod, timezone, isCost]);
}
