import { useSelector } from 'react-redux';

import { Invitation } from 'clipsal-cortex-types/src/api/api-invitation';

import { baseApi } from '../../app/services/baseApi';
import { selectSite } from './siteSlice';

export type InvitationPost = { siteId: number; email: string };

export const invitationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvitations: builder.query<Invitation[], { siteId: number }>({
      query: ({ siteId }) => `/v1/sites/${siteId}/invitations`,
      providesTags: ['Invitations'],
    }),
    createInvitation: builder.mutation<Invitation, InvitationPost>({
      query: ({ siteId, email }) => {
        return {
          url: `/v1/sites/${siteId}/invitations`,
          method: 'POST',
          body: { user_email: email, clipsal_solar_id: siteId },
        };
      },
      invalidatesTags: ['Invitations'],
    }),
  }),
});

export const { useGetInvitationsQuery: useGetOriginalGetInvitationsQuery, useCreateInvitationMutation } =
  invitationsApi;

/**
 * Provides some sensible default values for the invitations query.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetInvitationsQuery() {
  const { site_id: siteId } = useSelector(selectSite);
  const result = useGetOriginalGetInvitationsQuery({
    siteId,
  });
  return {
    ...result,
    data: result?.data,
  };
}
