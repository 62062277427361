import '@fontsource-variable/nunito';
import '@fontsource-variable/noto-sans';

import { DarkMode, DarkModeGetterResult } from '@aparajita/capacitor-dark-mode';
import { extendTheme, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import {
  DESKTOP_LARGE_WIDTH_BREAKPOINT,
  DESKTOP_WIDTH_BREAKPOINT,
  MOBILE_WIDTH_BREAKPOINT,
  TABLET_WIDTH_BREAKPOINT,
} from '../common/hooks/use-viewport-type';
import { radioTheme } from './custom-chakra-radio';
import { skeletonTheme } from './custom-chakra-skeleton';
import { sliderTheme } from './custom-chakra-slider';
import { switchTheme } from './custom-chakra-switch';

export const colors = {
  ...theme.colors,
  primaryBranding: {
    // #3DCD58 follows SE branding guidelines, #0FB542 is a darker shade suitable for light mode
    50: '#3DCD58',
    100: '#3DCD58',
    200: '#3DCD58',
    300: '#3DCD58',
    400: '#3DCD58',
    500: '#3DCD58',
    600: '#0FB542',
    700: '#0FB542',
    800: '#0FB542',
    900: '#0FB542',
  },
  primaryBrandingStatic: {
    50: '#3DCD58',
    100: '#3DCD58',
    200: '#3DCD58',
    300: '#3DCD58',
    400: '#3DCD58',
    500: '#3DCD58',
    600: '#3DCD58',
    700: '#3DCD58',
    800: '#3DCD58',
    900: '#3DCD58',
  },
  solarGreenStatic: {
    50: '#27AE60',
    100: '#27AE60',
    200: '#27AE60',
    300: '#27AE60',
    400: '#27AE60',
    500: '#27AE60',
    600: '#27AE60',
    700: '#27AE60',
    800: '#27AE60',
    900: '#27AE60',
  },
  // #42B4E6 follows SE branding guidelines, #1989bb is a darker shade suitable for light mode
  schneiderSkyBlue: {
    50: '#42B4E6',
    100: '#42B4E6',
    200: '#42B4E6',
    300: '#42B4E6',
    400: '#42B4E6',
    500: '#42B4E6',
    600: '#1989bb',
    700: '#1989bb',
    800: '#1989bb',
    900: '#1989bb',
  },
  // #626469 (500) and #9FA0A4 (400) follow the SE branding guidelines
  customGrey: {
    100: '#f7f8f8',
    200: '#edeeee',
    300: '#c6c7c9',
    400: '#9FA0A4',
    500: '#626469',
    600: '#56575c',
    700: '#494b4f',
    800: '#363E40',
    900: '#27282A',
  },
  devicePurpleStatic: {
    50: '#9B51E0',
    100: '#9B51E0',
    200: '#9B51E0',
    300: '#9B51E0',
    400: '#9B51E0',
    500: '#9B51E0',
    600: '#9B51E0',
    700: '#9B51E0',
    800: '#9B51E0',
    900: '#9B51E0',
  },
  gridRedStatic: {
    50: '#DB5E52',
    100: '#DB5E52',
    200: '#DB5E52',
    300: '#DB5E52',
    400: '#DB5E52',
    500: '#DB5E52',
    600: '#DB5E52',
    700: '#DB5E52',
    800: '#DB5E52',
    900: '#DB5E52',
  },
  generatorOrangeStatic: {
    50: '#F2C94C',
    100: '#F2C94C',
    200: '#F2C94C',
    300: '#F2C94C',
    400: '#F2C94C',
    500: '#F2C94C',
    600: '#F2C94C',
    700: '#F2C94C',
    800: '#F2C94C',
    900: '#F2C94C',
  },
};

const breakpoints = {
  base: `${MOBILE_WIDTH_BREAKPOINT}px`,
  sm: `${TABLET_WIDTH_BREAKPOINT}px`,
  md: `${DESKTOP_WIDTH_BREAKPOINT}px`,
  lg: `${DESKTOP_LARGE_WIDTH_BREAKPOINT}px`,
  xl: `${DESKTOP_LARGE_WIDTH_BREAKPOINT}px`,
};

// @TODO: use system color mode once all colour modes are aligned
const config = {
  initialColorMode: 'dark',
};

const styles = {
  global: (props: any) => ({
    'html, body': {
      bg: mode('#F7F7F7', '#111111')(props),
    },
  }),
};

export const getTheme = () => {
  const fonts = `'Nunito', 'Noto Sans', sans-serif`;
  DarkMode.configure({
    getter: () => {
      const respect = window.localStorage.getItem('shiftColorModeAutomatically') !== 'false';
      return (respect ? 'system' : 'light') as DarkModeGetterResult;
    },
  });

  return extendTheme({
    config,
    colors,
    breakpoints,
    styles,
    fonts: {
      heading: fonts,
      body: fonts,
      mono: fonts,
    },
    components: { Radio: radioTheme, Skeleton: skeletonTheme, Switch: switchTheme, Slider: sliderTheme },
  });
};
