import React, { useState } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';
import TimePicker from 'clipsal-cortex-ui/src/components/time-picker/TimePicker';

import { useReduxDispatch } from '../../../../app/store';
import { selectSchedules, setSchedulerState } from './schedulerSlice';

type Props = { scheduleIndex: number } & DialogProps;

type TimeSelectState = {
  hours: number;
  minutes: number;
  amOrPm: 'AM' | 'PM';
};

export default function TimeSelectDrawer({ onClose, isOpen, scheduleIndex }: Props) {
  const dispatch = useReduxDispatch();
  const { schedules, timeEditType } = useSelector(selectSchedules);
  const selectedSchedule = schedules[scheduleIndex];
  const initialState = getInitialState();
  const [state, setState] = useState<TimeSelectState>(initialState);
  const { hours, minutes, amOrPm } = state;
  const timePickerGradientColor = useColorModeValue('253, 253, 253', '41, 49, 51');
  const timePickerBorderColor = useColorModeValue('#293133', '#fff');
  const bgColor = useColorModeValue('#fff', '#293133');
  const titleColor = useColorModeValue('customGrey.700', 'customGrey.400');
  const buttonTextColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const { t } = useTranslation();

  function getInitialState() {
    const selectedSubType = timeEditType === 'start' ? selectedSchedule.startSubType : selectedSchedule.endSubType;
    const selectedTime = timeEditType === 'start' ? selectedSchedule.startTime : selectedSchedule.endTime;

    if (!selectedTime || selectedSubType) {
      return {
        hours: 12,
        minutes: 0,
        amOrPm: 'AM',
      } as TimeSelectState;
    }

    // Convert saved schedule time back to 12 hour format
    const [hoursFromSchedule, minutesFromSchedule] = selectedTime.split(':');
    let hoursDefaultValue = Number(hoursFromSchedule) > 12 ? Number(hoursFromSchedule) - 12 : Number(hoursFromSchedule);
    if (hoursDefaultValue === 0) hoursDefaultValue = 12;
    return {
      hours: hoursDefaultValue,
      minutes: Number(minutesFromSchedule),
      amOrPm: Number(hoursFromSchedule) >= 12 ? 'PM' : 'AM',
    } as TimeSelectState;
  }

  function handleSave() {
    // Reformat the time from UI to match the API (ie. 7:00 -> 07:00:00) to facilitate validation checking
    const minutesPadded = minutes < 9 ? `0${minutes}` : minutes;
    const hours24hTime: number | string = amOrPm === 'PM' && hours !== 12 ? hours + 12 : hours;

    let hoursPadded = hours24hTime < 9 ? `0${hours24hTime}` : hours24hTime;
    if (hours === 12 && amOrPm === 'AM') {
      hoursPadded = '00';
    }
    const clonedSchedules = cloneDeep(schedules);
    const schedule = clonedSchedules[scheduleIndex];
    schedule[`${timeEditType!}Time`] = `${hoursPadded}:${minutesPadded}:00`;
    schedule[`${timeEditType!}SubType`] = ''; // Reset schedule sub type if it exists
    dispatch(
      setSchedulerState({
        hasUnsavedChanges: false,
        schedules: clonedSchedules,
        timeEditType: null,
      })
    );

    onClose();
  }

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent data-testid="time-select-drawer" bg={'transparent'}>
        <DrawerBody px={1}>
          <Box borderRadius={'15px'} bg={bgColor}>
            <Center borderBottom={'1px solid'} borderColor={'customGrey.500'} py={3}>
              <Text textAlign={'center'} color={titleColor} fontSize={'sm'}>
                {t('Devices.time select')}
              </Text>
            </Center>

            <Center as={'button'} w={'100%'} borderBottom={'1px solid'} borderColor={'customGrey.500'} py={3}>
              <TimePicker
                sliderColumnWidth={'75px'}
                defaultHours={initialState.hours}
                defaultMinutes={initialState.minutes}
                defaultAmOrPm={amOrPm}
                onChange={(value) => {
                  setState((prevState) => {
                    return { ...prevState, ...value };
                  });
                }}
                gradientColor={timePickerGradientColor}
                borderColor={timePickerBorderColor}
              />
            </Center>

            <Center
              data-testid="save-time-select-drawer"
              as={'button'}
              w={'100%'}
              py={3}
              borderRadius={'15px'}
              bg={bgColor}
              onClick={handleSave}
            >
              <Text fontWeight={600} color={buttonTextColor}>
                {t('Common.confirm')}
              </Text>
            </Center>
          </Box>

          <Center
            data-testid="close-time-select-drawer"
            as={'button'}
            w={'100%'}
            onClick={onClose}
            py={3}
            mt={3}
            borderRadius={'15px'}
            bg={bgColor}
          >
            <Text color={buttonTextColor}>{t('Common.close')}</Text>
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
