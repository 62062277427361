import React, { useEffect, useState } from 'react';
import { isInteger } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { mapTariffApiToForm } from './tariff-helpers';
import { useTariffs } from './tariffApi';
import { useTariffFormContext } from './useTariffFormContext';

export function TariffSetupRoutes() {
  const { getValues, reset } = useTariffFormContext();
  const navigate = useNavigate();
  const { tariffId } = useParams<{ tariffId: string }>();
  const { isLoading, tariffs } = useTariffs();
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // once we have tariffs, we can check if we need to redirect
    if (!isLoading) {
      const { id } = getValues();
      const paramTariffId = Number(tariffId);
      const isValidTariffId = isInteger(paramTariffId);

      // When direct url is passed, update the form with the tariff details
      if (isValidTariffId && id !== paramTariffId) {
        const tariff = tariffs.find((t) => t.id === Number(paramTariffId));
        if (tariff) reset(mapTariffApiToForm(tariff));

        // if somehow tariff id is messed up, redirect to home
      } else if (!isValidTariffId && tariffId !== 'new') {
        navigate(`../home`);
      } else if (tariffId === 'new') {
        // return to home page for new tariffs
        navigate(`home?direction=back`);
      }

      setIsLoaded(true);
    }
  }, [isLoading]);

  return !isLoaded || isLoading ? <CenteredLoader text={`${t('Common.loading')}...`} minHeight={'50vh'} /> : <Outlet />;
}
