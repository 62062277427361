import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { Appliance } from 'clipsal-cortex-types/src/api/api-appliances';
import { CostsData } from 'clipsal-cortex-types/src/api/api-costs';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { baseApi } from '../../app/services/baseApi';
import { get } from '../../common/api/api-helpers';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { useGetDevicesQuery } from '../devices/devicesApi';
import { selectSite } from './siteSlice';

type CostsQueryParams = {
  siteId: number;
  devices: Appliance[];
  selectedDateRangeType: DateRangeType;
  selectedDate: Date;
};

export type DeviceWithCost = Appliance & { cost: number };
type CostApiState = {
  devices: DeviceWithCost[];
  costData: CostsData[];
};

export const costsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCosts: builder.query<CostApiState, CostsQueryParams>({
      queryFn: async ({ siteId, devices, selectedDate, selectedDateRangeType }) => {
        let groupBy: 'day' | 'month' | null = null;
        let startDateFormatted = '';
        let endDateFormatted = '';

        if (selectedDateRangeType === DateRangeType.Week) {
          const firstDayOfWeek = getFirstDayOfWeek(selectedDate);
          const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
          const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 7));

          startDateFormatted = format(firstDayOfWeek, 'yyyy-MM-dd');
          endDateFormatted = format(lastDayOfWeek, 'yyyy-MM-dd');
          groupBy = 'day';
        } else if (selectedDateRangeType === DateRangeType.Month) {
          const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
          const firstDayOfNextMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
          startDateFormatted = format(firstDayOfMonth, 'yyyy-MM-dd');
          endDateFormatted = format(firstDayOfNextMonth, 'yyyy-MM-dd');
          groupBy = 'day';
        } else {
          startDateFormatted = `${selectedDate.getFullYear()}-01-01`;
          endDateFormatted = `${selectedDate.getFullYear() + 1}-01-01`;
          groupBy = 'month';
        }

        if (selectedDateRangeType === DateRangeType.Day) {
          // Use local timezone for fetching cost data
          const localDate = format(selectedDate, 'yyyy-MM-dd');
          const costData = await get<CostsData[]>(
            `/v1/sites/${siteId}/costs?start_date=${localDate}&end_date=${localDate}&groupby=day`
          );
          return {
            data: {
              devices: combineApplianceCostSummary(devices, costData),
              costData,
            },
          };
        } else {
          const costData = await get<CostsData[]>(
            `/v1/sites/${siteId}/costs?start_date=${startDateFormatted}` +
              `&end_date=${endDateFormatted}&groupby=${groupBy}`
          );

          return {
            data: {
              devices: combineApplianceCostSummary(devices, costData),
              costData,
            },
          };
        }
      },
      providesTags: ['Costs'],
    }),
  }),
});

function combineApplianceCostSummary(devices: Appliance[], costData: CostsData[]): DeviceWithCost[] {
  const assignmentToCost = new Map<string, number>();

  costData.forEach((costEntry) => {
    costEntry.assignments?.forEach((interval) => {
      const currentCost = assignmentToCost.get(interval.assignment) || 0;
      assignmentToCost.set(interval.assignment, currentCost + interval.amount);
    });
  });

  return devices
    .map((device) => ({
      ...device,
      cost: assignmentToCost.get(device.assignment) ?? 0,
    }))
    .sort((a, b) => (a.cost > b.cost ? -1 : 1));
}

export const { useGetCostsQuery: useGetOriginalCostsQuery } = costsApi;

/**
 * Provides some sensible default values for the cost query.
 *
 * @param selectedDate - The currently selected date in the UI. Note, this is the _local_ date of the site.
 * @param selectedDateRangeType - The currently selected date range type.
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetCostsQuery(selectedDate: Date, selectedDateRangeType: DateRangeType) {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: devices, isLoading: isDevicesLoading } = useGetDevicesQuery();
  selectedDate.setHours(0, 0, 0, 0); // Ensure we're using the start of the day for better caching

  const result = useGetOriginalCostsQuery(
    {
      siteId,
      selectedDate,
      devices,
      selectedDateRangeType,
    },
    { skip: isDevicesLoading }
  );

  return {
    ...result,
    isLoading: isDevicesLoading || result.isLoading,
    data: {
      ...result.data,
      costData: result?.data?.costData ?? [],
      devices: result?.data?.devices ?? [],
    },
  };
}
