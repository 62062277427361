import { useSelector } from 'react-redux';

import {
  MANUFACTURER_ID_AYLA,
  MANUFACTURER_ID_SATURN,
  MANUFACTURER_ID_SEM,
} from '../../features/devices/devices-helper';
import { useGetSwitchesByManufacturerQuery } from '../../features/devices/switches/switchApi';
import { selectInverters, selectMeters } from '../../features/site/siteSlice';

type HardwareConfigurationType = 'default' | 'sense' | 'matter' | 'none';

export function useSiteHardwareConfigurationType(skip = false): {
  hardwareConfigurationType: HardwareConfigurationType;
  isLoading: boolean;
} {
  const siteInverters = useSelector(selectInverters);
  const siteMeters = useSelector(selectMeters);
  const { data: siteAylaSwitches, isLoading } = useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA, skip);
  const siteHasSenseMeter = siteMeters.some((meter) => [MANUFACTURER_ID_SEM].includes(meter.manufacturer_id));
  const siteHasSaturnInverter = siteInverters.some((inverter) =>
    [MANUFACTURER_ID_SATURN].includes(inverter.manufacturer_id)
  );
  const siteHasAylaSwitches = siteAylaSwitches?.length;

  let hardwareConfigurationType: HardwareConfigurationType = 'none';

  if (siteHasSenseMeter && !siteHasSaturnInverter) {
    hardwareConfigurationType = 'sense';
  } else if (siteHasSaturnInverter) {
    hardwareConfigurationType = 'default';
  } else if (siteHasAylaSwitches) {
    hardwareConfigurationType = 'matter';
  }

  return {
    hardwareConfigurationType,
    isLoading,
  };
}
