import React, { useMemo } from 'react';
import { Center, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import inverterOnImg from '../../../../../assets/images/inverter_on.svg';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';

export function InverterPowerUpInstructions() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { t } = useTranslation();

  const backURL = useMemo(() => {
    const deviceId = search.get('deviceId');
    // If we have a deviceId, we are coming from the inverter details page
    return deviceId ? `../../../${deviceId}/view?direction=back` : `../../../configure/inverter/start?direction=back`;
  }, [search]);

  return (
    <SlidingAnimationPageBase title={t('Common.device setup')} backURL={backURL}>
      <Center flexDirection="column" px={3} mt={5} textAlign="center">
        <Image w={'70%'} my={4} src={inverterOnImg} alt="Inverter sketch" />

        <Heading mt={2}>{t('Set Up Hardware.activate wifi ap')}</Heading>
        <Flex flexDir="column">
          <Text mt={1}>{t('Set Up Hardware.press inverter button')}</Text>
          <Text fontWeight={600} color="#3DCD58">
            {t('Set Up Hardware.two times')}
          </Text>
          <Text>{t('Set Up Hardware.to activate the inverter')}</Text>
        </Flex>
        <Text mt={1}>{t('Set Up Hardware.needed to connect inverter to wifi')}</Text>

        <ArcButton
          data-testid={'inverter-connect'}
          onClick={() => {
            navigate(`../scan?${search.toString() || ''}`);
          }}
          mt={8}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Common.connect')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
