import React, { useEffect, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { Button, CircularProgress, Flex, Image, Text } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import loggedOutImg from '../../../assets/images/logged_out.svg';
import { COMPONENT_MIN_HEIGHT, IS_IOS, IS_NATIVE } from '../../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../../env-type';
import { REGION_SHORTHAND } from '../../../region-type';
import { getRedirectURI } from '../auth-helpers';
import styles from './Logout.module.scss';

const IDENTITY_URL =
  ENV_TYPE_SHORTHAND === 'STAGING'
    ? 'https://se--uatbfo22.sandbox.my.site.com'
    : 'https://secureidentity.schneider-electric.com';
const HOSTED_UI_DOMAIN = ENV_TYPE_SHORTHAND === 'STAGING' ? 'clippy-staging' : 'clippy-prod';
// Uncomment this when working in preview environments
// const HOSTED_UI_DOMAIN = ENV_TYPE === 'DEV' ? 'clippy-preview' : 'clipsalsolar-prod';

export function Logout() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function logOut() {
      const idpLogoutURL = encodeURIComponent(
        `${IDENTITY_URL}/identity/IDMS_IDPSloInit?RelayState=${getRedirectURI(true)}&app=SchneiderHome`
      );
      await Browser.open({
        url:
          `https://${HOSTED_UI_DOMAIN}.auth.ap-southeast-2.amazoncognito.com/logout` +
          `?client_id=${
            import.meta.env?.[`VITE_${REGION_SHORTHAND}_${ENV_TYPE_SHORTHAND}_CLIENT_ID`]
          }&logout_uri=${idpLogoutURL}`,
        windowName: IS_NATIVE ? '_self' : undefined,
      });

      await Auth.signOut();
      dispatch({ type: 'store/reset' });

      setLoaded(true);

      // @FIXME: this is super hacky, but it's not clear why the redirect isn't working on iOS
      if (IS_IOS) {
        setTimeout(() => {
          Browser.close();
        }, 5000);
      }
    }

    logOut();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setShouldRedirect(true);
    }, 3000);
  });

  useEffect(() => {
    if (isLoaded && shouldRedirect) navigate('/');
  }, [isLoaded, shouldRedirect]);

  return isLoaded ? (
    <>
      <div className={styles.container} id="logged-out-card">
        <div className={styles.logoutContainer}>
          <Image mb={4} w="60%" src={loggedOutImg} alt="Page not found" />
          <Text mb={2} fontSize="md">
            {t('Account.you have logged out')}
          </Text>
          <Button variant="outline">
            <Link to="/login">{t('Account.click here to log in')}</Link>
          </Button>
        </div>
      </div>
    </>
  ) : (
    <Flex direction="column" height={COMPONENT_MIN_HEIGHT} align="center" justify="center">
      <Text fontSize="lg" mb={2}>
        {t('Common.loading')}
      </Text>
      <CircularProgress isIndeterminate color="green" />
    </Flex>
  );
}
