import { ComponentWithAs, IconProps } from '@chakra-ui/react';

import {
  SEHomeBackupControlSwitchIcon,
  SEHomeBatteryIcon,
  SEHomeEVIcon,
  SEHomeInverterIcon,
  SEHomeMeterIcon,
} from '../../../styles/custom-icons';
import { VisibleHardwareType } from './types';

export const HARDWARE_TYPE_TO_UI_CONFIG: Record<
  VisibleHardwareType,
  { icon: ComponentWithAs<'svg', IconProps>; title: string }
> = {
  INVERTER: { icon: SEHomeInverterIcon, title: 'Inverter' },
  BATTERY_PACK: { icon: SEHomeBatteryIcon, title: 'Battery' },
  EV_CHARGER: { icon: SEHomeEVIcon, title: 'EV Charger' },
  METER: { icon: SEHomeMeterIcon, title: 'Energy Monitor' },
  BACKUP_GRID_CONTROL_SYSTEM: { icon: SEHomeBackupControlSwitchIcon, title: 'Backup Controller' },
};
