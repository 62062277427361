import { useSelector } from 'react-redux';

import { IS_DEMO_LOGIN } from '../../common/constants';
import { MANUFACTURER_ID_AYLA, MANUFACTURER_ID_SATURN } from '../devices/devices-helper';
import { useGetSwitchesByManufacturerQuery } from '../devices/switches/switchApi';
import { useGetSiteConsentByTypeQuery } from '../site/new-user-journey/consentApi';
import { selectInverters, selectSite } from '../site/siteSlice';
import { selectUser } from '../user/userSlice';

const useOnboardingWizardProgress = () => {
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const siteInverters = useSelector(selectInverters);
  const isSystemOwner = user.role === 'SYSTEM_OWNER';

  const { data: shareDataWithInstallerConsent, isLoading: isSiteConsentsLoading } =
    useGetSiteConsentByTypeQuery('SHARE_WITH_INSTALLER');

  const siteHasSaturnInverter = siteInverters.some((device) =>
    [MANUFACTURER_ID_SATURN].includes(device.manufacturer_id)
  );

  const { data: aylaSwitchesFromApi, isLoading: isSwitchesLoading } =
    useGetSwitchesByManufacturerQuery(MANUFACTURER_ID_AYLA);

  const siteDoesntHaveMatterDevices = aylaSwitchesFromApi && !aylaSwitchesFromApi?.length;

  return {
    needsSiteAddress: !site.address,

    // This consent needs to be provided by the user if they are the system owner and the site has not yet provided it
    needsToProvideInstallerConsent: isSystemOwner && !shareDataWithInstallerConsent,

    // At least one device is required to be configured
    needsToConfigureHardware: !IS_DEMO_LOGIN && !site.devices.length && siteDoesntHaveMatterDevices,

    // This will be used only when moving from the configure hardware page to the tariffs page
    needsToVisitTariffs: !IS_DEMO_LOGIN && site.isOnboarding,

    // Only show this if the site has a Saturn inverter and user is moving from tariffs page to energy modes page
    needsToVisitEnergyModes: !IS_DEMO_LOGIN && site.isOnboarding && siteHasSaturnInverter,

    isLoaded: !isSiteConsentsLoading && !isSwitchesLoading,
  };
};

export default useOnboardingWizardProgress;
