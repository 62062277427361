import React, { useEffect, useState } from 'react';
import { Box, Center, Flex, Grid, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Notification } from 'clipsal-cortex-types/src/api/api-notifications';

import OptionSelectAlertDrawer from '../../common/components/OptionSelectAlertDrawer';
import SwipeableSEHomeCard from '../../common/components/SwipeableSEHomeCard';
import { IS_DEMO_LOGIN } from '../../common/constants';
import { ArchiveIcon, DeleteIcon } from '../../styles/custom-icons';
import { selectSite } from '../site/siteSlice';
import { useGetNotificationConfigForType } from './notifications-helper';
import { useDeleteNotificationByIdMutation, useUpdateNotificationByIdMutation } from './notificationsApi';
import NotificationTimestamp from './NotificationTimestamp';

type Props = {
  notification: Notification;
  // Archived notifications can only be deleted (not archived again)
  isArchived?: boolean;
  onDelete?: (notificationId: string) => void;
};

// @TODO: fix weird swipe behaviour when scrolling
export default function NotificationListItem({ notification, isArchived = false, onDelete }: Props) {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true, position: 'top' });
  const { icon, title } = useGetNotificationConfigForType(notification.notification_type);
  const {
    isOpen: isDeleteConfirmDialogOpen,
    onClose: onCloseDeleteConfirmDialog,
    onOpen: onOpenDeleteConfirmDialog,
  } = useDisclosure();
  const {
    isOpen: isArchiveConfirmDialogOpen,
    onClose: onCloseArchiveConfirmDialog,
    onOpen: onOpenArchiveConfirmDialog,
  } = useDisclosure();
  const [updateNotification] = useUpdateNotificationByIdMutation();
  const [deleteNotification] = useDeleteNotificationByIdMutation();
  const [shouldForceResetSlide, setShouldForceResetSlide] = useState(false);
  const borderColor = useColorModeValue('primaryBranding.600', 'primaryBranding.200');
  const { t } = useTranslation();

  useEffect(() => {
    // Instantly reset when enabled. Acts as a pseudo-event emitter.
    if (shouldForceResetSlide) setShouldForceResetSlide(false);
  }, [shouldForceResetSlide]);

  async function handleDeleteNotification() {
    try {
      await deleteNotification(notification.notification_id).unwrap();
      setShouldForceResetSlide(true);
      if (onDelete) onDelete(notification.notification_id);
    } catch {
      toast({
        title: t('Notifications.error deleting notification'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
    onCloseDeleteConfirmDialog();
  }

  async function handleArchiveNotification() {
    try {
      await updateNotification({ notificationId: notification.notification_id, body: { archived: true } }).unwrap();
      setShouldForceResetSlide(true);
    } catch {
      toast({
        title: t('Notifications.error archiving notification'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
    onCloseArchiveConfirmDialog();
  }

  return (
    <>
      <SwipeableSEHomeCard
        disableSlide={IS_DEMO_LOGIN}
        containerProps={{
          'data-testid': 'notification-list-item-container',
        }}
        shouldForceResetSlide={shouldForceResetSlide}
        data-testid="notification-list-item"
        w={'100%'}
        onClick={() =>
          navigate(`/sites/${site.site_id}/notifications/${notification.notification_id}/view?direction=forward`, {
            state: { prevPath: window.location.pathname + `?archived=${isArchived}&direction=back` },
          })
        }
        pl={0}
        borderTopLeftRadius="5px"
        mb={2}
        borderLeft={!notification.read ? '8px solid' : undefined}
        borderColor={borderColor}
        primaryButtonProps={{
          onClick: onOpenDeleteConfirmDialog,
          'data-testid': 'delete-notification-btn',
          children: (
            <>
              <DeleteIcon w={8} h={8} />
              <Text mt={1} fontSize="xs">
                {t('Common.delete').toUpperCase()}
              </Text>
            </>
          ),
        }}
        secondaryButtonProps={
          !isArchived
            ? {
                onClick: onOpenArchiveConfirmDialog,
                'data-testid': 'archive-notification-btn',
                children: (
                  <>
                    <ArchiveIcon w={8} h={8} />
                    <Text mt={1} fontSize="xs">
                      {t('Notifications.archive').toUpperCase()}
                    </Text>
                  </>
                ),
              }
            : undefined
        }
      >
        <Grid templateColumns={'75px 1fr'}>
          <Center>{icon}</Center>
          <Box>
            <Grid templateColumns={'0.7fr 0.3fr'}>
              <Box>
                <Text fontSize="sm" fontWeight="bold">
                  {title}
                </Text>
                <Text fontSize="sm">{notification.short_content}</Text>
              </Box>
              <Flex flexDirection="column">
                <NotificationTimestamp notification={notification} />
              </Flex>
            </Grid>
          </Box>
        </Grid>
      </SwipeableSEHomeCard>

      <OptionSelectAlertDrawer
        title={`${t('Notifications.this is permanent')} ${t('Common.are you sure?')}`}
        options={[{ label: t('Common.delete'), value: 'DELETE' }]}
        onSelectOption={handleDeleteNotification}
        isOpen={isDeleteConfirmDialogOpen}
        onClose={onCloseDeleteConfirmDialog}
      />

      <OptionSelectAlertDrawer
        title={`${t('Notifications.this is permanent')} ${t('Common.are you sure?')}`}
        options={[{ label: t('Notifications.archive'), value: 'ARCHIVE' }]}
        onSelectOption={handleArchiveNotification}
        isOpen={isArchiveConfirmDialogOpen}
        onClose={onCloseArchiveConfirmDialog}
      />
    </>
  );
}
