import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectSites } from '../sites/sitesSlice';
import { selectUser } from '../user/userSlice';

export function SiteRedirect() {
  const navigate = useNavigate();
  const { sites } = useSelector(selectSites);
  const { isLoggedIn } = useSelector(selectUser);

  useEffect(() => {
    if (isLoggedIn) {
      if (sites.length === 1) {
        navigate(`/sites/${sites[0].site_id}/home`, { replace: true });
      } else if (!sites.length) {
        navigate('/sites/create', { replace: true });
      } else {
        navigate('/sites', { replace: true });
      }
    } else {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn, sites, navigate]);

  return null;
}
