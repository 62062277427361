import React from 'react';

import { useSiteHardwareConfigurationType } from '../../common/hooks/use-site-hardware-configuration-type';
import { DefaultActivity } from './default/DefaultActivity';
import { MatterOnlyActivity } from './matter-only/MatterOnlyActivity';
import { SenseOnlyActivity } from './sense-only/SenseOnlyActivity';

export function Activity() {
  const { hardwareConfigurationType } = useSiteHardwareConfigurationType();

  return (
    {
      sense: <SenseOnlyActivity />,
      default: <DefaultActivity />,
      matter: <MatterOnlyActivity />,
      none: null,
    }[hardwareConfigurationType] ?? null
  );
}
