import React from 'react';
import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import { MAX_TARIFF_PAGE_WIDTH, useTranslatedDefaultTariffData } from './tariff-constants';
import { useTranslatedTariffSchema } from './tariff-schema';
import { TariffData } from './tariff-types';

export function TariffRoutes() {
  const DEFAULT_TARIFF_DATA = useTranslatedDefaultTariffData();
  const methods = useForm<TariffData>({
    resolver: yupResolver(useTranslatedTariffSchema()),
    defaultValues: DEFAULT_TARIFF_DATA,
  });

  return (
    <Box width={MAX_TARIFF_PAGE_WIDTH} maxWidth={MAX_TARIFF_PAGE_WIDTH} overflowX={'hidden'}>
      <FormProvider {...methods}>
        <Outlet />
      </FormProvider>
    </Box>
  );
}
