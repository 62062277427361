import React, { useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { EyeIcon, EyeSlashIcon } from 'clipsal-cortex-icons/src/custom-icons';

import { useReduxDispatch } from '../../../app/store';
import ArcButton from '../../../common/components/ArcButton';
import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import { selectEvChargerData } from '../dashboard/evChargerSlice';
import { WifiConfigAlertDialogModalDualButton } from './WifiConfigAlertModal';
import { addBearerToken, selectBearerToken } from './wifiConfigSlice';

const createPinSchema = yup.object().shape({
  newPin: yup
    .number()
    .typeError('New PIN must be a number')
    .required('New PIN is a required field')
    .test('min', 'New PIN must be at least 6 digits long', (val) => !!val && val.toString().length >= 6)
    .test('max', 'New PIN must be at most 16 digits long', (val) => !!val && val.toString().length <= 16),
  confirmPin: yup
    .number()
    .typeError('Confirm PIN must be a number')
    .required('Confirm Pin is a required field')
    .test('min', 'Confirm PIN must be at least 6 digits long', (val) => !!val && val.toString().length >= 6)
    .test('max', 'Confirm PIN must be at most 16 digits long', (val) => !!val && val.toString().length <= 16),
});

type Formvalues = { newPin: string; confirmPin: string };

/* istanbul ignore next -- @preserve */
export const CreateResetPin = ({ isCreateMode }: { isCreateMode: boolean }) => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    trigger,
    formState: { errors },
  } = useForm<Formvalues>({
    resolver: yupResolver(createPinSchema),
    defaultValues: { newPin: '', confirmPin: '' },
  });
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bearerToken = useSelector(selectBearerToken);
  const [isPinVisible, setIsPinVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const charger = useSelector(selectEvChargerData);
  const dispatch = useReduxDispatch();
  const VisibleIcon = isPinVisible ? EyeSlashIcon : EyeIcon;
  const handleVisibleIconClick = () => setIsPinVisible(!isPinVisible);
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  const { placeHolderColor, inputBackground } = useColorModeValue(
    { placeHolderColor: '#0F0F0F', inputBackground: '#FFFFFF' },
    { placeHolderColor: '#F7F7F7', inputBackground: '#293133' }
  );

  const commonInputProps = {
    placeholder: 'From 6 to 16 digits',
    borderColor: '#9FA0A4',
    background: inputBackground,
    py: 6,
    inputMode: 'numeric' as const,
    type: isPinVisible ? 'number' : 'password',
    _placeholder: {
      color: placeHolderColor,
    },
  };

  const getLoginToken = async (password: string) => {
    // login to get token
    const { data } = await HTTP.post(
      'https://192.168.89.136/evse/v1/login/login',
      {
        username: 'SEhome',
        password,
      },
      { 'Content-type': 'application/json' }
    );
    const parsedData = JSON.parse(data);
    return parsedData.token as string;
  };

  const handleUpdatePin = async (newPin: string, token: string | null) => {
    await HTTP.put(
      'https://192.168.89.136/evse/v1/login/updatepassword',
      {
        oldpassword: '123456',
        newpassword: newPin,
      },
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        Host: '192.168.89.136',
      }
    );
  };

  const handleCreatePin = async (values: Formvalues) => {
    setIsLoading(true);
    try {
      await handleUpdatePin(values.newPin.toString(), bearerToken);
      navigate("../enter-pin'");
    } catch (error) {
      toast({
        title: 'Something went wrong creating a new PIN',
        description: 'Please make sure you use a complex PIN, e.g. 123.. or 654.. will not be accepted.',
        status: 'error',
      });
    }
    setIsLoading(false);
  };

  const handleResetPin = async (values: Formvalues) => {
    setIsLoading(true);

    try {
      // reset pincode
      await HTTP.post(
        'https://192.168.89.136/evse/v1/evsemanager/methods/resetpincode',
        { UUID: charger.chargerId },
        { 'Content-type': 'application/json' }
      );

      // login with default pincode and add the new pin
      const token = await getLoginToken('123456');
      await handleUpdatePin(values.newPin.toString(), token);

      // login to get new token with new pin
      const newToken = await getLoginToken(values.newPin.toString());
      dispatch(addBearerToken(newToken));

      setIsLoading(false);
      toast({
        title: 'PIN has been successfully reset!',
        status: 'success',
      });
      navigate('../add-network');
    } catch (error) {
      toast({
        title: 'Something went wrong while resetting PIN',
        description: 'Please make sure you are connected to charger network!',
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <Flex
      bgColor={bgColor}
      direction={'column'}
      align="center"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title={`${isCreateMode ? 'Create' : 'Reset'} PIN`} backURL="../home" />
      <Box px={4} mt={16}>
        <Text>
          This PIN allows you to login to your charger's Wi-Fi direct access point and change some of your charger's
          settings.
        </Text>
        <Text my={4}>Please save this PIN to login to your charger's Wi-Fi direct access point in the future.</Text>
        <Box>
          <FormControl isInvalid={!!errors?.newPin} my={12}>
            <FormLabel fontWeight={600}>New PIN</FormLabel>
            <InputGroup>
              <Input data-testid={'new-pin-input'} {...commonInputProps} {...register('newPin')} />
              <InputRightElement h={12}>
                <VisibleIcon onClick={handleVisibleIconClick} w={6} h={6} color={placeHolderColor} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.newPin?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors?.confirmPin}>
            <FormLabel fontWeight={600}>Confirm PIN</FormLabel>
            <InputGroup>
              <Input data-testid={'confirm-pin-input'} {...commonInputProps} {...register('confirmPin')} />
              <InputRightElement h={12}>
                <VisibleIcon onClick={handleVisibleIconClick} w={6} h={6} color={placeHolderColor} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors?.confirmPin?.message}</FormErrorMessage>
          </FormControl>
        </Box>
      </Box>
      <ArcButton
        arcColor="#3DCD57"
        width="100%"
        maxWidth={'300px'}
        mb={16}
        onClick={async () => {
          const isValid = await trigger();
          if (isValid) onOpen();
        }}
      >
        {isCreateMode ? 'Create' : 'Reset'} PIN
      </ArcButton>
      <WifiConfigAlertDialogModalDualButton
        {...{ isOpen, onClose, isLoading }}
        header="Confirm your PIN"
        subHeader="Do you want to set your PIN?"
        confirmButtonName="Confirm"
        cancelButtonName="Cancel"
        onConfirm={handleFormSubmit(isCreateMode ? handleCreatePin : handleResetPin)}
      />
    </Flex>
  );
};
