import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { ReactComponent as EVLinkTickDevice } from '../../../assets/images/evlink_device_tick.svg';
import ArcButton from '../../../common/components/ArcButton';
import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';

/* istanbul ignore next -- @preserve */
export const DevicePreSetup = () => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  return (
    <Flex
      bgColor={bgColor}
      direction={'column'}
      align="center"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title="Device Setup" backURL="../device_scan" />
      <Text data-testid={'ev-setup-complete-text'} px={8} pt={TOP_NAV_SPACING_AFFORDANCE}>
        We have set up your Schneider Home successfully.
      </Text>
      <Flex direction={'column'} align="center">
        <Box data-testid={'ev-image-container'}>
          <EVLinkTickDevice style={{ width: 'fit-content', height: 'auto', maxHeight: '300px', marginLeft: '50px' }} />
        </Box>
        <Text px={8} mt={8} fontSize="24px" fontWeight={'bold'}>
          Setup Successful
        </Text>
      </Flex>
      <ArcButton
        data-testid={'finish-enrolment-btn'}
        arcColor="#3DCD57"
        width="100%"
        maxWidth={'300px'}
        mb={16}
        onClick={() => navigate('../ev_charger_status', { replace: true })}
      >
        Next
      </ArcButton>
    </Flex>
  );
};
