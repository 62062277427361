import React, { useMemo } from 'react';
import { Flex, Skeleton, Text } from '@chakra-ui/react';

import {
  CloudBoltRainIcon,
  CloudDrizzleIcon,
  CloudFogIcon,
  CloudHeavyRainIcon,
  CloudIcon,
  CloudMoonIcon,
  CloudRainIcon,
  CloudSleetIcon,
  CloudSunIcon,
  MoonIcon,
  SmokeIcon,
  SnowIcon,
  SunDustIcon,
  SunHazeIcon,
  SunMaxIcon,
  TornadoIcon,
  TropicalStormIcon,
} from 'clipsal-cortex-icons/src/weather-icons';

import { getFormattedTemperature } from '../account/settings/settings-helpers';
import { useGetWeatherQuery } from '../site/weatherApi';

const COMMON_PROPS = { mr: 2, w: 5, h: 5 };

function getWeatherIcon(condition: string, description: string, isDay: boolean) {
  const weatherIconMap: Record<string, JSX.Element> = {
    ash: <SmokeIcon {...COMMON_PROPS} />,
    clear: isDay ? <SunMaxIcon {...COMMON_PROPS} /> : <MoonIcon {...COMMON_PROPS} />,
    clouds: description.toLowerCase().includes('few') ? (
      isDay ? (
        <CloudSunIcon {...COMMON_PROPS} />
      ) : (
        <CloudMoonIcon {...COMMON_PROPS} />
      )
    ) : (
      <CloudIcon {...COMMON_PROPS} />
    ),
    drizzle: <CloudDrizzleIcon {...COMMON_PROPS} />,
    dust: <SunDustIcon {...COMMON_PROPS} />,
    fog: <CloudFogIcon {...COMMON_PROPS} />,
    haze: <SunHazeIcon {...COMMON_PROPS} />,
    mist: <SmokeIcon {...COMMON_PROPS} />,
    rain: description.toLowerCase().includes('freezing') ? (
      <CloudSleetIcon {...COMMON_PROPS} />
    ) : description.toLowerCase().includes('heavy') ? (
      <CloudHeavyRainIcon {...COMMON_PROPS} />
    ) : (
      <CloudRainIcon {...COMMON_PROPS} />
    ),
    sand: <SunDustIcon {...COMMON_PROPS} />,
    squall: <CloudHeavyRainIcon {...COMMON_PROPS} />,
    smoke: <SmokeIcon {...COMMON_PROPS} />,
    snow: description.toLowerCase().includes('sleet') ? (
      <CloudSleetIcon {...COMMON_PROPS} />
    ) : (
      <SnowIcon {...COMMON_PROPS} />
    ),
    storm: <CloudBoltRainIcon {...COMMON_PROPS} />,
    thunderstorm: <TropicalStormIcon {...COMMON_PROPS} />,
    tornado: <TornadoIcon {...COMMON_PROPS} />,
  };

  return weatherIconMap[condition.toLowerCase()];
}

type Props = {
  shouldShowIcon?: boolean;
};

export default function Weather({ shouldShowIcon = true }: Props) {
  const { data: weather, isLoading } = useGetWeatherQuery();

  const formattedTemperature = useMemo(() => {
    if (weather) {
      return getFormattedTemperature(weather.temperature);
    }
  }, [weather?.temperature]);

  const WeatherIcon = useMemo(() => {
    if (weather && shouldShowIcon) {
      return getWeatherIcon(weather.condition, weather.description, weather.daytime);
    }
  }, [weather, shouldShowIcon]);

  return (
    <Skeleton isLoaded={!isLoading}>
      {weather && (
        <Flex alignItems="center">
          {WeatherIcon}
          <Text data-testid="current-temperature">{formattedTemperature}°</Text>
        </Flex>
      )}
    </Skeleton>
  );
}
