import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SEHomeCard from '../../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../../common/constants';
import { selectSite } from '../../site/siteSlice';
import AccountMenuButton from '../AccountMenuButton';
import { HARDWARE_TYPE_TO_UI_CONFIG } from './constants';
import { VisibleHardwareType } from './types';

const VISIBLE_HARDWARE_TYPES = ['INVERTER', 'BATTERY_PACK', 'EV_CHARGER', 'METER', 'BACKUP_GRID_CONTROL_SYSTEM'];

export function HardwareList() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const siteDevices = site.devices.filter((d) => {
    if (d.device_type === 'METER') {
      return ['Sense', 'Schneider Electric'].includes(d.manufacturer);
    }
    return VISIBLE_HARDWARE_TYPES.includes(d.device_type);
  });
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Hardware.hardware')}
      backURL={`../../dashboard?direction=back`}
      customTopNavButton={
        <Button
          isDisabled={IS_DEMO_LOGIN}
          colorScheme="schneiderSkyBlue"
          data-testid="add-device-btn"
          onClick={() => navigate(`../configure`)}
          variant={'ghost'}
          mr={2}
          size={'sm'}
          fontWeight={500}
        >
          {t('Common.add')}
        </Button>
      }
    >
      <Box px={3}>
        <Text>{t('Hardware.my hardware').toUpperCase()}</Text>
        {siteDevices.length ? (
          <SEHomeCard borderTopLeftRadius={'5px'} p={0}>
            {siteDevices.map((device, i) => {
              const { icon: DeviceIcon } = HARDWARE_TYPE_TO_UI_CONFIG[device?.device_type as VisibleHardwareType];
              return (
                <AccountMenuButton
                  key={i}
                  borderBottomRightRadius={i === siteDevices.length - 1 ? undefined : 0}
                  includeBottomDivider={i !== siteDevices.length - 1}
                  data-testid={`device-list-item-${device.device_type}`}
                  onClick={() => {
                    // @TODO: Update this page for EV chargers once we have a unified strategy for managing
                    //        wifi configuration and device removal.
                    if (device.device_type === 'EV_CHARGER') {
                      navigate(
                        `../../../ev_charger/home/device_details?backURL=/sites/${site.site_id}/account/hardware/list`
                      );
                    } else {
                      navigate(`../${device.id}/view`);
                    }
                  }}
                >
                  <Flex align={'center'} overflow="auto">
                    <DeviceIcon w={8} h={8} />
                    <Text fontSize={14} fontWeight="normal" ml={3}>
                      {device.model}
                    </Text>
                  </Flex>
                </AccountMenuButton>
              );
            })}
          </SEHomeCard>
        ) : (
          <Text>{t('Hardware.you have no hardware')}</Text>
        )}
      </Box>
    </SlidingAnimationPageBase>
  );
}
