import React, { useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  Flex,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArcButton from '../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { MIN_TARIFF_PAGE_HEIGHT } from './tariff-constants';
import { mapTariffApiToForm } from './tariff-helpers';
import { useConvertRetailRateToTariffMutation, useRetailRates, useTariffs } from './tariffApi';
import { useTariffFormContext } from './useTariffFormContext';

// can't set this manually yet
const PAGE_SIZE = 10;

export const WattbuyRetailRatesSelect = () => {
  const { control, reset, setValue, getValues } = useTariffFormContext();
  const { tariffs } = useTariffs();
  const utility = useWatch({ name: 'tariff.utility', control });
  const { name, state } = utility;
  const [{ retailRateIndex, pageNumber }, setState] = useState({ retailRateIndex: 0, pageNumber: 1 });
  const { retailRates, count, isLoading, isFetching } = useRetailRates(pageNumber);
  const { t } = useTranslation();

  const { canGoPreviousPage, canGoNextPage, showPagination } = useMemo(() => {
    let canGoPreviousPage = false;
    let canGoNextPage = false;
    if (count > PAGE_SIZE * pageNumber) canGoNextPage = true;
    if (pageNumber > 1) canGoPreviousPage = true;

    return { canGoPreviousPage, canGoNextPage, showPagination: canGoPreviousPage || canGoNextPage };
  }, [retailRates]);

  const [convertRetailRate, { isLoading: isConvertingRetailRate }] = useConvertRetailRateToTariffMutation();
  const { tariffId = '' } = useParams<{ tariffId: string }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const toast = useToast();

  const handleSubmit = async () => {
    const retailRate = retailRates[retailRateIndex];
    try {
      const tariff = await convertRetailRate({
        utilityId: retailRate.utility_id,
        rateId: retailRate.id,
        state,
      }).unwrap();
      const tariffData = {
        tariff_effective_date: format(new Date(), 'yyyy-MM-dd'),
        tariff,
        zipCode: getValues('zipCode'),
      };
      reset(mapTariffApiToForm(tariffData, utility));

      // adjust new date and dirty the form
      const latestTariffEffectiveDate = tariffs[0]?.tariff_effective_date;
      const newEffectiveDate = latestTariffEffectiveDate ? new Date(latestTariffEffectiveDate) : new Date();
      newEffectiveDate.setDate(newEffectiveDate.getDate() + 1);
      setValue('effectiveDate', format(newEffectiveDate, 'yyyy-MM-dd'), { shouldDirty: true });

      // show confirmation page
      navigate(`../${tariff.tariff_type.toLowerCase()}/confirm?backURL=${pathname}`);
    } catch (error) {
      console.error(error);
      toast({
        title: t('Energy Rates.error fetching tariff'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
      });
    }
  };

  const listHeight = useMemo(
    () => `calc(${MIN_TARIFF_PAGE_HEIGHT} - ${showPagination ? 210 : 160}px)`,
    [showPagination]
  );

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.set up rates')}
      includeTopNav
      px={4}
      backURL={tariffId === 'new' ? `../home?direction=back` : `../../home?direction=back`}
      data-testid="retail-rates-select"
    >
      <VStack align={'initial'} minHeight={MIN_TARIFF_PAGE_HEIGHT} gap={0}>
        <Text>{t('Energy Rates.select the rate plan', { utilityName: name })}</Text>
        <Box
          mt={4}
          maxHeight={listHeight}
          minHeight={listHeight}
          overflowY={'auto'}
          bg="rgba(0,0,0,0.05)"
          _dark={{ bg: 'rgba(255,255,255,0.05)' }}
          borderRadius={4}
          data-testid="retail-rates-list"
        >
          <RadioGroup
            onChange={(retailRateIndex) => {
              setState((prevState) => ({ ...prevState, retailRateIndex: parseInt(retailRateIndex) }));
            }}
            value={retailRateIndex.toString()}
          >
            {isLoading ? (
              <VStack>
                {Array.from({ length: 5 }, (_, i) => i).map((index) => (
                  <Skeleton w={'100%'} h={20} rounded={2} key={`skeleton-${index}`} />
                ))}
              </VStack>
            ) : (
              retailRates.map(({ id, rate_name, tariff_code, zone }, index) => {
                const tariffCode = tariff_code ? `${tariff_code} - ` : '';
                const zoneId = zone ? `${zone} - ` : '';
                const retailRateName = `${tariffCode}${zoneId}${rate_name}`;
                return (
                  <Box
                    key={id + retailRateName}
                    onClick={() => setState((prevState) => ({ ...prevState, retailRateIndex: index }))}
                    data-testid={`retail-rate-${index}`}
                  >
                    <Box pl={4} py={4}>
                      <Flex mt={2} pr={4} w="100%" justify={'space-between'}>
                        <Text>{retailRateName}</Text>
                        <Radio
                          value={index.toString()}
                          h="fit-content"
                          my="auto"
                          ml={4}
                          data-testid={`${rate_name}-radio-button`}
                        />
                      </Flex>
                    </Box>
                    <Divider />
                  </Box>
                );
              })
            )}
          </RadioGroup>
        </Box>
        {showPagination && (
          <Center mt={showPagination ? 4 : 0}>
            {canGoPreviousPage && (
              <IconButton
                isLoading={isFetching}
                icon={<ChevronLeftIcon w={6} h={6} />}
                rounded={'100%'}
                aria-label="go-to-previous-page"
                onClick={() => setState((prevState) => ({ ...prevState, pageNumber: pageNumber - 1 }))}
                data-testid="previous-page-button"
              />
            )}
            {canGoNextPage && (
              <IconButton
                isLoading={isFetching}
                icon={<ChevronRightIcon w={6} h={6} />}
                rounded={'100%'}
                aria-label="go-to-next-page"
                onClick={() => setState((prevState) => ({ ...prevState, pageNumber: pageNumber + 1 }))}
                data-testid="next-page-button"
              />
            )}
          </Center>
        )}
        <Center mt="auto">
          <ArcButton
            arcColor="#3DCD58"
            minWidth="280px"
            mt={showPagination ? 4 : 6}
            mb={6}
            py={6}
            isLoading={isConvertingRetailRate || isLoading}
            onClick={handleSubmit}
            data-testid="continue-button"
          >
            {t('Common.continue')}
          </ArcButton>
        </Center>
      </VStack>
    </SlidingAnimationPageBase>
  );
};
