import React, { useMemo } from 'react';
import { Heading, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useSiteHardwareConfigurationType } from '../../common/hooks/use-site-hardware-configuration-type';
import { useLiveData } from '../site/live-data/liveDataApi';
import { selectUser } from '../user/userSlice';

export default function WelcomeMessage() {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const textColor = useColorModeValue('customGrey.900', 'customGrey.400');
  const { hardwareConfigurationType, isLoading: isSiteConfigurationTypeLoading } = useSiteHardwareConfigurationType();
  const { isLoading: isLiveDataLoading, isInverterError, isMeterError } = useLiveData();
  const firstNameFormatted = user?.firstName?.length ? `, ${user.firstName}!` : '!';
  const currentTime = new Date().getHours();

  const heading = useMemo(() => {
    if (currentTime >= 0 && currentTime < 12) {
      return `${t('Welcome Message.Heading.morning')}${firstNameFormatted}`;
    } else if (currentTime >= 12 && currentTime < 18) {
      return `${t('Welcome Message.Heading.afternoon')}${firstNameFormatted}`;
    } else {
      return `${t('Welcome Message.Heading.evening')}${firstNameFormatted}`;
    }
  }, [t, currentTime, firstNameFormatted]);

  // @TODO: How can we discern system status for battery, BCS/GCS, or online but error detected? backend work required
  const offlineHardware = useMemo(() => {
    if (isInverterError && isMeterError) {
      return t('Welcome Message.Message.system');
    } else if (isInverterError) {
      return t('Welcome Message.Message.inverter');
    } else if (isMeterError) {
      return t('Welcome Message.Message.energy monitor');
    } else {
      return null;
    }
  }, [t, isInverterError, isMeterError]);

  const statusMessageCopy = useMemo(() => {
    const isMeter = hardwareConfigurationType === 'sense';
    const isInverter = hardwareConfigurationType === 'default';
    if ((isMeter || isInverter) && offlineHardware) {
      return t('Welcome Message.Message.offline system status', { hardware: offlineHardware });
    } else if (isInverter && !offlineHardware) {
      return t('Welcome Message.Message.online system status');
    } else {
      return t('Welcome Message.Message.your real time energy use breakdown');
    }
  }, [t, hardwareConfigurationType, offlineHardware]);

  const isLoaded = useMemo(() => {
    if (hardwareConfigurationType === 'default') {
      return !isLiveDataLoading;
    } else return !isSiteConfigurationTypeLoading;
  }, [isSiteConfigurationTypeLoading, isLiveDataLoading, hardwareConfigurationType]);

  const statusMessage = (
    <Text color={textColor} data-testid="default-home-status-msg">
      {statusMessageCopy}
    </Text>
  );

  return (
    <>
      <Heading size={'lg'} data-testid="welcome-message">
        {heading}
      </Heading>
      <Skeleton isLoaded={isLoaded}>{statusMessage}</Skeleton>
    </>
  );
}
