import './charge-history-date-picker.scss';

import React, { useMemo } from 'react';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Input } from '@chakra-ui/react';
import { sub } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';

import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { ChargeHistoryDateRangeType } from './ChargeHistory';

type CustomDatePickerProps = {
  rangeType: ChargeHistoryDateRangeType;
  selectedPeriod: string;
  onChangeSelectedPeriod: (newPeriod: string) => void;
  isDisabled: boolean;
  minDate: string;
  timezone: string;
};

type Direction = 'forward' | 'back';

const directionToFunction: Record<Direction, (a: number, b: number) => number> = {
  forward: (a: number, b: number) => a + b,
  back: (a: number, b: number) => a - b,
};

/* istanbul ignore next -- @preserve */
export const ChargeHistoryDatePicker = ({
  rangeType,
  selectedPeriod,
  onChangeSelectedPeriod,
  isDisabled,
  minDate,
  timezone,
}: CustomDatePickerProps) => {
  const { todayDate, minimumDate, defaultRangeTypeToSelectedPeriod } = useMemo(() => {
    const currentDate = new Date();
    const todayDate = formatInTimeZone(currentDate, timezone, 'yyyy-MM-dd');
    const minimumDate = formatInTimeZone(new Date(minDate.replace(/-/g, '/')), timezone, 'yyyy-MM-dd');

    // for month view we show 1st day of month
    // as moving from 29th March to 28th Feb will look inconsistent
    currentDate.setDate(1);
    const firstDayOfMonth = formatInTimeZone(currentDate, timezone, 'yyyy-MM-dd');

    const defaultRangeTypeToSelectedPeriod: Record<ChargeHistoryDateRangeType, string> = {
      [DateRangeType.Day]: todayDate,
      [DateRangeType.Week]: todayDate,
      [DateRangeType.Month]: firstDayOfMonth,
    };

    return { todayDate, minimumDate, defaultRangeTypeToSelectedPeriod };
  }, [timezone]);

  function handleMoveByDay(direction: Direction) {
    const fn = directionToFunction[direction];
    // changed format of selectedPeriod to non-utc format to fix timezone issues on date
    // For e.g.: a utc date such as 2022-09-11 will be changed to 2022-09-10 in US timezone
    // But non utc format such as 2022/09/11 do not change as they are not formatted as per UTC timezone
    const selectedDate = new Date(selectedPeriod.replace(/-/g, '/'));
    selectedDate.setDate(fn(selectedDate.getDate(), 1));
    const selectedDateFormatted = formatDate(selectedDate);
    onChangeSelectedPeriod(selectedDateFormatted);
  }

  function handleMoveByWeek(direction: Direction) {
    const fn = directionToFunction[direction];
    const selectedDate = new Date(selectedPeriod.replace(/-/g, '/'));
    selectedDate.setDate(fn(selectedDate.getDate(), 7));
    const selectedDateFormatted = formatDate(selectedDate);
    onChangeSelectedPeriod(selectedDateFormatted);
  }

  function handleMoveByMonth(direction: Direction) {
    const fn = directionToFunction[direction];
    const selectedDate = new Date(selectedPeriod.replace(/-/g, '/'));

    // https://stackoverflow.com/a/25058037
    // to fix inconsistent month set issues we use: Date.setMonth(month,day)
    selectedDate.setMonth(fn(selectedDate.getMonth(), 1), 1);
    onChangeSelectedPeriod(formatDate(selectedDate));
  }

  const handleMove = (direction: Direction) => {
    const rangeTypeToCaller: Record<ChargeHistoryDateRangeType, (direction: Direction) => void> = {
      [DateRangeType.Day]: handleMoveByDay,
      [DateRangeType.Week]: handleMoveByWeek,
      [DateRangeType.Month]: handleMoveByMonth,
    };
    const fn = rangeTypeToCaller[rangeType];
    fn(direction);
  };

  const checkIfMoveForwardButtonIsDisabled = () => {
    const selectedDate = new Date(selectedPeriod.replace(/-/g, '/'));

    if (rangeType === DateRangeType.Week) {
      const comparisonDate = sub(selectedDate, { weeks: 1 });
      if (formatDate(comparisonDate) > todayDate) return true;
    }

    return isDisabled || selectedPeriod === defaultRangeTypeToSelectedPeriod[rangeType];
  };

  const checkIfMoveBackButtonIsDisabled = () => {
    const selectedDate = new Date(selectedPeriod.replace(/-/g, '/'));

    if (rangeType === DateRangeType.Day) {
      const comparisonDate = sub(selectedDate, { days: 1 });
      if (formatDate(comparisonDate) < minimumDate) return true;
    }

    if (rangeType === DateRangeType.Week) {
      const comparisonDate = sub(selectedDate, { weeks: 1 });
      if (formatDate(comparisonDate) < minimumDate) return true;
    }

    if (rangeType === DateRangeType.Month) {
      const comparisonDate = sub(selectedDate, { months: 1 });
      if (format(comparisonDate, 'yyyy-MM-dd') < minimumDate) return true;
    }

    return isDisabled;
  };

  return (
    <Flex justify={'center'} align={'center'} color={'#1772ED'}>
      <IconButton
        data-testid="previous-date-button"
        size={'sm'}
        rounded={20}
        mr={2}
        variant={'ghost'}
        aria-label="Previous"
        icon={<ChevronLeftIcon h={5} w={5} />}
        isDisabled={checkIfMoveBackButtonIsDisabled()}
        onClick={() => handleMove('back')}
      />
      <Box position={'relative'} color={'#1772ED'}>
        <Input
          pl={14}
          value={selectedPeriod}
          isDisabled={isDisabled}
          border="none"
          w="fit-content"
          onChange={(e) => onChangeSelectedPeriod(e.currentTarget.value)}
          type={'date'}
          min={minimumDate}
          max={todayDate}
        />
        <CalendarIcon pointerEvents={'none'} position={'absolute'} left={4} top={'30%'} />
      </Box>
      <IconButton
        data-testid="next-date-button"
        size={'sm'}
        rounded={20}
        mr={2}
        variant={'ghost'}
        aria-label="Next"
        icon={<ChevronRightIcon h={5} w={5} />}
        isDisabled={checkIfMoveForwardButtonIsDisabled()}
        onClick={() => handleMove('forward')}
      />
    </Flex>
  );
};

export default ChargeHistoryDatePicker;
