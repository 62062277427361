import React, { useRef, useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { EyeIcon, EyeSlashIcon } from 'clipsal-cortex-icons/src/custom-icons';
import useInterval from 'clipsal-cortex-utils/src/hooks/use-interval';

import { useReduxDispatch } from '../../../app/store';
import ArcButton from '../../../common/components/ArcButton';
import CommonTopNav from '../../../common/components/CommonTopNav';
import { BOTTOM_NAV_HEIGHT, COMPONENT_MIN_HEIGHT } from '../../../common/constants';
import { WifiConfigAlertDialogModalConfirmOnly, WifiConfigAlertDialogModalDualButton } from './WifiConfigAlertModal';
import { addBearerToken } from './wifiConfigSlice';

const DEFAULT_TIMER_IN_SECONDS = 60;
const DEFAULT_POLLING_INTERVAL_IN_MS = 1000;

/* istanbul ignore next -- @preserve */
export const EnterPin = () => {
  const [
    { pin, error, isPinVisible, isLoading, isResetDialogOpen, isTimerStarted, isResetTimeoutDialogOpen },
    setState,
  ] = useState({
    pin: '',
    error: '',
    isPinVisible: false,
    isLoading: false,
    isResetDialogOpen: false,
    isTimerStarted: false,
    isResetTimeoutDialogOpen: false,
  });
  const navigate = useNavigate();
  const VisibileIcon = isPinVisible ? EyeSlashIcon : EyeIcon;
  const handleVisibleIconClick = () =>
    setState((prevState) => ({ ...prevState, isPinVisible: !prevState.isPinVisible }));
  const dispatch = useReduxDispatch();
  const { placeHolderColor, inputBackground } = useColorModeValue(
    { placeHolderColor: '#0F0F0F', inputBackground: '#FFFFFF' },
    { placeHolderColor: '#F7F7F7', inputBackground: '#293133' }
  );
  const bgColor = useColorModeValue('#F7F7F7', '#111111');
  // starting with 1 as useInterval is invoked after a second
  // hence at that point, a second has passed
  const timeoutRef = useRef(1);

  useInterval(
    () => {
      if (timeoutRef.current >= DEFAULT_TIMER_IN_SECONDS - 1) {
        setState((prevState) => ({
          ...prevState,
          isTimerStarted: false,
          isResetDialogOpen: false,
          isResetTimeoutDialogOpen: true,
        }));
        timeoutRef.current = 1;
      } else {
        timeoutRef.current++;
      }
    },
    isTimerStarted ? DEFAULT_POLLING_INTERVAL_IN_MS : null
  );

  return (
    <Flex
      bgColor={bgColor}
      direction={'column'}
      align="center"
      w="100%"
      justify={'space-between'}
      minH={COMPONENT_MIN_HEIGHT}
      pb={BOTTOM_NAV_HEIGHT}
    >
      <CommonTopNav title={'Enter PIN'} backURL="../home" />
      <Box w="100%" px={4} mt={16} position="relative">
        <Text mb={4}>Enter your PIN</Text>
        <FormControl isInvalid={!!error} mt={6}>
          <FormLabel fontWeight={600}>PIN</FormLabel>
          <InputGroup>
            <Input
              data-testid={'enter-pin-input'}
              {...{
                placeholder: 'From 6 to 16 digits',
                borderColor: '#9FA0A4',
                background: inputBackground,
                py: 6,
                inputMode: 'numeric',
                type: isPinVisible ? 'number' : 'password',
                _placeholder: {
                  color: placeHolderColor,
                },
                value: pin,
                onChange: (e) => {
                  const currentValue = e.target.value.trim();
                  const isValidPin = currentValue === '' || !isNaN(Number(currentValue));
                  if (isValidPin) setState((prevState) => ({ ...prevState, pin: currentValue }));
                },
              }}
            />
            <InputRightElement h={12}>
              <VisibileIcon onClick={handleVisibleIconClick} w={6} h={6} color={placeHolderColor} />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage id={'enter-pin-error'}>{error}</FormErrorMessage>
        </FormControl>

        <Button
          minW={0}
          px={2}
          variant={'ghost'}
          position="absolute"
          top={48}
          colorScheme="schneiderSkyBlue"
          fontWeight={'normal'}
          onClick={() => {
            setState((prevState) => ({ ...prevState, isResetDialogOpen: true, isTimerStarted: true }));
          }}
        >
          Reset PIN?
        </Button>
      </Box>

      <ArcButton
        isLoading={isLoading}
        arcColor="#3DCD57"
        width="100%"
        maxWidth={'300px'}
        mb={16}
        onClick={async () => {
          setState((prevState) => ({ ...prevState, isLoading: true }));
          try {
            const { data } = await HTTP.post(
              'https://192.168.89.136/evse/v1/login/login',
              {
                username: 'SEhome',
                password: pin,
              },
              { 'Content-type': 'application/json', Accept: 'application/json' }
            );
            const { token } = JSON.parse(data);
            dispatch(addBearerToken(token));
            navigate(pin === '123456' ? '../create-pin' : '../add-network');
          } catch (error) {
            // Set default error message
            let errorMessage = 'Something went wrong. Please check if you are connected to charger network.';

            // Add error message based on status code when received
            if (error && typeof error === 'object') {
              if ((error as any).status === 401)
                errorMessage = 'Wrong PIN. You will be locked out after 3 incorrect tries.';
              if ((error as any).status === 403) errorMessage = 'Please try again after 5 minutes or reset your PIN.';
            }

            setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
          }
        }}
      >
        Connect
      </ArcButton>
      <WifiConfigAlertDialogModalDualButton
        {...{
          isOpen: isResetDialogOpen,
          onClose: () => {
            setState((prevState) => ({ ...prevState, isResetDialogOpen: false, isTimerStarted: false }));
          },
        }}
        header="Reset your PIN"
        subHeader="Do you want to reset your PIN?"
        confirmButtonName="Reset"
        cancelButtonName="Cancel"
        onConfirm={() => {
          setState((prevState) => ({ ...prevState, isResetDialogOpen: false, isTimerStarted: false }));
          navigate('../reset-pin');
        }}
      />

      <WifiConfigAlertDialogModalConfirmOnly
        {...{
          isOpen: isResetTimeoutDialogOpen,
          onClose: () => {
            setState((prevState) => ({ ...prevState, isResetTimeoutDialogOpen: false }));
          },
        }}
        header="Reset PIN Cancelled"
        subHeader="You have been timeout with no response after 60 seconds"
        confirmButtonName="Try again"
        onConfirm={() => {
          setState((prevState) => ({
            ...prevState,
            isResetTimeoutDialogOpen: false,
          }));
        }}
      />
    </Flex>
  );
};
