import { DayType, TOURateType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { Rate, TariffRatesPerType } from '../tariff-types';
import { DAY_TYPE_TO_DAYS, TOU_RATE_TYPE_TO_DEFAULT_HOUR_MINUTE } from './tou-tariff-constants';

export const getDefaultTOUTariffRate = (
  seasonIndex: number,
  touRateType: TOURateType,
  dayType: DayType = 'weekend'
): Rate => {
  const days = DAY_TYPE_TO_DAYS[dayType];
  const defaultPeriodValue = TOU_RATE_TYPE_TO_DEFAULT_HOUR_MINUTE[dayType][touRateType];
  return {
    seasonIndex,
    chargeType: 'CONSUMPTION_BASED',
    chargeClass: 'SUPPLY',
    chargePeriod: 'DAILY',
    transactionType: 'BUY_IMPORT',
    timeOfUse: {
      touName: touRateType,
      touRateType,
      periods: [
        {
          days,
          publicHoliday: false,
          ...defaultPeriodValue,
        },
      ],
    },

    rateBands: [
      {
        sequenceNumber: 1,
        rate: undefined,
        hasConsumptionLimit: false,
        hasDemandLimit: false,
      },
    ],
  };
};

export const getDefaultTOUTariffRates = (seasonIndex: number): TariffRatesPerType => {
  return {
    import: [],
    weekday: {
      peak: [getDefaultTOUTariffRate(seasonIndex, 'PEAK', 'weekday')],
      partialPeak: [],
      offPeak: [getDefaultTOUTariffRate(seasonIndex, 'OFF_PEAK', 'weekday')],
    },
    weekend: {
      peak: [],
      partialPeak: [],
      offPeak: [getDefaultTOUTariffRate(seasonIndex, 'OFF_PEAK')],
    },
    allWeek: {
      peak: [],
      partialPeak: [],
      offPeak: [],
    },
    export: [
      {
        seasonIndex,
        chargeType: 'CONSUMPTION_BASED',
        chargeClass: 'SUPPLY',
        chargePeriod: 'DAILY',
        transactionType: 'SELL_EXPORT',
        rateBands: [
          {
            sequenceNumber: 1,
            rate: undefined,
            hasConsumptionLimit: false,
            hasDemandLimit: false,
          },
        ],
      },
    ],
  };
};

/**
 * @description Convert 24 hour format to 12 hour format
 * @param hour 24 hour format 0-23
 * @returns {hour: number, amOrPm: 'AM' | 'PM', formatted: string}
 * @example convert24HourTo12HourFormat(0) => {hour: 12, amOrPm: 'AM', formatted: '12AM'}
 * @example convert24HourTo12HourFormat(12) => {hour: 12, amOrPm: 'PM', formatted: '12PM'}
 */
export const convert24HourTo12HourFormat = (hour: number) => {
  let amOrPm = 'AM';
  let hourValue = hour;

  if (hour >= 12) amOrPm = 'PM';
  if (hour > 12) hourValue = hour - 12;
  if (hour === 0) hourValue = 12;

  return { hour: hourValue, amOrPm, formatted: `${hourValue}${amOrPm}` };
};

/**
 * @description Convert 12 hour format to 24 hour format
 * @param hour 12 hour format 1-12
 * @param amOrPm AM or PM
 * @returns number 0-23
 * @example convert12HourTo24HourFormat(12, 'AM') => 0
 * @example convert12HourTo24HourFormat(12, 'PM') => 12
 */
export const convert12HourTo24HourFormat = (hour: number, amOrPm: 'AM' | 'PM') => {
  if (hour === 12) return amOrPm === 'AM' ? 0 : 12;
  if (amOrPm === 'PM') return hour + 12;
  return hour;
};
