import React from 'react';
import { Center, Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import inverterCommissioningSuccessful from '../../../../../assets/images/inverter_connection_success.svg';
import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import useOnboardingWizardProgress from '../../../../home/useOnboardingWizardProgress';
import { selectMeters, selectSite } from '../../../../site/siteSlice';

export function InverterSetupComplete() {
  const site = useSelector(selectSite);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { needsToVisitTariffs } = useOnboardingWizardProgress();
  const { t } = useTranslation();
  const meters = useSelector(selectMeters);
  const hasOfflineSEM = meters.length && meters.find((meter) => meter.operating_state === 'OFFLINE');
  const redirectBackURL = `/sites/${site.site_id}/account/hardware/configure/inverter/finish`;

  return (
    <SlidingAnimationPageBase title={t('Set Up Hardware.setup complete')}>
      <Center flexDirection="column" px={3} mt={6} textAlign="center">
        <Image w={'70%'} src={inverterCommissioningSuccessful} alt="Inverter configuration complete" />

        <Heading mt={2}>{t('Set Up Hardware.setup successful')}</Heading>
        <Text mt={1}>
          {t('Set Up Hardware.we setup your device', { device: t('Set Up Hardware.schneider inverter') })}
        </Text>
        {/* At this point, if the user has an offline SEM, it likely means they have a SEM + inverter configuration,
            but their SEM hasn't been Wi-Fi configured yet (which can be done through the Sense Pro app by the
            installer). When this happens, redirect the user to configure their SEM */}
        {hasOfflineSEM ? <Text mt={2}>{t('Set Up Hardware.your sem needs to be setup')}</Text> : null}
        <ArcButton
          data-testid={'inverter-finish'}
          onClick={() => {
            if (hasOfflineSEM) {
              navigate(`../../meter/start?backURL=${redirectBackURL}`);
            } else {
              const deviceId = search.get('deviceId') || '';
              // If we have a deviceId, we are coming from the inverter details page
              if (deviceId) {
                navigate(`../../../${deviceId}/view`);
              } else if (needsToVisitTariffs) {
                navigate(`../../../../tariff/new/home?backURL=${redirectBackURL}`);
              } else {
                navigate(`../../../../../home`);
              }
            }
          }}
          mt={3}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {hasOfflineSEM ? t('Set Up Hardware.set up sem wifi') : t('Common.continue')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
