import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLiveData } from '../../../site/live-data/liveDataApi';
import { HomePageWidgetTileProps } from '../HomePageWidgetTile';
import GenericHomePageWidgetTile from './GenericHomePageWidgetTile';

export default function BatteryWidgetTile({ item, index, isEditing, onRemoveGridItem }: HomePageWidgetTileProps) {
  const { data: liveDataSummary, isInverterDataLoading: isLoading } = useLiveData();
  const { t } = useTranslation();

  return (
    <GenericHomePageWidgetTile
      isLoaded={!isLoading}
      item={{
        ...item,
        title: t('Widgets.Battery.title'),
        status: `${Math.round((liveDataSummary.battery_soc_fraction || 0) * 100)}%`,
      }}
      index={index}
      isEditing={isEditing}
      onRemoveGridItem={onRemoveGridItem}
    />
  );
}
