import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Skeleton, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import { useSelector } from 'react-redux';

import { EvChargeHistoryData } from 'clipsal-cortex-types/src/api/api-evcharger';
import { formatDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

import { get } from '../../../common/api/api-helpers';
import { DateRangeType, DateRangeTypePicker } from '../../../common/components/DateRangeTypePicker';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { selectEvChargers, selectSite } from '../../site/siteSlice';
import { selectEvChargerData } from '../dashboard/evChargerSlice';
import ChargeHistoryDatePicker from './ChargeHistoryDatePicker';
import ChargingSessionListAccordion from './ChargingSessionListAccordion';
import TotalEnergyCard from './TotalEnergyCard';

export type ChargeHistoryDateRangeType = Exclude<DateRangeType, DateRangeType.Year>;

type ChargeHistoryState = {
  isLoaded: boolean;
  historyData: EvChargeHistoryData;
  selectedDateRangeType: ChargeHistoryDateRangeType;
  rangeTypeToSelectedPeriod: Record<ChargeHistoryDateRangeType, string>;
};

/* istanbul ignore next -- @preserve */
export const ChargeHistory = () => {
  const { timezone, site_id } = useSelector(selectSite);
  const [evCharger] = useSelector(selectEvChargers);
  const chargerInstallationDate = evCharger.created;
  const { chargerId } = useSelector(selectEvChargerData);
  const toast = useToast();
  const { isDesktopViewport } = useViewportType();
  const textColor = useColorModeValue('#0F0F0F', '#FFFFFF');

  const defaultRangeTypeToSelectedPeriod = useMemo(() => {
    const currentDate = new Date();
    const today = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');

    // for month view we show 1st day of month
    // as moving from 29th March to 28th Feb will look inconsistent
    currentDate.setDate(1);
    const firstDayOfMonth = formatInTimeZone(currentDate, timezone, 'yyyy-MM-dd');

    return {
      [DateRangeType.Day]: today,
      [DateRangeType.Week]: today,
      [DateRangeType.Month]: firstDayOfMonth,
    };
  }, [timezone]);

  const [{ isLoaded, historyData, selectedDateRangeType, rangeTypeToSelectedPeriod }, setState] =
    useState<ChargeHistoryState>({
      isLoaded: false,
      historyData: { agg_type: 'day', total_energy: 0, total_charge_count: 0, records: [] },
      selectedDateRangeType: DateRangeType.Day,
      rangeTypeToSelectedPeriod: defaultRangeTypeToSelectedPeriod,
    });

  function checkIfIsInvalidDate(selectedDate: string) {
    const today = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');
    const installationDate = formatInTimeZone(
      new Date(chargerInstallationDate.replace(/-/g, '/')),
      timezone,
      'yyyy-MM-dd'
    );
    const isBeforeInstallDate = selectedDate < installationDate;
    const isAfterCurrentDate = selectedDate > today;
    if (!isBeforeInstallDate && !isAfterCurrentDate) return false;

    toast({
      title: 'Invalid Date!',
      description: isBeforeInstallDate
        ? `Please select dates after your installation date i.e. ${chargerInstallationDate}`
        : 'Please select a valid date that is not in the future.',
      isClosable: true,
    });

    return true;
  }

  function handleChangeSelectedPeriod(newPeriod: string) {
    const isInvalidDate = checkIfIsInvalidDate(newPeriod);
    if (isInvalidDate || !newPeriod) return;
    setState((prevState) => ({
      ...prevState,
      rangeTypeToSelectedPeriod: {
        ...prevState.rangeTypeToSelectedPeriod,
        [selectedDateRangeType]: newPeriod,
      },
    }));
  }

  useEffect(() => {
    // @TODO: consider a refactor
    let startDate = '';
    let endDate = rangeTypeToSelectedPeriod[selectedDateRangeType];
    const finalDate = new Date(endDate.replace(/-/g, '/'));

    if (selectedDateRangeType === DateRangeType.Week) {
      // decrease end date by 7 to find start day of week
      finalDate.setDate(finalDate.getDate() + 1);
      endDate = formatDate(finalDate);
      finalDate.setDate(finalDate.getDate() - 7);
      startDate = formatDate(finalDate);
    } else if (selectedDateRangeType === DateRangeType.Month) {
      startDate = endDate;
      // calculate last day and first day of the month
      const firstDay = new Date(finalDate.getFullYear(), finalDate.getMonth(), 1);
      const lastDay = new Date(finalDate.getFullYear(), finalDate.getMonth() + 1, 1);
      startDate = formatDate(firstDay);
      endDate = formatDate(lastDay);
    } else {
      // If its day, end date should be start day + 1
      startDate = endDate;
      finalDate.setDate(finalDate.getDate() + 1);
      endDate = formatDate(finalDate);
    }

    const fetchChargeHistory = async () => {
      setState((prevState) => ({ ...prevState, isLoaded: false }));
      let uri =
        `/v1/sites/${site_id}/ev_chargers/${chargerId}/history?start_date=${startDate}&end_date=${endDate}` +
        `&agg_type=${selectedDateRangeType.toLowerCase()}&timezone=${timezone}`;
      if (selectedDateRangeType !== DateRangeType.Month) uri += '&show_daily=true';

      const historyData = await get<EvChargeHistoryData>(uri);
      setState((prevState) => ({ ...prevState, historyData, isLoaded: true }));
    };

    if (chargerId) fetchChargeHistory();
  }, [selectedDateRangeType, rangeTypeToSelectedPeriod, chargerId]);

  return (
    <SlidingAnimationPageBase
      title="Charge History"
      backURL="../ev_charger_status?direction=back"
      data-testid="charge-history-page"
      customNavigationDirection="forward"
    >
      <Flex direction={'column'} w="100%" px={2}>
        <DateRangeTypePicker
          selectedDateRangeType={selectedDateRangeType}
          setSelectedDateRangeType={(selectedDateRangeType) => {
            setState((prevState) => ({
              ...prevState,
              selectedDateRangeType: selectedDateRangeType as ChargeHistoryDateRangeType,
            }));
          }}
        />

        <ChargeHistoryDatePicker
          isDisabled={!isLoaded}
          minDate={chargerInstallationDate}
          rangeType={selectedDateRangeType}
          selectedPeriod={rangeTypeToSelectedPeriod[selectedDateRangeType]}
          onChangeSelectedPeriod={handleChangeSelectedPeriod}
          timezone={timezone}
        />
      </Flex>

      <TotalEnergyCard
        isParentLoaded={isLoaded}
        selectedDateRangeType={selectedDateRangeType}
        totalEnergy={historyData.total_energy.toFixed(1)}
      />

      <Skeleton isLoaded={isLoaded} mt={4} mb={2}>
        <Text px={4} fontSize="14px" color={textColor}>
          CHARGING SESSIONS ({historyData.total_charge_count})
        </Text>
      </Skeleton>

      {isLoaded ? (
        <Flex direction="column" px={isDesktopViewport ? 4 : 0}>
          {historyData.records.length ? (
            <ChargingSessionListAccordion
              selectedPeriod={rangeTypeToSelectedPeriod[selectedDateRangeType]}
              chargeHistorySessionData={historyData}
              selectedDateRangeType={selectedDateRangeType}
            />
          ) : (
            <Flex p={4} align="center" justify={'center'} borderRadius={isDesktopViewport ? 4 : 0}>
              No charge history found for this {selectedDateRangeType.toLowerCase()}.
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex direction="column">
          <Skeleton height="55px" />
          <Skeleton height="55px" mt="1px" />
        </Flex>
      )}
    </SlidingAnimationPageBase>
  );
};
