import { useTranslation } from 'react-i18next';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';

export function useDateRangeOptions() {
  const { t } = useTranslation();

  return [
    {
      label: t('Common.day')[0],
      value: DateRangeType.Day,
    },
    {
      label: t('Common.week')[0],
      value: DateRangeType.Week,
    },
    {
      label: t('Common.month')[0],
      value: DateRangeType.Month,
    },
    {
      label: t('Common.year')[0],
      value: DateRangeType.Year,
    },
  ];
}
