import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { ControlGroupType } from 'clipsal-cortex-types/src/api/api-control-group';

import {
  BatteryChargedIcon,
  HouseChargingIcon,
  LowPowerIcon,
  SEHomeEnergyModeIcon,
  StormIcon,
} from '../../../styles/custom-icons';

export type StandardMode = 'BATTERY_MAX' | 'SELF_CONSUMPTION' | 'TOU';
export type TemporaryMode = 'STORM' | 'LOW_POWER';

export const ENERGY_MODE_ICONS: Record<StandardMode | TemporaryMode, ComponentWithAs<'svg', IconProps>> = {
  SELF_CONSUMPTION: HouseChargingIcon,
  TOU: SEHomeEnergyModeIcon,
  BATTERY_MAX: BatteryChargedIcon,
  STORM: StormIcon,
  LOW_POWER: LowPowerIcon,
};

export type EnergyModeUIConfig = {
  title: string;
  icon: ComponentWithAs<'svg', IconProps>;
  route?: string;
  groupType?: ControlGroupType;
};

export const ENERGY_MODES_UI_CONFIG: Record<StandardMode | TemporaryMode, EnergyModeUIConfig> = {
  SELF_CONSUMPTION: {
    title: 'Self Consumption',
    icon: ENERGY_MODE_ICONS['SELF_CONSUMPTION'],
  },
  TOU: {
    title: 'Time of Use Savings',
    icon: ENERGY_MODE_ICONS['TOU'],
    route: 'tou-savings-mode',
    groupType: 'TOU',
  },
  BATTERY_MAX: {
    title: 'Max Backup Reserve',
    icon: ENERGY_MODE_ICONS['BATTERY_MAX'],
  },
  STORM: {
    title: 'Storm Mode',
    icon: ENERGY_MODE_ICONS['STORM'],
  },
  LOW_POWER: {
    title: 'Low Power Mode',
    icon: ENERGY_MODE_ICONS['LOW_POWER'],
    route: 'low-power-mode',
    groupType: 'LOW_POWER',
  },
};

export const STANDARD_MODES: StandardMode[] = ['SELF_CONSUMPTION', 'TOU', 'BATTERY_MAX'];
export const TEMPORARY_MODES: TemporaryMode[] = ['STORM', 'LOW_POWER'];

export function getEnergyModeType(energyMode: StandardMode | TemporaryMode): 'STANDARD' | 'TEMPORARY' {
  if (STANDARD_MODES.includes(energyMode as StandardMode)) return 'STANDARD';
  if (TEMPORARY_MODES.includes(energyMode as TemporaryMode)) return 'TEMPORARY';
  return 'STANDARD';
}

export function useGetEnergyModeUIConfig(): EnergyModeUIConfig {
  const location = useLocation();
  if (location.pathname.includes('tou-savings-mode')) return ENERGY_MODES_UI_CONFIG['TOU'];
  if (location.pathname.includes('low-power-mode')) return ENERGY_MODES_UI_CONFIG['LOW_POWER'];
  return ENERGY_MODES_UI_CONFIG['TOU'];
}
