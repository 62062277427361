import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { BatteryChargingIcon, EmptyBatteryIcon, ExclamationIcon } from '../../styles/custom-icons';
import { useGetInverterLiveDataQuery } from '../site/live-data/liveDataApi';
import { selectSite } from '../site/siteSlice';

type BatteryUIConfig = { color: string; Icon?: ComponentWithAs<'svg', IconProps> };

export type BatteryStatus = keyof typeof BATTERY_STATUS_UI_CONFIG;

export const BATTERY_STATUS_UI_CONFIG: Record<string, BatteryUIConfig> = {
  CHARGING: {
    color: '#42B4E6',
    Icon: BatteryChargingIcon,
  },
  DISCHARGING: {
    color: '#42B4E6',
  },
  EMPTY: {
    color: '#9FA4AF',
    Icon: EmptyBatteryIcon,
  },
  ERROR: {
    color: '#FF5B44',
    Icon: ExclamationIcon,
  },
  IDLE: {
    color: '#42B4E6',
    Icon: BatteryChargingIcon,
  },
};

// 0.1 kW above or below 0 is usually just noise
const BATTERY_THRESHOLD_KW = 0.1;

/**
 * Adds a computed 'status' property to the inverter live data summary API response, calling this endpoint on a 5-second
 * interval.
 */
export function useLiveBatteryData() {
  const { site_id: siteId } = useSelector(selectSite);
  const queryResult = useGetInverterLiveDataQuery(siteId, { pollingInterval: 5000 });
  const { data: liveDataSummary, isLoading, isError } = queryResult;

  if (liveDataSummary && !isLoading && !isError) {
    let status = 'EMPTY';
    const { battery, battery_soc_fraction } = liveDataSummary;

    // If above or below 0 by the threshold, revert to 0
    let batteryReal = battery ?? 0;
    if (Math.abs(batteryReal) < BATTERY_THRESHOLD_KW) {
      batteryReal = 0;
    }

    if (batteryReal < 0) status = 'DISCHARGING';
    if (batteryReal > 0) status = 'CHARGING';
    if (batteryReal === 0 && battery_soc_fraction && battery_soc_fraction > 0) status = 'IDLE';
    if (batteryReal === 0 && battery_soc_fraction == 0) status = 'EMPTY';

    return {
      ...queryResult,
      data: {
        ...queryResult.data,
        battery: batteryReal,
        status,
      },
    };
  }

  return {
    ...queryResult,
    data: {
      ...queryResult.data,
      status: isError ? 'ERROR' : 'IDLE',
    },
  };
}
