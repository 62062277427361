import { SwitchSchedule, SwitchScheduleToCreate } from 'clipsal-cortex-types/src/api/api-switch-schedule';

import { baseApi } from '../../../../app/services/baseApi';
import { del, patch, post } from '../../../../common/api/api-helpers';

// Note: most of these endpoints use a custom `queryFn` to batch update/create/delete requests, because individual
// endpoints for each at a large scale causes constant cache invalidation, and constant calls to the retrieve endpoint.
export const schedulerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSwitchSchedules: build.query<SwitchSchedule[], number>({
      query: (switchId) => `/v1/switches/${switchId}/schedules`,
      providesTags: ['SwitchSchedules'],
    }),
    createSwitchSchedules: build.mutation<SwitchSchedule[], { switchId: number; schedules: SwitchScheduleToCreate[] }>({
      queryFn: async ({ switchId, schedules }) => {
        const data = await Promise.all(
          schedules.map((schedule) => post<SwitchSchedule>(`/v1/switches/${switchId}/schedules`, schedule))
        );

        return {
          data,
        };
      },
      invalidatesTags: ['SwitchSchedules'],
    }),
    updateSwitchSchedules: build.mutation<
      SwitchSchedule[],
      { scheduleId: number; schedule: Partial<SwitchSchedule> }[]
    >({
      queryFn: async (schedules) => {
        const data = await Promise.all(
          schedules.map(({ scheduleId, schedule }) => patch<SwitchSchedule>(`/v1/schedules/${scheduleId}`, schedule))
        );

        return {
          data,
        };
      },
      invalidatesTags: ['SwitchSchedules'],
    }),
    deleteSwitchSchedules: build.mutation<null, number[]>({
      queryFn: async (scheduleIds) => {
        await Promise.all(scheduleIds.map((scheduleId) => del(`/v1/schedules/${scheduleId}`, {})));

        return {
          data: null,
        };
      },
      invalidatesTags: ['SwitchSchedules'],
    }),
  }),
});

export const {
  useGetSwitchSchedulesQuery,
  useCreateSwitchSchedulesMutation,
  useUpdateSwitchSchedulesMutation,
  useDeleteSwitchSchedulesMutation,
} = schedulerApi;

export function useGetSwitchSchedules(switchId: number) {
  const { data: schedules, ...rest } = useGetSwitchSchedulesQuery(switchId);
  return { schedules: schedules ?? [], ...rest };
}
