import { GradientColorObject, Options, SeriesAreaOptions } from 'highcharts';

import { MatterDeviceActivityUIConfig } from './types';

export const MATTER_DEVICE_ACTIVITY_UI_CONFIG: MatterDeviceActivityUIConfig[] = [
  {
    switchVariant: 'gridRed',
    color: '#DB5E52',
    fillGradientStart: 'rgba(219, 94, 82, 0.3)',
    fillGradientStop: 'rgba(219, 94, 82, 0)',
  },
  {
    switchVariant: 'orange',
    color: '#F19F39',
    fillGradientStart: 'rgba(241, 159, 57, 0.3)',
    fillGradientStop: 'rgba(241, 159, 57, 0)',
  },
  {
    switchVariant: 'yellow',
    color: '#FEDC38',
    fillGradientStart: 'rgba(254, 220, 56, 0.3)',
    fillGradientStop: 'rgba(254, 220, 56, 0)',
  },
  {
    switchVariant: 'solarGreen',
    color: '#3DCD58',
    fillGradientStart: 'rgba(61, 205, 88, 0.3)',
    fillGradientStop: 'rgba(61, 205, 88, 0)',
  },
  {
    switchVariant: 'batteryBlue',
    color: '#42B4E6',
    fillGradientStart: 'rgba(66, 180, 230, 0.3)',
    fillGradientStop: 'rgba(66, 180, 230, 0)',
  },
];
export const MAX_ACTIVITY_MATTER_DEVICES = MATTER_DEVICE_ACTIVITY_UI_CONFIG.length;

export const CHART_DEFAULT_OPTIONS: Options = {
  chart: {
    type: 'datetime',
    backgroundColor: 'rgba(255,255,255,0)',
    spacingLeft: 2,
    spacingRight: 2,
    style: { marginRight: '-18px', marginLeft: '0px' },
  },
  title: {
    text: '',
  },
  tooltip: {
    shared: true,
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: 50,
    borderWidth: 0,
    shadow: false,
    padding: 12,
    style: {
      color: 'white',
      fontSize: '16px',
    },
    headerFormat: '<span style="font-size: 16px; font-weight: bold;">{point.key}</span><br/>',
    pointFormat: '<span style="color:{point.color}; font-size: 28px;">∎</span> <span>{point.y}</span><br/>',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    // shows long crosshair when hovered in chart
    crosshair: {
      color: 'rgb(204, 204, 204)',
    },
    endOnTick: false,
    // style ticks
    tickWidth: 2,
    tickLength: 6,
    tickColor: '#C6C6C6',
    type: 'datetime',
    // removes default padding in the chart
    minPadding: 0,
    maxPadding: 0,
    lineWidth: 0,
  },
  yAxis: {
    gridLineDashStyle: 'ShortDash',
    title: {
      text: '',
    },
  },
  legend: {
    enabled: false,
  },
};

interface SeriesAreaOptionsGradientOnly extends SeriesAreaOptions {
  fillColor: GradientColorObject; // Restrict fillColor to GradientColorObject
}

export const SERIES_DEFAULT_OPTIONS: SeriesAreaOptionsGradientOnly = {
  type: 'area',
  fillColor: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [],
  },
  connectNulls: true,
};
