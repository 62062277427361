import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from 'clipsal-cortex-types/src/api/api-notifications';

import { DevicesList } from '../devices/DevicesList';
import { SwitchesList, SwitchesListProps } from '../devices/switches/SwitchesList';

type NotificationSwitchesListProps = {
  type?: NotificationType;
};
export function NotificationSwitchesList({ type }: NotificationSwitchesListProps) {
  const { t } = useTranslation();
  // default props
  const getSwitchesListProps = () => {
    const props: SwitchesListProps = {
      sortBy: 'POWER',
      limit: 3,
      state: 'closed',
      title: t('Notifications.top 3 consumers'),
    };

    // For Grid Restored, we want to show all powered off consumers
    // So that users can turn them on if they want
    if (type === 'GRID_RESTORED') {
      props.limit = undefined;
      props.sortBy = 'ALPHABETICAL';
      props.state = 'open';
      props.title = t('Notifications.all powered off consumers');
    }

    // For Overload Notice and Overload Tripped, we want to show all powered on consumers
    // So that users can close them to reduce the load
    if (type === 'OVERLOAD_NOTICE' || type === 'OVERLOAD_TRIPPED') {
      props.limit = undefined;
      props.title = t('Notifications.all powered on consumers');
    }

    return props;
  };

  return (
    <Box data-testid="notification-switches-list">
      <SwitchesList {...getSwitchesListProps()} />

      {type && (
        <Box mt={4}>
          <Text mb={2}>{t('Devices.discovered devices')}</Text>
          <DevicesList sortBy={'POWER'} />
        </Box>
      )}
    </Box>
  );
}
