import React, { useMemo } from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { MIN_TARIFF_PAGE_HEIGHT } from './tariff-constants';
import { useTariffFormContext } from './useTariffFormContext';

const ConfirmTariffPageBase = ({ children }: React.PropsWithChildren) => {
  const { control, isDirtyForm } = useTariffFormContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [search] = useSearchParams();
  const tariffId = useWatch({ control, name: 'id' });
  const tariff = useWatch({ control, name: `tariff` });
  const { backURL, paramBackURL } = useMemo(() => {
    const paramBackURL = search.get('backURL');
    return { backURL: `${paramBackURL ?? '../start'}?direction=back`, paramBackURL };
  }, []);
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Energy Rates.set up rates')}
      includeTopNav
      px={4}
      backURL={backURL}
      data-testid={`confirm-${tariff.tariffType.toLowerCase()}-tariff-rates`}
      customTopNavButton={
        (!isDirtyForm && tariffId) || paramBackURL ? (
          <Button
            mr={2}
            variant={'ghost'}
            size={'sm'}
            fontWeight={400}
            colorScheme={'schneiderSkyBlue'}
            data-testid="edit-tariff-button"
            onClick={() => {
              navigate(
                `../../tariff-details?direction=back${paramBackURL ? `&backURL=${pathname}?${search.toString()}` : ''}`
              );
            }}
          >
            {t('Common.edit')}
          </Button>
        ) : undefined
      }
    >
      <VStack
        align={'initial'}
        minHeight={MIN_TARIFF_PAGE_HEIGHT}
        fontWeight={400}
        color={'#111111'}
        _dark={{
          color: '#EEEEEE',
        }}
      >
        {isDirtyForm && <Text>{t('Energy Rates.review the tariff plan', { planName: tariff?.planName })}</Text>}
        {children}
      </VStack>
    </SlidingAnimationPageBase>
  );
};

export default ConfirmTariffPageBase;
