import React, { useMemo } from 'react';

import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { StatefulChart } from '../../../common/components/StatefulChart';
import { PowerSourceConfiguration } from '../types';
import { useActivityChartData } from './use-activity-chart-data';
import { usePowerEnergyApplianceSeries } from './use-power-energy-appliance-series';
import { usePowerEnergySeries } from './use-power-energy-series';

type Props = {
  isLoaded: boolean;
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
  selectedPowerSources?: PowerSourceConfiguration;
  selectedDeviceId: number | null;
  isSelectedDeviceDisplayedInChart: boolean;
  chartOpts?: {
    height: number; // in pixels
    applianceArea: {
      color: string;
      fillGradientStart: string;
      fillGradientStop: string;
    };
  };
};

export default function ActivityChart({
  isLoaded,
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  powerUsageData,
  energyUsageData,
  selectedPowerSources,
  selectedDeviceId,
  isSelectedDeviceDisplayedInChart,
  chartOpts = {
    height: 320,
    applianceArea: {
      color: '#9B51E0',
      fillGradientStart: 'rgba(155, 81, 224, 0.3)',
      fillGradientStop: 'rgba(155, 81, 224, 0)',
    },
  },
}: Props) {
  const isDayView = selectedDateRangeType === DateRangeType.Day;
  const { height, applianceArea } = chartOpts;

  const { isBattery, isSolar, isGrid, isAppliance } = useMemo(() => {
    if (!isLoaded) return {};
    const data = isDayView ? powerUsageData : energyUsageData;
    return {
      isBattery: selectedPowerSources?.battery && data[0]?.hasOwnProperty('battery') && isDayView,
      isSolar: selectedPowerSources?.solar && data[0]?.hasOwnProperty('solar'),
      isGrid: selectedPowerSources?.grid && (data[0]?.hasOwnProperty('imported') || data[0]?.hasOwnProperty('grid')),
      isAppliance:
        data[0]?.hasOwnProperty('appliances') && data[0]?.appliances?.length && isSelectedDeviceDisplayedInChart,
    };
  }, [isLoaded, isDayView, powerUsageData, energyUsageData, selectedPowerSources, isSelectedDeviceDisplayedInChart]);

  const { batterySeries, solarSeries, gridSeries } = usePowerEnergySeries({
    isDayView,
    powerUsageData,
    energyUsageData,
  });
  const applianceSeries = usePowerEnergyApplianceSeries({
    isReady: !!isAppliance,
    isDayView,
    powerUsageData,
    energyUsageData,
    selectedDeviceIds: [selectedDeviceId],
    chartOpts: { applianceArea: [applianceArea] },
  });

  const options = useActivityChartData({
    series: [
      ...(isBattery ? batterySeries : []),
      ...(isSolar ? solarSeries : []),
      ...(isGrid ? gridSeries : []),
      ...(isAppliance ? applianceSeries : []),
    ],
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    chartOpts,
  });

  const noData = (isDayView && !powerUsageData.length) || (!isDayView && !energyUsageData.length);

  return (
    <StatefulChart
      isLoaded={isLoaded}
      isData={!noData}
      height={height}
      options={options}
      testId={'activity-chart-container'}
    />
  );
}
