import { Device } from 'clipsal-cortex-types/src/api';

import { useSource } from './use-source';

export function useInitialSelectedPowerSources(devices: Device[]) {
  const source = useSource();

  if (source) {
    return {
      solar: source === 'solar',
      battery: source === 'battery',
      // generator: source === 'generator',
      grid: source === 'grid',
    };
  }

  // @TODO: currently, we assume the inverter has a meter if there is also a battery on the site
  const hasBattery = devices.some((d) => d.device_type === 'BATTERY_PACK');
  const hasInverter = devices.some((d) => d.device_type === 'INVERTER');
  const hasMeter = devices.some((d) => d.device_type === 'METER');
  const shouldDisplayGrid = (hasBattery && hasInverter) || hasMeter;

  return {
    solar: !!devices.find((d) => d.device_type === 'INVERTER'),
    battery: !!devices.find((d) => d.device_type === 'BATTERY_PACK'),
    generator: false,
    // Could be from inverter meter or sense meter
    grid: shouldDisplayGrid,
  };
}
