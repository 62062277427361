import React, { useMemo } from 'react';
import { Alert, AlertIcon, Skeleton } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getIconForDevice } from '../../../devices/devices-helper';
import { SenseAccordionItem } from '../common/SenseAccordionItem';
import { SensePowerCard } from '../common/SensePowerCard';
import { LiveDeviceWithCost, useLiveDevicesWithCost } from '../common/use-live-devices-with-cost';
import { MAX_NUMBER_OF_TOP_CONSUMER_DEVICES, TOP_CONSUMERS_COLORS } from '../constants';

export function TopPowerConsumers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { devicesWithCost, isLoaded, isError } = useLiveDevicesWithCost();

  const devices = useMemo(() => {
    return orderBy(devicesWithCost, (device) => device.power, 'desc')
      .slice(0, MAX_NUMBER_OF_TOP_CONSUMER_DEVICES)
      .filter((device) => device.power > 0);
  }, [devicesWithCost]);

  const handleCardClick = (device: LiveDeviceWithCost) => {
    if (!device?.switchId && !device?.applianceId) {
      navigate('../devices/list?backURL=../home');
    } else {
      navigate(
        device.switchId
          ? `../switches/${device.switchId}/view?backURL=../home`
          : `../devices/${device.applianceId}/view?backURL=../home`
      );
    }
  };

  return (
    <SenseAccordionItem title={t('Home Sense.top power consumers')} dataTestId="top-consumers-list">
      <Skeleton
        isLoaded={isLoaded}
        borderRadius={5}
        borderBottomRightRadius="25px"
        height={isLoaded ? 'unset' : 75}
        my={4}
      >
        {isError ? (
          <Alert status="error" variant="left-accent" data-testid="error-alert">
            <AlertIcon />
            {t('Common.error fetching data')}
          </Alert>
        ) : (
          devices.map((device, i) => (
            <SensePowerCard
              onClick={() => handleCardClick(device)}
              borderColor={TOP_CONSUMERS_COLORS[i]}
              name={device.name}
              power={device.power}
              cost={device.cost}
              Icon={getIconForDevice(device.assignment)}
              key={i}
              dataTestId={`top-consumer-list-item-${device.name.toLowerCase().replace(' ', '-')}-${i}`}
            />
          ))
        )}
      </Skeleton>
    </SenseAccordionItem>
  );
}
