import React from 'react';
import { Browser } from '@capacitor/browser';
import { Box, Center, Heading, Link, ListItem, Text, UnorderedList, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SchneiderHamIcon } from 'clipsal-cortex-icons/src/custom-icons';

import SEHomeCard from '../../common/components/SEHomeCard';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import AccountMenuButton from './AccountMenuButton';

export const Support = () => {
  const [search] = useSearchParams();
  const backURLFromQueryParams = search.get('backURL');
  const iconColor = useColorModeValue('primaryBranding.600', 'primaryBranding.500');
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Support.support')}
      backURL={backURLFromQueryParams ?? '../dashboard?direction=back'}
    >
      <Box fontWeight={550} px={5}>
        <Center>
          <SchneiderHamIcon w={'100px'} h={'100px'} color={iconColor} my={4} />
        </Center>
        <ContactDetails />
      </Box>
    </SlidingAnimationPageBase>
  );
};

export const ContactDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading fontWeight="bold" mb={2} size="md" data-testid="contact-technical-support-details">
        {t('Support.contact technical')}
      </Heading>
      <Text fontWeight={400} mb={5}>
        {t('Support.customer care teams')}
      </Text>

      <Text fontSize="sm" fontWeight={600} mb={1}>
        {t('Support.phone support')}
      </Text>
      <UnorderedList>
        <ListItem>
          <Link data-testid={'support-phone-1'} isExternal href={'tel:+18777346631'}>
            877-SE HOME 1 (877-734-6631)
          </Link>
        </ListItem>
        <ListItem>
          <Link data-testid={'support-phone-2'} isExternal href={'tel:+15136058000'}>
            513-605-8000
          </Link>
        </ListItem>
      </UnorderedList>

      <Text fontWeight={400} fontSize="sm" mt={1}>
        {t('Support.available mon fri')}
      </Text>

      <Text mt={5} fontSize="sm" fontWeight={600}>
        {t('Support.web support')}
      </Text>
      <Link
        data-testid={'support-link'}
        isExternal
        href="https://www.se.com/us/en/work/support/"
        textDecoration="underline"
      >
        https://www.se.com/us/en/work/support/
      </Link>

      <SEHomeCard mt={10} borderTopLeftRadius={'5px'} p={0}>
        <AccountMenuButton
          includeBottomDivider={false}
          data-testid="support-faq"
          onClick={() => {
            Browser.open({ url: 'https://shop.se.com/us/en/customer-support' });
          }}
        >
          <Text fontSize={16} fontWeight="normal">
            {t('Support.faq')}
          </Text>
        </AccountMenuButton>
      </SEHomeCard>
    </>
  );
};
