import React from 'react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoRocketOutline } from 'react-icons/io5';

import ArcButton from '../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../common/components/SlidingAnimationPageBase';
import { ANDROID_PLAY_STORE_URL, APPLE_APP_STORE_URL } from '../../common/constants';

/* istanbul ignore next -- @preserve */
export const UpdateRequired = () => {
  const { t } = useTranslation();
  return (
    <SlidingAnimationPageBase title={''}>
      <VStack px={4} mt={4}>
        <IoRocketOutline size={100} color="#3DCD57" />
        <Heading size="xl" fontWeight={500} mt={50}>
          {t('Update Required.title')}
        </Heading>
        <Text textAlign={'center'} mt={3}>
          {t('Update Required.description')}
        </Text>
        <ArcButton
          onClick={() => {
            const url = Capacitor.getPlatform() === 'ios' ? APPLE_APP_STORE_URL : ANDROID_PLAY_STORE_URL;
            Browser.open({ url });
          }}
          mt={100}
          w={'80%'}
          arcColor="#3DCD57"
        >
          {t('Update Required.button')}
        </ArcButton>
      </VStack>
    </SlidingAnimationPageBase>
  );
};
