import React from 'react';
import { Center, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { DeleteAccountIcon, OutlineGradientIcon } from '../../../../../styles/custom-icons';

export function DeleteAccountStart() {
  const navigate = useNavigate();
  const redIconColor = useColorModeValue('#FF3E24', '#FF5B44');
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase title={t('Account.delete account')} backURL={`../../../privacy?direction=back`}>
      <Center flexDirection="column" m={4}>
        <Center position="relative">
          <OutlineGradientIcon w="250px" h="250px" color={redIconColor} data-testid="outline-icon" />
          <Center pos="absolute">
            <DeleteAccountIcon color={redIconColor} w="87px" h="74px" mb={2} data-testid="delete-account-icon" />
          </Center>
        </Center>
        <Text fontSize={22} fontWeight={400} textAlign="center" data-testid="delete-account-title">
          {t('Account.delete account')}
        </Text>
        <Text textAlign="center" fontSize={15}>
          {t('Account.deleting your account will')}
        </Text>
        <Text textAlign="center" fontSize={15}>
          {t('Account.you will not be able to create a new account')} {t('Account.your energy data is also deleted')}
        </Text>

        <ArcButton
          arcColor={redIconColor}
          w={250}
          mt={14}
          onClick={() => navigate('../request-data-archive')}
          data-testid="continue-button"
        >
          {t('Common.continue')}
        </ArcButton>
      </Center>
    </SlidingAnimationPageBase>
  );
}
