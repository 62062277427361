import React from 'react';
import { Box, Center, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArcButton from '../../../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../../../common/components/SlidingAnimationPageBase';
import { DeleteAccountIcon, OutlineGradientIcon } from '../../../../../styles/custom-icons';
import { useDeleteAccountMutation } from '../../../accountApi';

export function DeleteAccountConfirm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const redIconColor = useColorModeValue('#FF3E24', '#FF5B44');
  const [deleteAccount, { isLoading: isDeleteLoading }] = useDeleteAccountMutation();

  async function handleDeleteAccount() {
    try {
      await deleteAccount({}).unwrap();
      navigate(`/logout`);
    } catch (error) {
      toast({
        title: t('Account.error deleting account'),
        description: `${t('Common.please try again')} ${t('Common.if this persists contact support')}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }

  return (
    <SlidingAnimationPageBase title={t('Account.delete account')} backURL={`../verify-details?direction=back`}>
      <Box m={4}>
        <Center flexDirection="column">
          <Center position="relative">
            <OutlineGradientIcon w="250px" h="250px" color={redIconColor} data-testid="outline-icon" />
            <Center pos="absolute">
              <DeleteAccountIcon color={redIconColor} w="87px" h="74px" mb={2} data-testid="delete-data-icon" />
            </Center>
          </Center>
          <Text fontSize={22} fontWeight={400} textAlign="center" data-testid="delete-my-account-title">
            {t('Account.delete se home account')}
          </Text>
          <Text textAlign="center" fontSize={15} mb={3}>
            {t('Account.once you tap delete')}
          </Text>
          <Text textAlign="center" fontSize={15} mb={3}>
            {t('Account.to cancel delete')}
          </Text>
          <Text textAlign="center" fontSize={15} mb={3}>
            {t('Account.if delete request is not cancelled')}
          </Text>
          <ArcButton
            arcColor={redIconColor}
            w={250}
            onClick={handleDeleteAccount}
            isLoading={isDeleteLoading}
            data-testid="delete-my-account-button"
          >
            {t('Account.delete my account')}
          </ArcButton>
        </Center>
      </Box>
    </SlidingAnimationPageBase>
  );
}
