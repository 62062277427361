import { useBreakpointValue } from '@chakra-ui/react';

export type ViewportType = 'desktop-lg' | 'desktop' | 'tablet' | 'mobile';

export const DESKTOP_LARGE_WIDTH_BREAKPOINT = 1920;
export const DESKTOP_WIDTH_BREAKPOINT = 1024;
export const TABLET_WIDTH_BREAKPOINT = 768;
export const MOBILE_WIDTH_BREAKPOINT = 480;

/**
 * Retrieves the viewport type, according to provided width.
 *
 * @param width Numeric viewport width.
 */
export function getViewportType(width: number): ViewportType {
  if (width >= DESKTOP_LARGE_WIDTH_BREAKPOINT) {
    return 'desktop-lg';
  } else if (width >= DESKTOP_WIDTH_BREAKPOINT) {
    return 'desktop';
  } else if (width < DESKTOP_WIDTH_BREAKPOINT && width >= TABLET_WIDTH_BREAKPOINT) {
    return 'tablet';
  } else {
    return 'mobile';
  }
}

interface UseViewportType {
  viewportType: ViewportType;
  isDesktopViewport: boolean;
}

export const useViewportType = (): UseViewportType => {
  const isRunningCypressTest = (window as any).Cypress;
  const viewportType: ViewportType =
    useBreakpointValue(
      {
        base: 'mobile',
        sm: 'tablet',
        md: 'desktop',
        lg: 'desktop-lg',
        xl: 'desktop-lg',
      },
      { ssr: isRunningCypressTest ? true : false }
    ) || getViewportType(window.innerWidth);

  const isDesktopViewport = ['desktop-lg', 'desktop'].includes(viewportType);

  return { viewportType, isDesktopViewport };
};
