import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Box, Center, Divider, Flex, Image, Radio, RadioGroup, Text, useColorModeValue } from '@chakra-ui/react';

import '../../styles/react-phone-input-2.css';

import { Browser } from '@capacitor/browser';
import { StatusBar } from '@capacitor/status-bar';
import { useTranslation } from 'react-i18next';

import packageJson from '../../../package.json';
import background from '../../assets/images/prosumer_bg_img.jpg';
import schneiderLogoBlack from '../../assets/images/schneider_logo_black.svg';
import schneiderLogoWhite from '../../assets/images/schneider_logo_white.svg';
import ArcButton from '../../common/components/ArcButton';
import { COMPONENT_MIN_HEIGHT, IS_IOS } from '../../common/constants';
import { ENV_TYPE_SHORTHAND } from '../../env-type';
import { AWS_REGION } from '../../region-type';
import AUTHORIZE_URL from './auth-helpers';

export function Login() {
  const schneiderLogo = useColorModeValue(schneiderLogoBlack, schneiderLogoWhite);
  const { handleMouseDown, handleMouseUp, isEnvironmentManagementVisible } = useEnvironmentSwapEasterEgg();
  const { t } = useTranslation();
  useEffect(() => {
    if (IS_IOS) StatusBar.hide();
  }, []);

  return (
    <Box h="100%" minH={COMPONENT_MIN_HEIGHT}>
      {/* Image and logo section. The negative margin is to overlay the image over the status bar on iOS. */}
      <Flex h="80%" flexDirection="column" marginTop="calc(0px - env(safe-area-inset-top))">
        <Flex
          h="80%"
          bgImage={`url("${background}")`}
          backgroundPosition="left center"
          bgSize={'cover'}
          position="relative"
        >
          <Flex
            w="100%"
            bottom={0}
            h={'25px'}
            backgroundColor={'primaryBranding.200'}
            opacity={0.5}
            position={'absolute'}
          />
        </Flex>

        {isEnvironmentManagementVisible && <EnvironmentManagement />}

        <Flex flexDirection="column">
          <Box backgroundColor={'primaryBranding.200'} pl={5} pt={5}>
            <Text
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseDown}
              fontSize={24}
              color="#fff"
            >
              {t('Common.schneider home')}
            </Text>
            <Text fontSize={12} py={2} color="#fff">
              {t('Common.version')} {packageJson.version}
            </Text>
          </Box>
          <Image marginLeft="auto" mr={2} mt={3} src={schneiderLogo} />
        </Flex>
      </Flex>

      <Center flexDirection={'column'} h={'20%'}>
        <ArcButton
          mb={2}
          data-testid="login-button"
          w={'80%'}
          arcColor="#3DCD57"
          onClick={() => {
            Browser.open({
              url: AUTHORIZE_URL,
              windowName: '_self',
            });
            /*
             // To login into Production from Development environment, uncomment bellow and comment out above.
             Auth.signIn({
              username: '/!* your cognito user 01HXQP7BSMR... *!/',
              password: '/!* Your password. Might need to reset *!/'
            });
            */
          }}
        >
          {t('Account.login')}
        </ArcButton>

        <Center w="100%">
          <Divider borderWidth={1} />
          <Text mx={2}>{t('Common.or')}</Text>
          <Divider borderWidth={1} />
        </Center>

        <ArcButton
          mt={2}
          data-testid="demo-login-button"
          w={'80%'}
          arcColor="#007AFF"
          onClick={() => {
            localStorage.setItem('isDemoLogin', 'true');
            // Refresh to update local storage variable
            window.location.assign('/site/1/home');
          }}
        >
          {t('Demo Mode.view demo')}
        </ArcButton>
      </Center>
    </Box>
  );
}

const EASTER_EGG_WAIT_TIME_MS = 5000;

function useEnvironmentSwapEasterEgg() {
  const isMouseDown = useRef(false);
  const [isEnvironmentManagementVisible, setIsEnvironmentManagementVisible] = useState(false);

  function handleMouseDown(e: SyntheticEvent) {
    e.preventDefault();
    isMouseDown.current = true;

    setTimeout(() => {
      if (isMouseDown.current) {
        setIsEnvironmentManagementVisible(true);
      }
    }, EASTER_EGG_WAIT_TIME_MS);
  }

  function handleMouseUp() {
    isMouseDown.current = false;
  }

  return {
    handleMouseDown,
    handleMouseUp,
    isEnvironmentManagementVisible,
  };
}

function EnvironmentManagement() {
  return (
    <>
      <Box mb={1} data-testid="region-select">
        Select region
      </Box>
      <RadioGroup
        onChange={(e) => {
          localStorage.setItem('seHomeRegionType', e.toString());
          window.location.reload();
        }}
        mb={4}
        value={AWS_REGION}
      >
        <Radio mr={2} value="ap-southeast-2">
          Australia
        </Radio>
        <Radio ml={2} value="us-east-1">
          United States
        </Radio>
      </RadioGroup>
      <Box mb={1} data-testid="environment-select">
        Select environment
      </Box>
      <RadioGroup
        onChange={(e) => {
          localStorage.setItem('seHomeEnvType', e.toString());
          window.location.reload();
        }}
        mb={4}
        value={ENV_TYPE_SHORTHAND}
      >
        <Radio mr={2} value="PROD">
          Production
        </Radio>
        <Radio ml={2} value="STAGING">
          Staging
        </Radio>
      </RadioGroup>
    </>
  );
}
