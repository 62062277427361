import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DateRangeType } from '../../../../common/components/DateRangeTypePicker';
import { useGetCostsQuery } from '../../../site/costApi';
import { useLiveData } from '../../../site/live-data/liveDataApi';
import { selectCurrentDayForSite } from '../../../site/siteSlice';

export interface LiveDeviceWithCost {
  switchId?: number | null;
  applianceId?: number | null;
  assignment: string;
  name: string;
  power: number;
  cost: number;
}

export function useLiveDevicesWithCost() {
  const currentDay = useSelector(selectCurrentDayForSite);
  const { data: liveData, isLoading: isLiveDataLoading, isMeterError } = useLiveData();
  const {
    data: costsData,
    isLoading: isCostsLoading,
    isError: isCostsError,
  } = useGetCostsQuery(currentDay, DateRangeType.Day);
  const isLoaded = !isLiveDataLoading && !isCostsLoading;
  const isError = isMeterError || isCostsError;

  const costLookup = useMemo(() => {
    if (!costsData?.devices) return {};
    return costsData.devices.reduce((acc: Record<number, number>, { appliance_id, switch_id, cost }) => {
      acc[appliance_id ?? switch_id] = cost;
      return acc;
    }, {});
  }, [costsData]);

  const devicesWithCost = useMemo<LiveDeviceWithCost[]>(() => {
    if (!liveData || !costsData) return [];

    const appliances = liveData.appliances.map(({ appliance_id, display_name, power, assignment }) => ({
      applianceId: appliance_id,
      assignment,
      name: display_name,
      power,
      cost: appliance_id ? costLookup[appliance_id] || 0 : 0,
    }));

    const switches = liveData.switches.map(({ switch_id, name, site_switch_label, power, assignment }) => ({
      switchId: switch_id,
      assignment: assignment ?? 'load_other',
      name: site_switch_label ?? name ?? '',
      power,
      cost: switch_id ? costLookup[switch_id] || 0 : 0,
    }));

    return [...appliances, ...switches];
  }, [costsData, liveData]);

  return {
    devicesWithCost,
    liveData,
    costsData,
    isLoaded,
    isError,
  };
}
