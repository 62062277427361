import React, { useEffect, useMemo, useState } from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Heading, Link, Skeleton, Text } from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { License, toURL, useLicenses } from './useLicenses';

export function LicensesDetail() {
  const { publisherId, packageId } = useParams<{ publisherId: string; packageId: string }>();
  const { t } = useTranslation();
  const licenses = useLicenses();

  const license = useMemo(() => {
    const publisherLicenses = licenses.find((license) => license.publisherId === toURL(publisherId))?.licenses ?? [];
    return publisherLicenses.find((license) => license.packageId === toURL(packageId));
  }, [licenses, publisherId]);

  return (
    <SlidingAnimationPageBase title={t('Account.licenses')} backURL={'..?direction=back'}>
      {license && (
        <Box px={5}>
          <Heading mb={4} as="h2" size="md">
            <Link href={license.repository} isExternal>
              {license.packageName} <ExternalLinkIcon mx="2px" />
            </Link>
          </Heading>
          {license?.licenseFile ? <LicenseText license={license} /> : <Text>{license?.licenses} License</Text>}
        </Box>
      )}
    </SlidingAnimationPageBase>
  );
}

interface Props {
  readonly license: License;
}

function LicenseText({ license }: Props) {
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isMarkdown = useMemo(() => license?.licenseFile?.includes('.md'), [license?.licenseFile]);

  useEffect(() => {
    const loadLicense = async (pathToFile?: string) => {
      if (pathToFile) {
        setIsLoading(true);
        try {
          const srcPath = '../../../../../../licenses/';
          const response = await fetch(`${srcPath}${pathToFile}?raw`);
          const text = await response.text();
          setData(text);
          setIsLoading(false);
        } catch (error) {
          console.error('Error loading license file:', error);
          setIsLoading(false);
        }
      }
    };
    loadLicense(license?.licenseFile);
  }, [license?.licenseFile]);

  return (
    <Skeleton isLoaded={!isLoading} data-testid={`license-${isMarkdown ? 'markdown' : 'text'}`}>
      {isMarkdown ? (
        <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
          {data}
        </ReactMarkdown>
      ) : (
        <Text as="pre" sx={{ textWrap: 'balance' }}>
          {data}
        </Text>
      )}
    </Skeleton>
  );
}
