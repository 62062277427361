import React, { useMemo } from 'react';

import { SavingsAPIData } from 'clipsal-cortex-types/src/api';
import { CostsData } from 'clipsal-cortex-types/src/api/api-costs';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { StatefulChart } from '../../../common/components/StatefulChart';
import { useCostSavingChartData } from './use-cost-saving-chart-data';

type Props = {
  isLoaded: boolean;
  selectedDateRangeType: Exclude<DateRangeType, DateRangeType.Day>;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  costData?: CostsData[];
  savingsData?: SavingsAPIData[];
  selectedDeviceAssignment?: string;
  isSelectedDeviceDisplayedInChart?: boolean;
};

export function CostSavingChart({
  isLoaded,
  selectedDateRangeType,
  rangeTypeToSelectedPeriod,
  costData,
  savingsData,
  selectedDeviceAssignment,
  isSelectedDeviceDisplayedInChart,
}: Props) {
  const options = useCostSavingChartData({
    isLoaded,
    selectedDateRangeType,
    rangeTypeToSelectedPeriod,
    costData,
    savingsData,
    selectedDeviceAssignment,
    isSelectedDeviceDisplayedInChart,
  });

  const noData = useMemo(() => {
    if (costData) {
      return !costData.find((interval) => interval.total_cost && interval?.total_cost != 0);
    }
    if (savingsData) {
      return !savingsData.find((interval) => interval.total_saved && interval?.total_saved != 0);
    }
  }, [costData, savingsData]);

  return (
    <StatefulChart
      isLoaded={isLoaded}
      isData={!noData}
      height={320}
      options={options}
      testId={`${costData ? 'cost' : 'savings'}-chart-container`}
    />
  );
}
