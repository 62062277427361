import { useSelector } from 'react-redux';

import { ConsentType, SiteConsent } from 'clipsal-cortex-types/src/api/api-site-consent';
import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';

import { baseApi } from '../../../app/services/baseApi';
import { selectSite } from '../siteSlice';

export type CreateSiteConsent = { consent_type: ConsentType; permission: boolean };

export const siteConsentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSiteConsents: builder.query<PaginatedResponseV2<SiteConsent>, number>({
      query: (siteId) => `/v1/sites/${siteId}/consent`,
      /* 
      RTK Query keeps an internal counter for each endpoint+cacheKey combination. It increases the counter for each
      component that wants to read that data, ie, useGetPokemonQuery("pikachu"). When components unmount or change
      the cache key, it decreases the counter.
      When a subscription counter goes to 0, RTK Query sets a timer, and by default that timer is 60s. If no components
      have asked to read that data when the timer expires, it will remove the data from the cache.
      keepUnusedDataFor changes the timer delay. If you set it to, say, 60 * 60, it will wait for one hour before 
      checking if the data should be removed. If you did 60 * 60 * 24 * 365 * 10, it would wait for 10 years before
      checking to remove the data (ie, "basically forever").
      */
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER, // avoid re-fetching unless reloaded or refetched
      providesTags: (siteId) => [{ type: 'SiteConsents', siteId }],
    }),
    createSiteConsent: builder.mutation<SiteConsent, { siteId: number; body: CreateSiteConsent }>({
      query: ({ siteId, body }) => {
        return {
          url: `/v1/sites/${siteId}/consent`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_, __, { siteId }) => [{ type: 'SiteConsents', siteId }],
    }),
    updateSiteConsent: builder.mutation<SiteConsent, { siteId: number; consentId: number; body: CreateSiteConsent }>({
      query: ({ siteId, consentId, body }) => ({
        url: `/v1/sites/${siteId}/consent/${consentId}`,
        method: 'PUT',
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Optimistic update so the user gets fast feedback. The mutation happens in the background.
        const { siteId, consentId, body } = arg;
        const patchResult = dispatch(
          siteConsentApi.util.updateQueryData('getSiteConsents', siteId, (draft) => {
            // Update this setting in the drafted state. Note that immer provides mutability here!
            const siteConsent = Object.values(draft.results).find((consent) => consent.id === consentId);
            if (siteConsent) siteConsent.permission = body.permission;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetSiteConsentsQuery: useOriginalGetSiteConsentsQuery,
  useCreateSiteConsentMutation,
  useUpdateSiteConsentMutation,
} = siteConsentApi;

/**
 * Provides a way to get a site consent by the consent type
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetSiteConsentByTypeQuery(consentType: ConsentType, siteId?: number) {
  const { site_id: reduxSiteId } = useSelector(selectSite);
  const siteIdToUse = siteId ?? reduxSiteId;
  const result = useOriginalGetSiteConsentsQuery(siteIdToUse, { skip: !siteIdToUse });

  return {
    ...result,
    data: result.data?.results.find((consent) => consent.consent_type === consentType) || null,
  };
}
