import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SiteDataToSave } from 'clipsal-cortex-types/src/api/api-site';

import { useReduxDispatch } from '../../app/store';
import { post } from '../../common/api/api-helpers';
import { IS_PRODUCTION } from '../../common/constants';
import { fetchSite } from '../site/siteSlice';
import { AddressFormData, SiteForm } from './SiteForm';

export function CreateSiteForm() {
  const toast = useToast({ isClosable: true });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  async function handleSubmit(values: AddressFormData) {
    const body: SiteDataToSave = {
      lat: values.latitude,
      lon: values.longitude,
      city: values.city,
      post_code: values.postCode,
      state: values.state,
      country: values.country,
      address: values.address,
      timezone: values.timezone,
      site_name: values.name,
    };

    try {
      const newSite = await post<SiteDataToSave & { site_id: number; tenant_id: number }>(`/v1/sites`, body);
      await dispatch(fetchSite({ siteID: Number(newSite.site_id), isProduction: IS_PRODUCTION }));
      navigate(`/sites/${newSite.site_id}/home`);
    } catch (e) {
      console.log(e);
      toast({ title: t('Manage Homes.error creating home'), status: 'error', duration: 3000 });
    }
  }

  return <SiteForm onSubmit={handleSubmit} />;
}
