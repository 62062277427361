import React from 'react';
import { Box, Center, Heading } from '@chakra-ui/react';
import Highcharts, { Options } from 'highcharts';
import Chart from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';

import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

type Props = {
  options: Options;
  isLoaded: boolean;
  isData: boolean;
  height: number; // in pixels
  testId?: string;
};

export function StatefulChart({ options, isLoaded, isData, height, testId }: Props) {
  const { t } = useTranslation();

  if (!isLoaded) {
    return <CenteredLoader text={`${t('Common.loading')}...`} minH={`${height}px`} />;
  }

  if (!isData) {
    return (
      <Center minH={`${height}px`} flexDirection="column">
        <Heading size="md" textAlign="center" data-testid="data-unavailable-message">
          {t('Common.data unavailable')}
        </Heading>
      </Center>
    );
  }

  return (
    <Box maxW="100vw" position="relative" minH={`${height}px`}>
      <Box data-testid={testId ?? 'se-chart-container'} position="absolute" width="100%">
        <Chart highcharts={Highcharts} options={options} />
      </Box>
    </Box>
  );
}
