import { useMemo } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { Options, SeriesAreaOptions } from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PowerUsageV2 } from 'clipsal-cortex-types/src/api';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { getChartTimezoneOffset } from '../../../utils/common/common-utils';
import { selectSite } from '../../site/siteSlice';
import { CHART_DEFAULT_OPTIONS, SERIES_DEFAULT_OPTIONS } from '../constants';
import { getUIConfigForRangeType } from '../utils';

interface Props {
  selectedDate: Date;
  powerUsageData: PowerUsageV2[];
}

export function useBatterySocChartData({ selectedDate, powerUsageData }: Props) {
  const { timezone } = useSelector(selectSite);
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { t } = useTranslation();

  const series = useMemo<SeriesAreaOptions[]>(() => {
    const data =
      powerUsageData.map((interval) => ({
        x: new Date(interval.reading_datetime).getTime(),
        y: interval?.battery_soc ?? null,
      })) ?? [];
    return [
      {
        ...SERIES_DEFAULT_OPTIONS,
        id: 'battery-soc',
        color: '#42B4E6',
        fillColor: {
          ...SERIES_DEFAULT_OPTIONS.fillColor,
          stops: [
            [0, 'rgba(66, 180, 230, 0.3)'],
            [1, 'rgba(66, 180, 230, 0)'],
          ],
        },
        data: [{ x: 0, y: 0 }, ...data],
      },
    ];
  }, [powerUsageData]);

  const minDateTime = useMemo(() => {
    const zonedStartDate = fromZonedTime(selectedDate, timezone);
    return zonedStartDate.getTime();
  }, [selectedDate, timezone]);

  const maxDateTime = useMemo(() => {
    const zonedStartDate = fromZonedTime(selectedDate, timezone);
    zonedStartDate.setDate(zonedStartDate.getDate() + 1);
    return zonedStartDate.getTime();
  }, [selectedDate, timezone]);

  return useMemo<Options>(() => {
    const { xAxisTickFormat, tooltipDateFormat, xAxisTickInterval } = getUIConfigForRangeType(DateRangeType.Day);

    return {
      ...CHART_DEFAULT_OPTIONS,
      chart: {
        ...CHART_DEFAULT_OPTIONS.chart,
        height: '180px',
      },
      series,
      tooltip: {
        ...CHART_DEFAULT_OPTIONS.tooltip,
        valueSuffix: '%',
        valueDecimals: 0,
        xDateFormat: tooltipDateFormat,
      },
      xAxis: {
        ...CHART_DEFAULT_OPTIONS.xAxis,
        min: minDateTime,
        max: maxDateTime,
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        tickInterval: xAxisTickInterval,
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            return formatInTimeZone(new Date(data.value ?? 0), timezone, xAxisTickFormat);
          },
        },
      },
      yAxis: {
        ...CHART_DEFAULT_OPTIONS.yAxis,
        min: 0,
        max: 100,
        accessibility: { enabled: true, description: t('Activity.state of charge') },
        labels: {
          style: { fontSize: '11px', color: markerColor },
          format: '{text}%',
        },
      },
      time: {
        getTimezoneOffset: (timestamp: number) => getChartTimezoneOffset(timestamp, timezone),
      },
    };
  }, [series, minDateTime, maxDateTime, timezone, markerColor, t]);
}
