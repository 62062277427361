import { Action, combineReducers, configureStore, PayloadAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import schedulerReducer from '../features/devices/switches/schedules/schedulerSlice';
import evChargerReducer from '../features/ev_charger/dashboard/evChargerSlice';
import deviceSetupReducer from '../features/ev_charger/device-setup/deviceSetupSlice';
import wifiConfigReducer from '../features/ev_charger/wifi-configuration/wifiConfigSlice';
import siteReducer from '../features/site/siteSlice';
import sitesReducer from '../features/sites/sitesSlice';
import userReducer from '../features/user/userSlice';
import { baseApi } from './services/baseApi';

const rootReducer = combineReducers({
  sites: sitesReducer,
  user: userReducer,
  site: siteReducer,
  deviceSetup: deviceSetupReducer,
  evCharger: evChargerReducer,
  wifiConfig: wifiConfigReducer,
  scheduler: schedulerReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const resettableRootReducer = (state: RootState | undefined, action: PayloadAction<unknown>) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware);
  },
});

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type ReduxDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
