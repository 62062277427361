import React from 'react';
import { Badge, Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SlidingAnimationPageBase from '../../../../common/components/SlidingAnimationPageBase';
import { IS_DEMO_LOGIN } from '../../../../common/constants';
import { useGetInvitationsQuery } from '../../../site/invitationsApi';
import { useGetSiteUsersQuery } from '../../../site/siteUsersApi';

export function ViewFamilyMembers() {
  const navigate = useNavigate();
  const { data: invitations, isLoading } = useGetInvitationsQuery();
  const { data: users, isLoading: isSiteUsersLoading } = useGetSiteUsersQuery();
  const isLoaded = !isLoading && !isSiteUsersLoading;
  const handleButtonClick = async () => {
    navigate(`../edit`);
  };
  const { t } = useTranslation();

  return (
    <SlidingAnimationPageBase
      title={t('Account.family members')}
      backURL={`../../home?direction=back`}
      customTopNavButton={
        <Button
          data-testid="edit-button"
          onClick={handleButtonClick}
          variant={'ghost'}
          size={'sm'}
          isDisabled={IS_DEMO_LOGIN}
          colorScheme={'schneiderSkyBlue'}
          fontWeight={500}
        >
          {t('Common.edit')}
        </Button>
      }
    >
      <Box m={4}>
        <Text fontWeight={600}>{t('Account.members added')}</Text>
        <Skeleton
          isLoaded={isLoaded}
          borderBottomLeftRadius={'5px'}
          borderBottomRightRadius={'25px'}
          borderTopRightRadius={'5px'}
          borderTopLeftRadius={'25px'}
          minHeight={12}
        >
          {users?.map((user, i) => (
            <Text my={1} data-testid={`family-member-${i}`} key={user.user_id}>
              {user.user_email}
            </Text>
          ))}

          {invitations?.map((invitation, i) => (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              my={1}
              data-testid={`pending-invitation-${i}`}
              key={`pending-invitation-${invitation.user_email}-${i}`}
            >
              <Text overflow="auto">{invitation.user_email}</Text>
              <Badge data-testid={`pending-badge-${i}`}>{t('Common.pending')}</Badge>
            </Flex>
          ))}
        </Skeleton>
      </Box>
    </SlidingAnimationPageBase>
  );
}
