import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';

import { StatefulChart } from '../../../common/components/StatefulChart';
import { useBatterySocChartData } from './use-battery-soc-chart-data';

type Props = {
  isLoaded: boolean;
  selectedDate: Date;
  powerUsageData: PowerUsageV2[];
};

export function BatterySOCChart({ isLoaded, selectedDate, powerUsageData }: Props) {
  const { t } = useTranslation();
  const options = useBatterySocChartData({ selectedDate, powerUsageData });

  return (
    <Accordion mx={-3} mt={6} allowToggle>
      <AccordionItem>
        <AccordionButton data-testid="battery-soc-chart-accordion-btn">
          <Box as="span" flex="1" textAlign="left">
            {t('Activity.state of charge')}
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel mx={-1}>
          <StatefulChart
            isLoaded={isLoaded}
            isData={!!powerUsageData.length}
            height={200}
            options={options}
            testId={'activity-battery-soc-chart-container'}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
