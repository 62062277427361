import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { Investment, Investments } from 'clipsal-cortex-types/src/api/api-investments';
import { SavingsAPIData } from 'clipsal-cortex-types/src/api/api-savings';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { baseApi } from '../../app/services/baseApi';
import { get } from '../../common/api/api-helpers';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { selectSite } from '../site/siteSlice';

export type CreateInvestmentBody = Pick<Investment, 'amount' | 'date' | 'description'>;

type SavingsQueryParams = {
  siteId: number;
  selectedDateRangeType: DateRangeType;
  selectedDate: Date;
};

export const savingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSavings: builder.query<SavingsAPIData[], SavingsQueryParams>({
      queryFn: async ({ siteId, selectedDate, selectedDateRangeType }) => {
        let groupBy: 'day' | 'month' | null = null;
        let startDateFormatted = '';
        let endDateFormatted = '';
        if (selectedDateRangeType === DateRangeType.Week) {
          const firstDayOfWeek = getFirstDayOfWeek(selectedDate);
          const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
          const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 7));

          startDateFormatted = format(firstDayOfWeek, 'yyyy-MM-dd');
          endDateFormatted = format(lastDayOfWeek, 'yyyy-MM-dd');
          groupBy = 'day';
        } else if (selectedDateRangeType === DateRangeType.Month) {
          const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
          const firstDayOfNextMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
          startDateFormatted = format(firstDayOfMonth, 'yyyy-MM-dd');
          endDateFormatted = format(firstDayOfNextMonth, 'yyyy-MM-dd');
          groupBy = 'day';
        } else {
          startDateFormatted = `${selectedDate.getFullYear()}-01-01`;
          endDateFormatted = `${selectedDate.getFullYear() + 1}-01-01`;
          groupBy = 'month';
        }
        if (selectedDateRangeType === DateRangeType.Day) {
          // Use local timezone for fetching cost data
          const localDate = format(selectedDate, 'yyyy-MM-dd');
          const savingsData = await get<SavingsAPIData[]>(
            `/v1/sites/${siteId}/savings?start_date=${localDate}&end_date=${localDate}&groupby=day`
          );
          return {
            data: savingsData,
          };
        } else {
          const savingsData = await get<SavingsAPIData[]>(
            `/v1/sites/${siteId}/savings?start_date=${startDateFormatted}` +
              `&end_date=${endDateFormatted}&groupby=${groupBy}`
          );

          return {
            data: savingsData,
          };
        }
      },
      providesTags: ['Savings'],
    }),
    getInvestments: builder.query<Investments, number>({
      query: (siteId) => `/v1/sites/${siteId}/investments`,
      providesTags: ['Investments'],
    }),
    updateInvestment: builder.mutation<
      Investment,
      { siteId: number; investmentId: number; body: CreateInvestmentBody }
    >({
      query: ({ siteId, investmentId, body: { amount, date, description } }) => {
        return {
          url: `/v1/sites/${siteId}/investments/${investmentId}`,
          method: 'PATCH',
          body: { amount, date, description },
        };
      },
      invalidatesTags: ['Investments', 'Savings'],
    }),
    createInvestment: builder.mutation<Investment, { siteId: number; body: CreateInvestmentBody }>({
      query: ({ siteId, body: { amount, date, description } }) => {
        return {
          url: `/v1/sites/${siteId}/investments`,
          method: 'POST',
          body: { amount, date, description },
        };
      },
      invalidatesTags: ['Investments', 'Savings'],
    }),
  }),
});

export const {
  useGetSavingsQuery: useOriginalGetSavingsQuery,
  useGetInvestmentsQuery: useOriginalGetInvestmentsQuery,
  useUpdateInvestmentMutation,
  useCreateInvestmentMutation,
} = savingsApi;

/**
 * Provides some sensible default values for the savings query.
 *
 * @param selectedDate - The currently selected date in the UI. Note, this is the _local_ date of the site.
 * @param selectedDateRangeType - The currently selected date range type.
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetSavingsQuery(selectedDate: Date, selectedDateRangeType: DateRangeType) {
  const { site_id: siteId } = useSelector(selectSite);
  selectedDate.setHours(0, 0, 0, 0); // Ensure we're using the start of the day for better caching

  const result = useOriginalGetSavingsQuery({
    siteId,
    selectedDate,
    selectedDateRangeType,
  });

  return {
    ...result,
    data: result?.data ?? [],
  };
}

/**
 * Provides some sensible default values for the investments query.
 *
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetInvestmentsQuery() {
  const { site_id: siteId } = useSelector(selectSite);

  const result = useOriginalGetInvestmentsQuery(siteId);
  return {
    ...result,
  };
}
