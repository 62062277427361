import { useCallback, useMemo } from 'react';
import { SeriesAreaOptions } from 'highcharts';
import { useSelector } from 'react-redux';

import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api';

import { selectSite } from '../../site/siteSlice';
import { SERIES_DEFAULT_OPTIONS } from '../constants';
import { convertToTimezoneAwareDate } from '../utils';

interface ApplianceArea {
  color: string;
  fillGradientStart: string;
  fillGradientStop: string;
}

interface Props {
  isReady: boolean;
  isIndependentDevices?: boolean;
  isDayView: boolean;
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
  selectedDeviceIds: (number | null)[];
  chartOpts: {
    applianceArea: ApplianceArea[];
  };
}

export function usePowerEnergyApplianceSeries({
  isReady,
  isIndependentDevices,
  isDayView,
  powerUsageData,
  energyUsageData,
  selectedDeviceIds,
  chartOpts: { applianceArea },
}: Props) {
  const { timezone } = useSelector(selectSite);

  const powerUsageApplianceData = useCallback(
    (deviceId: number | null) => {
      return powerUsageData.map((interval) => {
        const appliance = interval.appliances?.find((a) => a.id === deviceId);

        if (!appliance) {
          throw new Error('Something went very wrong -- there should always be an appliance matching.');
        }

        // Lining up with other data points, converting nulls to zeros.
        const otherData = interval.battery || interval.solar || interval.grid ? appliance.power ?? 0 : null;

        return {
          x: new Date(interval.reading_datetime).getTime(),
          y: isIndependentDevices ? appliance.power : otherData,
        };
      });
    },
    [powerUsageData, isIndependentDevices]
  );

  const energyUsageApplianceData = useCallback(
    (deviceId: number | null) => {
      return energyUsageData.map((interval) => {
        const appliance = interval.appliances?.find((a) => a.id === deviceId);

        if (!appliance) {
          throw new Error('Something went very wrong -- there should always be an appliance matching.');
        }

        // Note: because the date string supplied by the API is in the local timezone of the site, but it is not a
        // timezone-aware ISO-8601 string, the JS `Date` object will read it as a UTC date. This means that we need to
        // convert it to a zoned date by supplying the correct offset from UTC.
        return {
          x: convertToTimezoneAwareDate(interval.reading_date, timezone).getTime(),
          y: appliance.energy,
        };
      });
    },
    [energyUsageData, timezone]
  );

  return useMemo((): SeriesAreaOptions[] => {
    if (!isReady) return [];
    return selectedDeviceIds.map((deviceId, i) => ({
      ...SERIES_DEFAULT_OPTIONS,
      id: `${deviceId}`,
      color: applianceArea[i].color,
      fillColor: {
        ...SERIES_DEFAULT_OPTIONS.fillColor,
        stops: [
          [0, applianceArea[i].fillGradientStart],
          [1, applianceArea[i].fillGradientStop],
        ],
      },
      data: isDayView ? powerUsageApplianceData(deviceId) : energyUsageApplianceData(deviceId),
    }));
  }, [isReady, isDayView, powerUsageApplianceData, energyUsageApplianceData, selectedDeviceIds, applianceArea]);
}
