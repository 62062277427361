import { useCallback, useMemo } from 'react';
import { SeriesAreaOptions } from 'highcharts';
import { useSelector } from 'react-redux';

import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api';

import { selectSite } from '../../site/siteSlice';
import { SERIES_DEFAULT_OPTIONS } from '../constants';
import { EnergyUsageSourceKey, PowerUsageSourceKey } from '../types';
import { convertToTimezoneAwareDate } from '../utils';

interface Props {
  isDayView: boolean;
  powerUsageData: PowerUsageV2[];
  energyUsageData: EnergyUsageV2[];
}

export function usePowerEnergySeries({ isDayView, powerUsageData, energyUsageData }: Props) {
  const { timezone } = useSelector(selectSite);

  const powerUsageChartData = useCallback(
    (propertyName: PowerUsageSourceKey) => {
      return powerUsageData.map((interval) => {
        return {
          x: new Date(interval.reading_datetime).getTime(),
          y: interval?.[propertyName],
        };
      });
    },
    [powerUsageData]
  );

  const energyUsageChartData = useCallback(
    (propertyName: EnergyUsageSourceKey) => {
      return energyUsageData.map((interval) => {
        // Note: because the date string supplied by the API is in the local timezone of the site, but it is not a
        // timezone-aware ISO-8601 string, the JS `Date` object will read it as a UTC date. This means that we need to
        // convert it to a zoned date by supplying the correct offset from UTC.
        return {
          x: convertToTimezoneAwareDate(interval.reading_date, timezone).getTime(),
          y: interval?.[propertyName],
        };
      });
    },
    [energyUsageData, timezone]
  );

  const batterySeries = useMemo(
    (): SeriesAreaOptions[] => [
      {
        ...SERIES_DEFAULT_OPTIONS,
        id: 'battery',
        color: '#42B4E6',
        fillColor: {
          ...SERIES_DEFAULT_OPTIONS.fillColor,
          stops: [
            [0, 'rgba(66, 180, 230, 0.3)'],
            [1, 'rgba(66, 180, 230, 0)'],
          ],
        },
        data: [{ x: 0, y: 0 }, ...powerUsageChartData('battery')],
      },
    ],
    [powerUsageChartData]
  );

  const solarSeries = useMemo(
    (): SeriesAreaOptions[] => [
      {
        ...SERIES_DEFAULT_OPTIONS,
        id: 'solar',
        color: '#27AE60',
        fillColor: {
          ...SERIES_DEFAULT_OPTIONS.fillColor,
          stops: [
            [0, 'rgba(39, 174, 96, 0.3)'],
            [1, 'rgba(39, 174, 96, 0)'],
          ],
        },
        data: [{ x: 0, y: 0 }, ...(isDayView ? powerUsageChartData('solar') : energyUsageChartData('solar'))],
        connectNulls: false,
      },
    ],
    [isDayView, powerUsageChartData, energyUsageChartData]
  );

  const gridSeries = useMemo(
    (): SeriesAreaOptions[] => [
      {
        ...SERIES_DEFAULT_OPTIONS,
        id: 'grid',
        color: '#DB5E52',
        fillColor: {
          ...SERIES_DEFAULT_OPTIONS.fillColor,
          stops: [
            [0, 'rgba(219, 94, 82, 0.3)'],
            [1, 'rgba(219, 94, 82, 0)'],
          ],
        },
        data: [{ x: 0, y: 0 }, ...(isDayView ? powerUsageChartData('grid') : energyUsageChartData('imported'))],
        connectNulls: false,
      },
    ],
    [isDayView, powerUsageChartData, energyUsageChartData]
  );

  return {
    batterySeries,
    solarSeries,
    gridSeries,
  };
}
