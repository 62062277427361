import { useSelector } from 'react-redux';

import { NotificationSetting } from 'clipsal-cortex-types/src/api/api-notification-settings';
import { UserLocale } from 'clipsal-cortex-types/src/api/api-user';

import { baseApi } from '../../app/services/baseApi';
import { selectSite } from '../site/siteSlice';

export const accountApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNotificationSettings: build.query<Record<string, NotificationSetting>, string[]>({
      query: () => '/v1/user/notification_settings?related_app=Schneider Home',
      transformResponse: (response: NotificationSetting[], _, arg) => {
        return Object.fromEntries(
          response.filter(({ type }) => arg.includes(type)).map((setting) => [setting.type, setting])
        );
      },
      providesTags: ['NotificationSettings'],
    }),
    getDeviceFirmwareVersion: build.query<{ firmware_version: string }, { siteId: number; siteDeviceId: number }>({
      query: ({ siteId, siteDeviceId }) => `/v1/sites/${siteId}/site_device/${siteDeviceId}/firmware_version`,
    }),
    updateNotificationSetting: build.mutation<
      void,
      { data: Pick<NotificationSetting, 'id' | 'enabled'>; types: string[] }
    >({
      query: ({ data: { id, ...body } }) => {
        return {
          url: `/v1/user/notification_settings/${id}`,
          method: 'PATCH',
          body: body,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Optimistic update so the user gets fast feedback. The mutation happens in the background.
        const { data, types } = arg;
        const patchResult = dispatch(
          accountApi.util.updateQueryData('getNotificationSettings', types, (draft) => {
            // Update this setting in the drafted state. Note that immer provides mutability here!
            const setting = Object.values(draft).find((setting) => setting.id === data.id);
            if (setting) setting.enabled = data.enabled;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateLocale: build.mutation<void, { locale: UserLocale }>({
      query: ({ locale }) => {
        return {
          url: `/v1/user`,
          method: 'PATCH',
          body: { locale },
        };
      },
    }),
    deleteAccount: build.mutation({
      query: () => {
        return {
          url: `/v1/user`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingMutation,
  useGetDeviceFirmwareVersionQuery,
  useUpdateLocaleMutation,
  useDeleteAccountMutation,
} = accountApi;

export function useGetFirmwareVersion(siteDeviceId: number) {
  const site = useSelector(selectSite);
  const result = useGetDeviceFirmwareVersionQuery({ siteId: site.site_id, siteDeviceId });

  return {
    ...result,
    data: result.data ?? { firmware_version: null },
  };
}
