import React, { useEffect } from 'react';
import { Box, Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TOP_NAV_SPACING_AFFORDANCE } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { useReduxDispatch } from '../../../app/store';
import { ReactComponent as EVLinkDevice } from '../../../assets/images/evlink_device.svg';
import ArcButton from '../../../common/components/ArcButton';
import SlidingAnimationPageBase from '../../../common/components/SlidingAnimationPageBase';
import { selectEvChargerData, setHasVisitedRegisterScreen } from '../dashboard/evChargerSlice';

/* istanbul ignore next -- @preserve */
export const DeviceRegister = () => {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();
  const { hasVisitedRegisterScreen } = useSelector(selectEvChargerData);
  const bgColor = useColorModeValue('#F7F7F7', '#111111');

  useEffect(() => {
    // updating flag so that user is not redirected to this screen from dashboard again
    if (!hasVisitedRegisterScreen) dispatch(setHasVisitedRegisterScreen(true));
  }, []);

  return (
    <SlidingAnimationPageBase title="Device Setup" customNavigationDirection="forward">
      <Flex bgColor={bgColor} direction={'column'} align="center" justify={'space-between'}>
        <Text data-testid={'ev-setup-text'} px={8} pt={TOP_NAV_SPACING_AFFORDANCE}>
          First, make sure that your <strong>Schneider Home</strong> station has been installed, configured by a
          qualified technician and is connected to the internet.
        </Text>
        <Box data-testid={'ev-image-container'}>
          <EVLinkDevice style={{ width: 'fit-content', height: 'auto', maxHeight: '480px' }} />
        </Box>
        <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={16}>
          <ArcButton
            arcColor="#3DCD57"
            data-testid={'continue-device-setup'}
            width="100%"
            maxWidth={'300px'}
            mb={6}
            onClick={() => navigate(`../device_scan`)}
          >
            Next
          </ArcButton>
          <Button
            data-testid={'skip-device-setup'}
            colorScheme={'schneiderSkyBlue'}
            fontWeight="normal"
            variant="link"
            width="100%"
            maxWidth={'300px'}
            onClick={() => navigate(`../ev_charger_status`, { replace: true })}
          >
            Skip for Later
          </Button>
        </Flex>
      </Flex>
    </SlidingAnimationPageBase>
  );
};
