import React from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Card from 'clipsal-cortex-ui/src/components/card/Card';

import { COMPONENT_MIN_HEIGHT, IS_ANDROID } from '../constants';

/* istanbul ignore next -- @preserve */
export function RootedDeviceWarningCard() {
  const backgroundColor = useColorModeValue('#F5F6F8', '#1A202C');
  const { t } = useTranslation();

  return (
    <Flex direction="column" minHeight={COMPONENT_MIN_HEIGHT}>
      <Box
        style={{ backdropFilter: 'blur(6px)' }}
        width="100vw"
        background={backgroundColor}
        zIndex={11}
        position="fixed"
        height={'env(safe-area-inset-top)'}
        top="0px"
      />
      <Card m="auto" bg="red" color="white" maxW="320px">
        <Flex direction="column">
          <WarningIcon mx="auto" h={12} w={12} />
          <Text textAlign="center" fontSize="18px" fontWeight="bold" marginTop={4}>
            {t('Jailbreak Error.message', {
              type: IS_ANDROID ? t('Jailbreak Error.rooted') : t('Jailbreak Error.jailbroken'),
            })}
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
}
