import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

// Redirect all use of site/:id to sites/:id
export function RedirectToSites() {
  const { id, '*': rest } = useParams();

  // Construct the new path
  const newPath = `/sites/${id}${rest ? `/${rest}` : ''}`;

  return <Navigate to={newPath} replace />;
}
