import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';

import { VersionCheckResult } from 'clipsal-cortex-types/src/api/api-version-check-result';

import { post } from '../api/api-helpers';
import { APP_VERSION, IS_NATIVE } from '../constants';

export function useAppUpdate() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the app version is up to date with required version
    // If not, redirect the user to the update page
    async function checkAppVersion() {
      /* istanbul ignore if -- @preserve */
      if (IS_NATIVE) {
        try {
          const response = await post<VersionCheckResult>('/v1/version_check', {
            platform: Capacitor.getPlatform().toUpperCase(),
            version: APP_VERSION,
            app_name: 'SE_HOME',
          });
          if (response.update_required) navigate('/update-required', { replace: true });
        } catch (error) {
          console.error(error);
        }
      }
    }

    checkAppVersion();
  }, []);
}
