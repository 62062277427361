import { useMemo } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Options, SeriesAreaOptions } from 'highcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { getChartTimezoneOffset, getLocaleForDateFns, SupportedLocales } from '../../../utils/common/common-utils';
import { selectSite } from '../../site/siteSlice';
import { CHART_DEFAULT_OPTIONS } from '../constants';
import { getMaxDateTime, getMinDateTime, getUIConfigForRangeType } from '../utils';

interface Props {
  series: SeriesAreaOptions[];
  selectedDateRangeType: DateRangeType;
  rangeTypeToSelectedPeriod: Record<DateRangeType, Date>;
  chartOpts: {
    height: number; // in pixels
  };
}

export function useActivityChartData({ series, selectedDateRangeType, rangeTypeToSelectedPeriod, chartOpts }: Props) {
  const { timezone } = useSelector(selectSite);
  const markerColor = useColorModeValue('#000', '#BCBCBC');
  const { i18n, t } = useTranslation();

  return useMemo<Options>(() => {
    const isDayView = selectedDateRangeType === DateRangeType.Day;
    const { height } = chartOpts;
    const startDate = rangeTypeToSelectedPeriod[selectedDateRangeType];
    const { xAxisTickFormat, tooltipDateFormat, xAxisTickInterval } = getUIConfigForRangeType(selectedDateRangeType);
    const localeForDateFns = getLocaleForDateFns(i18n.language as SupportedLocales);

    return {
      ...CHART_DEFAULT_OPTIONS,
      chart: {
        ...CHART_DEFAULT_OPTIONS.chart,
        height: `${height}px`,
      },
      series,
      tooltip: {
        ...CHART_DEFAULT_OPTIONS.tooltip,
        valueSuffix: isDayView ? ' kW' : ' kWh',
        valueDecimals: 2,
        xDateFormat: tooltipDateFormat,
      },
      xAxis: {
        ...CHART_DEFAULT_OPTIONS.xAxis,
        min: getMinDateTime(startDate, selectedDateRangeType, timezone),
        max: getMaxDateTime(startDate, selectedDateRangeType, timezone),
        accessibility: { enabled: true, description: t('Activity.time of the day') },
        tickInterval: xAxisTickInterval,
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            if (isDayView) {
              return formatInTimeZone(new Date(data.value ?? 0), timezone, xAxisTickFormat);
            } else {
              return format(new Date(data.value ?? 0), xAxisTickFormat, { locale: localeForDateFns });
            }
          },
        },
      },
      yAxis: {
        ...CHART_DEFAULT_OPTIONS.yAxis,
        accessibility: { enabled: true, description: t('Activity.usage in kw') },
        labels: {
          style: { fontSize: '11px', color: markerColor },
          formatter: function (data) {
            if (data.value === 0) return '<p style="font-size: 16px;">0</p>';
            return `${+Number(data.value).toFixed(3)} ${isDayView ? 'kW' : 'kWh'}`;
          },
        },
      },
      time: {
        getTimezoneOffset: (timestamp: number) => getChartTimezoneOffset(timestamp, timezone),
      },
    };
  }, [series, selectedDateRangeType, timezone, i18n.language, t, markerColor, rangeTypeToSelectedPeriod, chartOpts]);
}
