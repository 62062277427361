import { useEffect, useState } from 'react';

import { isDeviceRootedOrJailbroken } from '../../utils/native/root-jailbreak-detector';
import { IS_NATIVE } from '../constants';

export function useRootedDeviceCheck() {
  const [isRootedDevice, setIsRootedDevice] = useState(false);

  useEffect(() => {
    async function checkIsDeviceRooted() {
      /* istanbul ignore if -- @preserve */
      if (IS_NATIVE) {
        const isRootedDevice = await isDeviceRootedOrJailbroken();
        if (isRootedDevice) {
          setIsRootedDevice(true);
        }
      }
    }

    checkIsDeviceRooted();
  }, []);

  return isRootedDevice;
}
