import { useMemo } from 'react';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { sumBy } from 'lodash';
import { useSelector } from 'react-redux';

import { Appliance } from 'clipsal-cortex-types/src/api/api-appliances';
import { EnergyUsageV2, PowerUsageV2 } from 'clipsal-cortex-types/src/api/api-usage-v2';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { baseApi } from '../../app/services/baseApi';
import { get } from '../../common/api/api-helpers';
import { DateRangeType } from '../../common/components/DateRangeTypePicker';
import { useGetDevicesWithSwitchesQuery } from '../devices/devicesApi';
import { useGetCostsQuery } from '../site/costApi';
import { selectSite } from '../site/siteSlice';
import { ActivityDevice } from './types';

type UsageQueryParams = {
  siteId: number;
  timezone: string;
  devices: Appliance[];
  selectedDateRangeType: DateRangeType;
  selectedDate: Date;
};

type ActivityApiState = {
  energyUsageData: EnergyUsageV2[];
  powerUsageData: PowerUsageV2[];
  displayedDevices: ActivityDevice[];
  energyIndependencePercentage: number;
};

// @NOTE: The only caveat of this implementation at present is that the week picker won't correctly cache values if the
//        user selects the date in the date picker chart. It still works as expected when using the chevron buttons.
export const activityApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsage: build.query<ActivityApiState, UsageQueryParams>({
      queryFn: async ({ siteId, devices, selectedDate, selectedDateRangeType, timezone }) => {
        let groupBy: 'day' | 'week' | 'month' | null = null;
        // We group data differently in the energy usage summary call.
        let summaryGroupBy: 'day' | 'week' | 'month' | 'year' | null = null;
        let startDateFormatted = '';
        let endDateFormatted = '';
        const zonedStartDate = fromZonedTime(selectedDate, timezone);

        if (selectedDateRangeType === DateRangeType.Day) {
          startDateFormatted = zonedStartDate.toISOString();
          zonedStartDate.setDate(zonedStartDate.getDate() + 1);
          endDateFormatted = zonedStartDate.toISOString();
          summaryGroupBy = 'day';
        } else if (selectedDateRangeType === DateRangeType.Week) {
          const firstDayOfWeek = getFirstDayOfWeek(selectedDate);
          const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
          const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 7));

          startDateFormatted = format(firstDayOfWeek, 'yyyy-MM-dd');
          endDateFormatted = format(lastDayOfWeek, 'yyyy-MM-dd');
          groupBy = 'day';
          summaryGroupBy = 'week';
        } else if (selectedDateRangeType === DateRangeType.Month) {
          const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
          const firstDayOfNextMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
          startDateFormatted = format(firstDayOfMonth, 'yyyy-MM-dd');
          endDateFormatted = format(firstDayOfNextMonth, 'yyyy-MM-dd');
          groupBy = 'day';
          summaryGroupBy = 'month';
        } else {
          startDateFormatted = `${selectedDate.getFullYear()}-01-01`;
          endDateFormatted = `${selectedDate.getFullYear() + 1}-01-01`;
          groupBy = 'month';
          summaryGroupBy = 'year';
        }

        if (selectedDateRangeType === DateRangeType.Day) {
          const uri =
            `/v1/sites/${siteId}/power_usage?from_datetime=${startDateFormatted}` + `&to_datetime=${endDateFormatted}`;
          // Use local timezone for fetching energy usage summary
          const localDate = format(selectedDate, 'yyyy-MM-dd');

          // Fetch power usage (instantaneous) and a summary of energy usage (kWh) for this period.
          const [powerUsageData, [energyUsageSummary]] = await Promise.all([
            get<PowerUsageV2[] | string>(uri),
            get<EnergyUsageV2[] | string>(
              `/v1/sites/${siteId}/energy_usage?start_date=${localDate}` +
                `&end_date=${localDate}&groupby=${summaryGroupBy}`
            ),
          ]);
          // Handle a 204 response, which gives us an empty string.
          if (typeof powerUsageData === 'string' || typeof energyUsageSummary === 'string') {
            return {
              data: {
                energyUsageData: [],
                powerUsageData: [],
                displayedDevices: [],
                energyIndependencePercentage: 0,
              },
            };
          }

          return {
            data: {
              // We don't use energy usage data in the day view (for the Activity page), but we still return the -
              // - summary, this is used mainly for the Home page for Sense-only sites
              energyUsageData: [energyUsageSummary],
              powerUsageData,
              displayedDevices: combineApplianceEnergyUsageSummary(powerUsageData, energyUsageSummary, devices),
              energyIndependencePercentage: calculateEnergyIndependence(energyUsageSummary),
            },
          };
        } else {
          const uri =
            `/v1/sites/${siteId}/energy_usage?from_date=${startDateFormatted}&to_date=${endDateFormatted}` +
            `&groupby=${groupBy}`;

          // Fetching both energy usage grouped by the required grouping for the specified aggregation level, as well
          // as fetching the energy usage summary for appliance data in that period (which is grouped by the size of
          // the period itself).
          const [energyUsageData, [energyUsageSummary]] = await Promise.all([
            get<EnergyUsageV2[] | string>(uri),
            get<EnergyUsageV2[] | string>(
              `/v1/sites/${siteId}/energy_usage?from_date=${startDateFormatted}` +
                `&to_date=${endDateFormatted}&groupby=${summaryGroupBy}`
            ),
          ]);
          // Handle a 204 response, which gives us an empty string.
          if (typeof energyUsageData === 'string' || typeof energyUsageSummary === 'string') {
            return {
              data: {
                energyUsageData: [],
                powerUsageData: [],
                displayedDevices: [],
                energyIndependencePercentage: 0,
              },
            };
          }
          return {
            data: {
              energyUsageData,
              // There is no power usage data in the non-day view, so we just return an empty array.
              powerUsageData: [],
              displayedDevices: combineApplianceEnergyUsageSummary(energyUsageData, energyUsageSummary, devices),
              energyIndependencePercentage: calculateEnergyIndependence(energyUsageSummary),
            },
          };
        }
      },
      providesTags: ['Usage'],
    }),
  }),
});

/**
 * Calculates "energy independence", usually referred to as the "self-powered percentage", as a rounded percentage value
 * between 0 and 100.
 *
 * @param energyUsageSummary
 */
function calculateEnergyIndependence(energyUsageSummary: EnergyUsageV2) {
  // Edge case for no consumption data from API -- happens when a site has no Sense meter.
  if (!('consumed' in energyUsageSummary) || !energyUsageSummary.consumed) {
    console.error('Missing consumption value in energy usage summary!');
    return 0;
  }

  if (!('imported' in energyUsageSummary) || energyUsageSummary.imported === null) {
    console.error('Missing import value in energy usage summary!');
    return 0;
  }

  const { consumed, imported } = energyUsageSummary;
  const selfPoweredPercentage = ((consumed - imported) / consumed) * 100;

  return Math.round(Math.max(selfPoweredPercentage, 0));
}

/**
 * The appliances in the chart data are the ones which have been used in the period, and we don't want to list
 * _all_ appliances (there could be 30+, when only 5 are used in a period).
 * This function does two things:
 *
 * 1. Matches appliances to what is specified in the usage data, so the displayed devices are the ones which
 *    are visible to the user.
 * 2. Matches these displayed appliances up to the appliance energy usage summary, so it can be shown in the
 *    device selection list if possible.
 *
 * It's worth noting that sites which do not have a Sense meter won't display the summary at all, so this will
 * not apply for them.
 *
 * @param usageData - The usage data to match appliances to.
 * @param energyUsageSummary - The energy usage summary to fetch aggregated appliance usage from.
 * @param devices - The devices to match to the usage data.
 * @returns The devices which are visible in the usage data, with their energy usage summary.
 */
function combineApplianceEnergyUsageSummary(
  usageData: (PowerUsageV2 | EnergyUsageV2)[],
  energyUsageSummary: EnergyUsageV2,
  devices: Appliance[]
): ActivityDevice[] {
  let displayedDevices: ActivityDevice[] = [];

  if (usageData[0]?.appliances) {
    const appliancesInPeriod = usageData[0].appliances.map((a) => a.id);
    displayedDevices = devices
      .filter((device) => appliancesInPeriod.includes(device.appliance_id))
      .map((d) => {
        const matchingApplianceById = energyUsageSummary.appliances!.find((a) => a.id === d.appliance_id);
        if (!matchingApplianceById)
          throw new Error(
            'Something went wrong, could not match power usage appliance with energy usage summary appliance.'
          );
        return {
          ...d,
          energySummary: matchingApplianceById.energy ?? 0,
        };
      })
      // Sort by energy usage summary, descending
      .sort((a, b) => (a.energySummary > b.energySummary ? -1 : 1));
  }

  return displayedDevices;
}

const { useGetUsageQuery: useGetOriginalUsageQuery } = activityApi;

/**
 * Provides some sensible default values for the usage query.
 *
 * @param selectedDate - The currently selected date in the UI. Note, this is the _local_ date of the site.
 * @param selectedDateRangeType -
 * @param skip - Whether to skip the query.
 * @param excludeSwitches - Whether to exclude switches from the query.
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetUsageQuery(
  selectedDate: Date,
  selectedDateRangeType: DateRangeType,
  skip = false,
  excludeSwitches = true
) {
  const { site_id: siteId, timezone } = useSelector(selectSite);
  const {
    data: devicesData,
    isLoading: isDevicesLoading,
    isFetching: isDevicesFetching,
    isError: isDevicesError,
  } = useGetDevicesWithSwitchesQuery(skip, excludeSwitches);
  const {
    data: usageData,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
    isError: isUsageError,
    ...usageOther
  } = useGetOriginalUsageQuery(
    {
      siteId,
      selectedDate,
      devices: devicesData,
      timezone,
      selectedDateRangeType,
    },
    { skip: isDevicesLoading || isDevicesFetching }
  );

  return {
    ...usageOther,
    isError: isDevicesError || isUsageError,
    isFetching: isDevicesFetching || isUsageFetching,
    isLoading: isDevicesLoading || isUsageLoading,
    data: {
      ...usageData,
      powerUsageData: usageData?.powerUsageData ?? [],
      energyUsageData: usageData?.energyUsageData ?? [],
      displayedDevices: usageData?.displayedDevices ?? [],
    },
  };
}

/**
 * Provides some sensible default values for the usage query and includes device costs.
 *
 * @param selectedDate - The currently selected date in the UI. Note, this is the _local_ date of the site.
 * @param selectedDateRangeType - The currently selected date range type.
 * @param skip - Whether to skip the query.
 * @param excludeSwitches - Whether to exclude switches from the query.
 * @returns The query result with some sensible default values when no data exists.
 */
export function useGetUsageWithCostsQuery(
  selectedDate: Date,
  selectedDateRangeType: DateRangeType,
  skip = false,
  excludeSwitches = true
) {
  selectedDate.setHours(0, 0, 0, 0); // Ensure we're using the start of the day for better caching

  const { site_id: siteId, timezone } = useSelector(selectSite);
  const {
    data: devicesData,
    isLoading: isDevicesLoading,
    isFetching: isDevicesFetching,
    isError: isDevicesError,
  } = useGetDevicesWithSwitchesQuery(skip, excludeSwitches);
  const {
    data: costsData,
    isLoading: isCostsLoading,
    isFetching: isCostsFetching,
    isError: isCostsError,
  } = useGetCostsQuery(selectedDate, selectedDateRangeType);
  const {
    data: usageData,
    isLoading: isUsageLoading,
    isFetching: isUsageFetching,
    isError: isUsageError,
    ...usageOther
  } = useGetOriginalUsageQuery(
    {
      siteId,
      selectedDate,
      devices: devicesData,
      timezone,
      selectedDateRangeType,
    },
    { skip: isDevicesLoading }
  );

  const displayedDevicesWithCosts = useMemo(() => {
    let displayedDevices: ActivityDevice[] = [];

    if (!isUsageError && usageData && costsData) {
      displayedDevices = usageData.displayedDevices.map((d) => {
        const sumOfApplianceEnergyUsageCost = sumBy(
          costsData.costData.map((interval) => {
            return interval.assignments?.filter((assignment) => assignment.assignment === d.assignment)[0]?.amount || 0;
          })
        );
        return {
          ...d,
          cost: sumOfApplianceEnergyUsageCost,
        };
      });
    }

    return displayedDevices;
  }, [isUsageError, usageData, costsData.costData]);

  return {
    ...usageOther,
    isError: isCostsError || isDevicesError || isUsageError,
    isLoading: isCostsLoading || isDevicesLoading || isUsageLoading,
    isFetching: isCostsFetching || isDevicesFetching || isUsageFetching,
    data: {
      ...usageData,
      energyIndependencePercentage: usageData?.energyIndependencePercentage ?? 0,
      powerUsageData: usageData?.powerUsageData ?? [],
      energyUsageData: usageData?.energyUsageData ?? [],
      displayedDevices: displayedDevicesWithCosts,
    },
  };
}
