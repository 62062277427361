import React from 'react';
import { useSelector } from 'react-redux';

import { DateRangeType } from '../../../common/components/DateRangeTypePicker';
import { selectSite } from '../../site/siteSlice';
import { ActivityView } from '../common/ActivityView';
import { useDateRangeOptions } from '../common/use-date-range-options';

export function SenseOnlyActivity() {
  const { is_consumption_site } = useSelector(selectSite);
  const DATE_RANGE_OPTIONS = useDateRangeOptions();

  return (
    <ActivityView
      options={{
        dateRange: DATE_RANGE_OPTIONS.filter((option) => option.value !== DateRangeType.Year),
        powerSources: { solar: !is_consumption_site, grid: true, battery: false },
      }}
    />
  );
}
