import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ControlGroupType, DeviceControlGroup } from 'clipsal-cortex-types/src/api/api-control-group';
import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';

import { baseApi } from '../../../app/services/baseApi';
import { selectSite } from '../../site/siteSlice';

export type CreateSwitchGroup = {
  group_name: string;
  group_type: ControlGroupType;
};

export const switchGroupApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSwitchGroups: builder.query<PaginatedResponseV2<DeviceControlGroup>, number>({
      query: (siteId) => `/v1/sites/${siteId}/groups`,
      providesTags: ['SwitchGroups'],
    }),
    createSwitchGroup: builder.mutation<DeviceControlGroup, { siteId: number; body: CreateSwitchGroup }>({
      query: ({ siteId, body }) => {
        return {
          url: `/v1/sites/${siteId}/groups`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['SwitchGroups'],
    }),
    addDeviceToSwitchGroup: builder.mutation<DeviceControlGroup, { groupId: number; switchId: number }>({
      query: ({ groupId, switchId }) => {
        return {
          url: `/v1/controls/${switchId}/groups`,
          method: 'POST',
          body: { group: groupId },
        };
      },
      invalidatesTags: ['SwitchGroups'],
    }),
    removeDeviceFromSwitchGroup: builder.mutation<DeviceControlGroup, { groupId: number; switchId: number }>({
      query: ({ groupId, switchId }) => {
        return {
          url: `/v1/controls/${switchId}/groups/${groupId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['SwitchGroups'],
    }),
  }),
});

export const {
  useGetSwitchGroupsQuery: useOriginalGetSwitchGroupsQuery,
  useCreateSwitchGroupMutation,
  useAddDeviceToSwitchGroupMutation,
  useRemoveDeviceFromSwitchGroupMutation,
} = switchGroupApi;

/**
 * A basic wrapper around the `useGetSwitchGroupsQuery` original, with some sensible default values.
 */
export function useGetSwitchGroupsQuery() {
  const { site_id: siteId } = useSelector(selectSite);
  const result = useOriginalGetSwitchGroupsQuery(siteId);

  return {
    ...result,
    data: result?.data ?? [],
  };
}

/**
 * Provides a way to get a switch group ID for a given group type.
 *
 * @returns The switch group ID, or null if it doesn't exist.
 */
export function useGetSwitchGroupForType(groupType: ControlGroupType) {
  const { site_id: siteId } = useSelector(selectSite);
  const { data: switchGroups, ...rest } = useOriginalGetSwitchGroupsQuery(siteId);

  const switchGroup = useMemo(() => {
    const switchGroup = switchGroups?.results.find((switchGroup) => switchGroup.group_type === groupType);
    return switchGroup ?? null;
  }, [groupType, switchGroups]);

  return {
    ...rest,
    data: switchGroup,
  };
}
