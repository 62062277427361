import { getTimezoneOffset } from 'date-fns-tz';
import { enUS, es, frCA } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { es as yupEs, fr as yupFr } from 'yup-locales';

import { SUPPORTED_LOCALES } from '../../i18n';

/**
 * Promisifies the `setTimeout` function
 *
 * @param ms MS to wait
 */
export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export type SupportedLocales = keyof typeof SUPPORTED_LOCALES;

/**
 * Returns the relevant date-fns locale for the given language code
 *
 * @param locale code for the locale
 */
export function getLocaleForDateFns(locale: SupportedLocales) {
  switch (locale) {
    case 'en-US':
      return enUS;
    case 'fr-CA':
      return frCA;
    case 'es-US':
      return es;
    default:
      return enUS;
  }
}

/**
 * Returns the relevant yup locale for the given language code
 *
 * @param locale code for the locale
 */
export function getLocaleForYup(locale: SupportedLocales) {
  switch (locale) {
    case 'en-US':
      // Yup does not have an en-US locale exported
      // It is the default locale
      return undefined;
    case 'fr-CA':
      return yupFr;
    case 'es-US':
      return yupEs;
    default:
      return undefined;
  }
}

/**
 * Returns the months in the currently active locale
 *
 */
// Note: This handles edge cases of daylight savings time, where each timestamp has its own offset,
// instead of a single offset for the entire day's duration.
export function getChartTimezoneOffset(timestamp: number, timezone: string) {
  // The offset returned by this function differs from `Date.prototype.getTimezoneOffset` in that
  // it returns the offset from UTC time, instead of the difference between this date as evaluated
  // in the UTC time zone, and the same date as evaluated in the local timezone.
  // The result means that the offset is the inverse sign of the result
  // of `Date.prototype.getTimezoneOffset`.
  const offsetMins = getTimezoneOffset(timezone, new Date(timestamp)) / 60_000;
  // The chart expects the polarity of the Date.prototype method spec, so we need to invert the sign.
  return offsetMins > 0 ? offsetMins * -1 : Math.abs(offsetMins);
}

export function useTranslatedMonths() {
  const { t } = useTranslation();
  return [
    t('Common.january'),
    t('Common.february'),
    t('Common.march'),
    t('Common.april'),
    t('Common.may'),
    t('Common.june'),
    t('Common.july'),
    t('Common.august'),
    t('Common.september'),
    t('Common.october'),
    t('Common.november'),
    t('Common.december'),
  ];
}
