import { useEffect, useRef, useState } from 'react';

export function useContainerSize() {
  const ref = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState<DOMRect | null>(null);

  useEffect(() => {
    // Get the container's width and set it in state
    if (ref.current) {
      setSize(ref.current.getBoundingClientRect());
    }

    // Optional: Add event listener to handle window resize
    const handleResize = () => {
      if (ref.current) {
        setSize(ref.current.getBoundingClientRect());
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    ref,
    size,
  };
}
